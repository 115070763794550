//
//  @file RapidaIDService.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import WebServiceBase from "scripts/WebServiceBase.jsx";

import RapidaIDSession from "scripts/RapidaIDSession.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

import "config.js";

class RapidaIDService
{

    static ServiceURL = global.Config.ServiceURL;//"http://localhost:8000/Portal/";
    static AdminAPI = global.Config.AdminAPI;
    static UserAPI = global.Config.UserAPI;
    static SyncAPI = global.Config.SyncAPI;
    static LoginAPI = global.Config.LoginAPI;

    static Abort(pRequest)
    {
        if(pRequest)
            pRequest.abort();
        return null;
    }

    static Login(pRequest, OnResponse)
    {
        return WebServiceBase.CallPostJSON(RapidaIDService.ServiceURL + RapidaIDService.LoginAPI, pRequest, function(pResponse) {
            console.log(pResponse);
            pResponse = pResponse ? pResponse : { Result: -1 };
            OnResponse(pResponse);
        });
    }

    static LoginActivate(pThis, sUserInfo, sRegKey, OnResponse)
    {
        return RapidaIDService.Login({Type: "Activate", UserInfo: sUserInfo, RegKey: sRegKey}, function(pResponse) {
            OnResponse(pThis, pResponse);
        });
    }

    static LoginRegister(pThis, sUserInfo, sUserPass, OnResponse)
    {
        return RapidaIDService.Login({Type: "Register", UserInfo: sUserInfo, UserPass: sUserPass}, function(pResponse) {
            OnResponse(pThis, pResponse);
        });
    }

    static LoginClose(pThis, sSessionKey, OnResponse)
    {
        return RapidaIDService.Login({Type: "LoginClose", SessionKey: sSessionKey}, function(pResponse) {
            OnResponse(pThis, pResponse);
        });
    }

    static LoginInit(pThis, sUserInfo, OnResponse)
    {
        return RapidaIDService.Login({Type: "LoginInit", UserInfo: sUserInfo}, function(pResponse) {
            OnResponse(pThis, pResponse);
        });
    }

    static LoginCheckUNP(pThis, sAuthKey, sUserInfo, sUserPass, OnResponse)
    {
        return RapidaIDService.Login({Type: "LoginCheck", AuthKey: sAuthKey, Method: RapidaIDMainDefs.EAuthMethodOnlineUNP, UserInfo: sUserInfo, UserPass: sUserPass}, function(pResponse) {
            OnResponse(pThis, pResponse);
        });
    }

    static LoginCheckCard(pThis, sAuthKey, sUserInfo, sCardID, sCardPIN, OnResponse)
    {
        return RapidaIDService.Login({Type: "LoginCheck", AuthKey: sAuthKey, Method: RapidaIDMainDefs.EAuthMethodCard, UserInfo: sUserInfo, CardID: sCardID, PIN: sCardPIN}, function(pResponse) {
            OnResponse(pThis, pResponse);
        });
    }

    static LoginCheckMAGS(pThis, sAuthKey, sUserInfo, sCardID, sCardPIN, OnResponse)
    {
        return RapidaIDService.Login({Type: "LoginCheck", AuthKey: sAuthKey, Method: RapidaIDMainDefs.EAuthMethodMAGS, UserInfo: sUserInfo, CardID: sCardID, PIN: sCardPIN}, function(pResponse) {
            OnResponse(pThis, pResponse);
        });
    }

    static LoginFetchFIDO(pThis, sAuthKey, sUserInfo, OnResponse)
    {
        return RapidaIDService.Login({Type: "LoginFetch", AuthKey: sAuthKey, Method: RapidaIDMainDefs.EAuthMethodFIDO, UserInfo: sUserInfo}, function(pResponse) {
            OnResponse(pThis, pResponse);
        });
    }

    static LoginCheckFIDO(pThis, sAuthKey, sUserInfo, sPIN, iAuthItem, sHostResult, OnResponse)
    {
        return RapidaIDService.Login({Type: "LoginCheck", AuthKey: sAuthKey, Method: RapidaIDMainDefs.EAuthMethodFIDO, UserInfo: sUserInfo, PIN: sPIN, AuthItem: iAuthItem, HostResult: sHostResult}, function(pResponse) {
            OnResponse(pThis, pResponse);
        });
    }

    static LoginFetchFPRT(pThis, sAuthKey, sUserInfo, OnResponse)
    {
        return RapidaIDService.Login({Type: "LoginFetch", AuthKey: sAuthKey, Method: RapidaIDMainDefs.EAuthMethodFPRT, UserInfo: sUserInfo}, function(pResponse) {
            OnResponse(pThis, pResponse);
        });
    }

    static LoginCheckFPRT(pThis, sAuthKey, sUserInfo, sPIN, OnResponse)
    {
        return RapidaIDService.Login({Type: "LoginCheck", AuthKey: sAuthKey, Method: RapidaIDMainDefs.EAuthMethodFPRT, UserInfo: sUserInfo, PIN: sPIN}, function(pResponse) {
            OnResponse(pThis, pResponse);
        });
    }

    static LoginCheckPush(pThis, sAuthKey, sUserInfo, sUserPass, OnResponse)
    {
        return RapidaIDService.Login({Type: "LoginCheck", AuthKey: sAuthKey, Method: RapidaIDMainDefs.EAuthMethodPush, UserInfo: sUserInfo, UserPass: sUserPass}, function(pResponse) {
            OnResponse(pThis, pResponse);
        });
    }

    static LoginCheckOTP(pThis, sAuthKey, sUserInfo, sOTPCode, OnResponse)
    {
        return RapidaIDService.Login({Type: "LoginCheck", AuthKey: sAuthKey, Method: RapidaIDMainDefs.EAuthMethodOTP, UserInfo: sUserInfo, OTPCode: sOTPCode}, function(pResponse) {
            OnResponse(pThis, pResponse);
        });
    }

    static Admin(pRequest, OnResponse)
    {
        pRequest.SessionKey = RapidaIDSession.GetSessionKey();            
        return WebServiceBase.CallPostJSON(RapidaIDService.ServiceURL + RapidaIDService.AdminAPI, pRequest, function(pResponse) {
            console.log(pResponse);
            pResponse = pResponse ? pResponse : { Result: -1 };
            RapidaIDSession.UpdateData(pResponse);
            OnResponse(pResponse);
        });
    }

    static AdminActivityGet(pThis, iRecordPos, nRecordCount, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "ActivityGet", RecordPos: iRecordPos, RecordCount: nRecordCount }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data, pResponse.StatDisk, pResponse.StatApps, pResponse.StatUsers, pResponse.StatHosts);
        });
    }

    static AdminAppsGet(pThis, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "AppsGet" }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminAppsSet(pThis, pUUIDs, pTemplates, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "AppsSet", UUID: pUUIDs, Template: pTemplates }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminAppsDel(pThis, pItems, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "AppsDel", Items: pItems }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminAppsBuild(pThis, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "AppsBuild" }, function(pResponse) {
            OnResponse(pThis, pResponse.Result);
        });
    }

    static AdminHostsGet(pThis, iRecordPos, nRecordCount, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "HostsGet", RecordPos: iRecordPos, RecordCount: nRecordCount }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminHostsSet(pThis, iRecordPos, nRecordCount, sUUID, pItem, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "HostsSet", RecordPos: iRecordPos, RecordCount: nRecordCount, UUID: sUUID, Item: pItem }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminHostsSetPolicy(pThis, iRecordPos, nRecordCount, pUUID, sPolicyData, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "HostsSetPolicy", RecordPos: iRecordPos, RecordCount: nRecordCount, UUID: pUUID, PolicyData: sPolicyData }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminHostsDel(pThis, iRecordPos, nRecordCount, pItems, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "HostsDel", RecordPos: iRecordPos, RecordCount: nRecordCount, Items: pItems }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminHostsPolicyGet(pThis, iRecordPos, nRecordCount, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "HostsPolicyGet", RecordPos: iRecordPos, RecordCount: nRecordCount }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminHostsPolicySet(pThis, iRecordPos, nRecordCount, sUUID, sName, sDesc, sValue, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "HostsPolicySet", RecordPos: iRecordPos, RecordCount: nRecordCount, UUID: sUUID, Name: sName, Desc: sDesc, Value: sValue }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminHostsPolicyDel(pThis, iRecordPos, nRecordCount, pItems, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "HostsPolicyDel", RecordPos: iRecordPos, RecordCount: nRecordCount, Items: pItems }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminUsersGet(pThis, iRecordPos, nRecordCount, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "UsersGet", RecordPos: iRecordPos, RecordCount: nRecordCount }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminUsersSet(pThis, iRecordPos, nRecordCount, sUUID, pItem, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "UsersSet", RecordPos: iRecordPos, RecordCount: nRecordCount, UUID: sUUID, Item: pItem }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminUsersDel(pThis, iRecordPos, nRecordCount, pItems, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "UsersDel", RecordPos: iRecordPos, RecordCount: nRecordCount, Items: pItems }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminUsersSetPolicy(pThis, iRecordPos, nRecordCount, pUUID, sPolicyData, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "UsersSetPolicy", RecordPos: iRecordPos, RecordCount: nRecordCount, UUID: pUUID, PolicyData: sPolicyData }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminUsersPolicyGet(pThis, iRecordPos, nRecordCount, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "UsersPolicyGet", RecordPos: iRecordPos, RecordCount: nRecordCount }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminUsersPolicySet(pThis, iRecordPos, nRecordCount, sUUID, sName, sDesc, sValue, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "UsersPolicySet", RecordPos: iRecordPos, RecordCount: nRecordCount, UUID: sUUID, Name: sName, Desc: sDesc, Value: sValue }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminUsersPolicyDel(pThis, iRecordPos, nRecordCount, pItems, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "UsersPolicyDel", RecordPos: iRecordPos, RecordCount: nRecordCount, Items: pItems }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static AdminUsersPolicyBuild(pThis, OnResponse)
    {
        return RapidaIDService.Admin({ Type: "UsersPolicyBuild" }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

    static User(pRequest, OnResponse)
    {
        pRequest.SessionKey = RapidaIDSession.GetSessionKey();
        return WebServiceBase.CallPostJSON(RapidaIDService.ServiceURL + RapidaIDService.UserAPI, pRequest, function(pResponse) {
            console.log(pResponse);
            pResponse = pResponse ? pResponse : { Result: -1 };
            RapidaIDSession.UpdateData(pResponse);
            OnResponse(pResponse);
        });
    }

    static UserPolicySet(pThis, sAuthTheme, sUserPass, sUserLang, OnResponse)
    {
        return RapidaIDService.User({ Type: "PolicySet", AuthTheme: sAuthTheme, UserPass: sUserPass, UserLang: sUserLang }, function(pResponse) {
            OnResponse(pThis, pResponse.Result);
        });
    }

    static UserPolicyGet(pThis, OnResponse)
    {
        return RapidaIDService.User({ Type: "PolicyGet" }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse);
        });
    }

    static UserCardSet(pThis, sUUID, sName, sCardID, sPIN, OnResponse)
    {
        return RapidaIDService.User({ Type: "CardSet", UUID: sUUID, Name: sName, CardID: sCardID, PIN: sPIN }, function(pResponse) {
            OnResponse(pThis, pResponse.Result);
        });
    }

    static UserCardDel(pThis, sUUID, OnResponse)
    {
        return RapidaIDService.User({ Type: "CardDel", UUID: sUUID }, function(pResponse) {
            OnResponse(pThis, pResponse.Result);
        });
    }

    static UserMAGSSet(pThis, sUUID, sName, sCardID, sPIN, OnResponse)
    {
        return RapidaIDService.User({ Type: "MAGSSet", UUID: sUUID, Name: sName, CardID: sCardID, PIN: sPIN }, function(pResponse) {
            OnResponse(pThis, pResponse.Result);
        });
    }

    static UserMAGSDel(pThis, sUUID, OnResponse)
    {
        return RapidaIDService.User({ Type: "MAGSDel", UUID: sUUID }, function(pResponse) {
            OnResponse(pThis, pResponse.Result);
        });
    }

    static UserFIDOSet(pThis, sUUID, sName, sAppURL, sAppID, sPublicKey, sKeyHandle, sPIN, OnResponse)
    {
        return RapidaIDService.User({ Type: "FIDOSet", UUID: sUUID, Name: sName, AppURL: sAppURL, AppID: sAppID, KeyHandle: sKeyHandle, PublicKey: sPublicKey, PIN: sPIN }, function(pResponse) {
            OnResponse(pThis, pResponse.Result);
        });
    }

    static UserFIDODel(pThis, sUUID, OnResponse)
    {
        return RapidaIDService.User({ Type: "FIDODel", UUID: sUUID }, function(pResponse) {
            OnResponse(pThis, pResponse.Result);
        });
    }

    static UserPushAdd(pThis, sName, OnResponse)
    {
        return RapidaIDService.User({ Type: "PushAdd", Name: sName }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.QRCodeImg);
        });
    }

    static UserPushDel(pThis, sUUID, OnResponse)
    {
        return RapidaIDService.User({ Type: "PushDel", UUID: sUUID }, function(pResponse) {
            OnResponse(pThis, pResponse.Result);
        });
    }

    static UserOTPSet(pThis, sUUID, sName, sCode, sPIN, OnResponse)
    {
        return RapidaIDService.User({ Type: "OTPSet", UUID: sUUID, Name: sName, Secret: sCode, PIN: sPIN }, function(pResponse) {
            OnResponse(pThis, pResponse.Result);
        });
    }

    static UserOTPDel(pThis, sUUID, OnResponse)
    {
        return RapidaIDService.User({ Type: "OTPDel", UUID: sUUID }, function(pResponse) {
            OnResponse(pThis, pResponse.Result);
        });
    }

    static UserFPRTGet(pThis, OnResponse)
    {
        return RapidaIDService.User({ Type: "FPRTGet" }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.FPRTData);
        });
    }

    static UserFPRTSet(pThis, sUUID, sName, pData, sPIN, OnResponse)
    {
        return RapidaIDService.User({ Type: "FPRTSet", UUID: sUUID, Name: sName, Data: pData, PIN: sPIN }, function(pResponse) {
            OnResponse(pThis, pResponse.Result);
        });
    }

    static UserFPRTDel(pThis, sUUID, OnResponse)
    {
        return RapidaIDService.User({ Type: "FPRTDel", UUID: sUUID }, function(pResponse) {
            OnResponse(pThis, pResponse.Result);
        });
    }

    static UserActivityGet(pThis, iRecordPos, nRecordCount, OnResponse)
    {
        return RapidaIDService.User({ Type: "ActivityGet", RecordPos: iRecordPos, RecordCount: nRecordCount }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data, pResponse.StatApps, pResponse.StatEnrolls, pResponse.StatSSOs, pResponse.StatHosts);
        });
    }

    static UserAppsGet(pThis, OnResponse)
    {
        return RapidaIDService.User({ Type: "AppsGet" }, function(pResponse) {
            OnResponse(pThis, pResponse.Result, pResponse.Data);
        });
    }

};

export default RapidaIDService;
