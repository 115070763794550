//
//  @file RapidaIDSession.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import Cookies from "universal-cookie";

import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

class RapidaIDSession
{

    static SetSessionInfo(pSessionInfo)
    {
        const pCookie = new Cookies();
        pCookie.set("UserInfo", pSessionInfo ? pSessionInfo.UserInfo : "");
        pCookie.set("SessionKey", pSessionInfo ? pSessionInfo.SessionKey : "");
        pCookie.set("IsUserAdmin", pSessionInfo ? pSessionInfo.IsUserAdmin : false);
        pCookie.set("PushData", pSessionInfo ? pSessionInfo.PushData : "");
        pCookie.set("OTPData", pSessionInfo ? pSessionInfo.OTPData : "");
        pCookie.set("CardData", pSessionInfo ? pSessionInfo.CardData : "");
        pCookie.set("MAGSData", pSessionInfo ? pSessionInfo.MAGSData : "");
        pCookie.set("FIDOData", pSessionInfo ? pSessionInfo.FIDOData : "");
        pCookie.set("FPRTData", pSessionInfo ? pSessionInfo.FPRTData : "");
    }

    static ResetSession()
    {
        RapidaIDSession.SetSessionInfo(null);
    }

    static UpdateData(pResponse)
    {
        const pCookie = new Cookies();
        if(pResponse.Result === RapidaIDMainDefs.ERROR_USER_NOT_LOGGED)
        {
            RapidaIDSession.ResetSession();
            window.location = "#/auth/login-page";
            return;
        }
        if(pResponse.PushData !== undefined)
            pCookie.set("PushData", pResponse.PushData);
        if(pResponse.OTPData !== undefined)
            pCookie.set("OTPData", pResponse.OTPData);
        if(pResponse.CardData !== undefined)
            pCookie.set("CardData", pResponse.CardData);
        if(pResponse.MAGSData !== undefined)
            pCookie.set("MAGSData", pResponse.MAGSData);
        if(pResponse.FIDOData !== undefined)
            pCookie.set("FIDOData", pResponse.FIDOData);
        if(pResponse.FPRTData !== undefined)
            pCookie.set("FPRTData", pResponse.FPRTData);
    }

    static GetSessionValue(sName, pDefault)
    {
        var pValue = (new Cookies()).get(sName);
        return pValue === "null" || pValue === "undefined" || !pValue || pValue === undefined ? pDefault : pValue;
    }

    static GetUserInfo()
    {
        return RapidaIDSession.GetSessionValue("UserInfo", "");
    }

    static GetSessionKey()
    {
        return (new Cookies()).get("SessionKey");
    }

    static IsUserAdmin()
    {
        return (new Cookies()).get("IsUserAdmin") === "true";
    }

    static GetCardData()
    {
        return RapidaIDSession.GetSessionValue("CardData", []);
    }

    static SetCardData(sData)
    {
        return (new Cookies()).set("CardData", sData);
    }

    static GetMAGSData()
    {
        return RapidaIDSession.GetSessionValue("MAGSData", []);
    }

    static SetMAGSData(sData)
    {
        return (new Cookies()).set("MAGSData", sData);
    }

    static GetFIDOData()
    {
        return RapidaIDSession.GetSessionValue("FIDOData", []);
    }

    static SetFIDOData(sData)
    {
        return (new Cookies()).set("FIDOData", sData);
    }

    static GetPushData()
    {
        return RapidaIDSession.GetSessionValue("PushData", []);
    }

    static SetPushData(sData)
    {
        return (new Cookies()).set("PushData", sData);
    }

    static GetOTPData()
    {
        return RapidaIDSession.GetSessionValue("OTPData", []);
    }

    static SetOTPData(sData)
    {
        return (new Cookies()).set("OTPData", sData);
    }

    static GetFPRTData()
    {
        return RapidaIDSession.GetSessionValue("FPRTData", []);
    }

    static SetFPRTData(sData)
    {
        return (new Cookies()).set("FPRTData", sData);
    }

    static GetLang()
    {
        return RapidaIDSession.GetSessionValue("Lang", "English");
    }

    static SetLang(sLang)
    {
        return (new Cookies()).set("Lang", sLang);
    }

};

export default RapidaIDSession;
