//
//  @file UserRoutes.js
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import Activity from "views/User/Activity.jsx";
import Applications from "views/User/Applications.jsx";
import EnrollPush from "views/User/EnrollPush.jsx";
import EnrollOTP from "views/User/EnrollOTP.jsx";
import EnrollCard from "views/User/EnrollCard.jsx";
import EnrollMAGS from "views/User/EnrollMAGS.jsx";
import EnrollFIDO from "views/User/EnrollFIDO.jsx";
import EnrollFPRT from "views/User/EnrollFPRT.jsx";
import FilesMainDisk from "views/User/FilesMainDisk.jsx";
import HelpCenter from "views/User/HelpCenter.jsx";
import UserProfile from "views/User/UserProfile.jsx";
import UserSettings from "views/User/UserSettings.jsx";

var UserRoutes = [
    {
        path: "/activity",
        layout: "/user",
        name: global.StrActivity,
        icon: "pe-7s-note2",
        component: Activity,
        OnLangUpdate: function() { this.name = global.StrActivity; }
    },
    {
        path: "/applications",
        layout: "/user",
        name: global.StrApplications,
        icon: "pe-7s-browser",
        component: Applications,
        OnLangUpdate: function() { this.name = global.StrApplications; }
    },
    {
        collapse: true,
        path: "/enrollments",
        name: global.StrEnrollments,
        state: "openEnrollments",
        icon: "pe-7s-id",
        OnLangUpdate: function() { this.name = global.StrEnrollments; },
        views: [
            {
                path: "/push",
                layout: "/user",
                name: global.StrPush,
                mini: "\u00A0",
                component: EnrollPush,
                OnLangUpdate: function() { this.name = global.StrPush; }
            },
            {
                path: "/otp",
                layout: "/user",
                name: global.StrOTP,
                mini: "\u00A0",
                component: EnrollOTP,
                OnLangUpdate: function() { this.name = global.StrOTP; }
            },
            {
                path: "/card",
                layout: "/user",
                name: global.StrCard,
                mini: "\u00A0",
                component: EnrollCard,
                OnLangUpdate: function() { this.name = global.StrCard; }
            },
            {
                path: "/mags",
                layout: "/user",
                name: global.StrMagstripe,
                mini: "\u00A0",
                component: EnrollMAGS,
                OnLangUpdate: function() { this.name = global.StrMagstripe; }
            },
            {
                path: "/fido",
                layout: "/user",
                name: global.StrFIDO,
                mini: "\u00A0",
                component: EnrollFIDO,
                OnLangUpdate: function() { this.name = global.StrFIDO; }
            },
            {
                path: "/fprt",
                layout: "/user",
                name: global.StrFingerprint,
                mini: "\u00A0",
                component: EnrollFPRT,
                OnLangUpdate: function() { this.name = global.StrFingerprint; }
            }
        ]
    },
    {
        collapse: true,
        path: "/files",
        name: global.StrFiles,
        state: "openFiles",
        icon: "pe-7s-diskette",
        OnLangUpdate: function() { this.name = global.StrFiles; },
        views: [
            {
                path: "/maindisk",
                layout: "/user",
                name: global.StrMainDisk,
                mini: "\u00A0",
                component: FilesMainDisk,
                OnLangUpdate: function() { this.name = global.StrMainDisk; }
            }
        ]
    },
    {
        path: "/help",
        layout: "/user",
        name: global.StrHelpCenter,
        icon: "pe-7s-help2",
        component: HelpCenter,
        OnLangUpdate: function() { this.name = global.StrHelpCenter; }
    },
    {
        path: "/profile",
        layout: "/user",
        redirect: true,
        name: global.StrUserProfile,
        icon: null,
        component: UserProfile,
        OnLangUpdate: function() { this.name = global.StrUserProfile; }
    },
    {
        path: "/settings",
        layout: "/user",
        redirect: true,
        name: global.StrUserSettings,
        icon: null,
        component: UserSettings,
        OnLangUpdate: function() { this.name = global.StrUserSettings; }
    }
];

global.AddLangHandler(function() {
    global.SetLangRoute(UserRoutes);
});

export default UserRoutes;
