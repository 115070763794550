//
//  @file PromptWebKey.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import SweetAlert from "react-bootstrap-sweetalert";

class PromptWebKey extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
        };

        this.OnResult = this.OnResult.bind(this);

        this.RefFrame = React.createRef();

    }

    componentDidMount()
    {
        let sConfData = this.props.ConfData;
        console.log("componentDidMount5() -> " + sConfData);
        //sConfData = "650\n450\nws://127.0.0.1:54915\nwebkey\n1596598741\n1DSdXo6yqY6Sbpt/WQynSQ==";
        let pConfData = sConfData.split("\n");
        if(this.props.OnInit !== undefined)
            this.props.OnInit(this.props.Parent, this)
        let pFrame = this.RefFrame.current;
        let pWindow = pFrame.contentWindow;
        let pDocument = pWindow.document;
        //pDocument.body.style.backgroundColor = 'blue';
        //pDocument.body.style.margin = '0px';
        //pFrame.width = "100%";//pConfData[0];
        //pFrame.height = "100%";//pConfData[1];
        pDocument.write('<wk-content id="WK">');
        pDocument.write('<table style="background-color: #FFFFFF; font-family: Arial; padding:0; margin:0; height:100%; width:100%; position: absolute; top: 0; bottom: 0; left: 0; right: 0; border:1px solid">');
        pDocument.write('<tr style="height: 100%;">');
        pDocument.write('<td align="center" id="WK_Loading">Loading...</td>');
        pDocument.write('</tr>');
        pDocument.write('</table>');
        pDocument.write('</wk-content>');
        pWindow.PostEvent = function(eventType, eventArgs)
        {
            var xml = "<Event><EventType>" + eventType + "</EventType>";
            for (let i = 0; eventArgs != null && i < eventArgs.length; ++i) {
                var arg = eventArgs[i];
                xml += "<" + arg[0] + ">" + arg[1] + "</" + arg[0] + ">";
            }
            xml += "</Event>";
            pWindow.Socket.send(xml);
        }
        pWindow.Socket = new WebSocket(pConfData[2], pConfData[3]);//"ws://127.0.0.1:54915", "webkey");
        pWindow.Socket.onopen = function() {
            console.log("Socket.onopen()");
            pWindow.PostEvent("Handshake", [["Time", pConfData[4]], ["Hash", pConfData[5]]]);
            //setInterval (function() { PostHeartbeatMessage(); }, 5000);
        };
        pWindow.Socket.onclose = function (event) {
            console.log("Socket.onclose()");
        };
        pWindow.Socket.onerror = function (event) {
            console.log("Socket.onerror()");
        };
        pWindow.Socket.onmessage = function(event) {
            //console.log("Socket.onmessage() -> " + event.data);
            try { pWindow.eval(event.data); } catch (ex) {
                console.log('Error executing script starting with ' + event.data.substring(0, 25) + ':\n'+ ex.toString());
            }
        };
    }

    componentWillUnmount()
    {
        let pFrame = this.RefFrame.current;
        let pWindow = pFrame.contentWindow;
        console.log("componentWillUnmount()");
        if(pWindow.Socket !== undefined)
            pWindow.Socket.close();
        pWindow.Socket = undefined;
        pWindow.PostEvent = undefined;
        if(this.props.OnDone !== undefined)
            this.props.OnDone(this.props.Parent, this)
    }

    OnResult(fResult)
    {
        //console.log("PromptProgress::OnResult()");
        if(!fResult)
        {
            this.SetStatusText(global.StrCancelling);
            if(this.ServiceRequest !== undefined && this.ServiceRequest)
            {
                this.ServiceRequest.abort();
                this.ServiceRequest = null;
            }
            this.props.OnCancel(this.props.Parent, this)
        }
    }

    SetStatusText(sText)
    {
        this.setState({StatusText: sText});
    }

    render()
    {
        const { ConfData } = this.props;
        let pConfData = ConfData.split("\n");
        //console.log("ConfData: " + pConfData[0] + ", " + pConfData[1]);
        return (
            <SweetAlert
                style={{ display: "block", width: parseInt(pConfData[0]), height: parseInt(pConfData[1]) + 55 }}
                title={""}
                openAnim={false}
                showConfirm={false}
                showCancel={false}
                closeOnClickOutside={false}
                onConfirm={() => this.OnResult(true)}
                onCancel={() => this.OnResult(false)}
            >
                <iframe ref={this.RefFrame} title="WebKey" frameBorder="0" width="100%" height="100%" style={{ padding: 0, margin: 0, position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}>
                    {/*<wk-content id="WK">
                        <table style={{backgroundColor: "#FFFFFF", fontFamily: "Arial", fontSize: "10px", padding: 0, margin: 0, height: "100%", width: "100%", position: "absolute", top: 0, bottom: 0, left: 0, right: 0, border: "1px solid"}}>
                            <tbody>
                                <tr style={{height: "100%"}}>
                                    <td align="center" id="WK_Loading">Loading...</td>
                                </tr>
                            </tbody>
                        </table>
                    </wk-content>*/}
                </iframe>
            </SweetAlert>
        );
    }

}

export default PromptWebKey;
