//
//  @file Footer.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

/*eslint-disable*/
import React, { Component } from "react";

import RapidaIDUtils from "scripts/RapidaIDUtils.jsx";

class Footer extends Component
{

    componentDidMount()
    {
        RapidaIDUtils.AddComponent(this);
    }

    componentWillUnmount()
    {
        RapidaIDUtils.DelComponent(this);
    }

    render()
    {
        return (
            <footer className={"footer" + (this.props.transparent !== undefined ? " footer-transparent" : "")}>
                <div className={"container" + (this.props.fluid !== undefined ? "-fluid" : "")}>
                    <nav className="pull-left">
                        <ul>
                            <li>
                                <a href="https://rapida.us">{global.StrHome}</a>
                            </li>
                            <li>
                                <a href="https://rapida.us/contact.html">{global.StrCompany}</a>
                            </li>
                            <li>
                                <a href="https://rapida.us/blog.html">{global.StrBlog}</a>
                            </li>
                        </ul>
                    </nav>
                    <p className="copyright pull-right">
                        <a href="https://rapida.us" target="_blank">rapida.us</a>
                        &nbsp;&copy;{1900 + new Date().getYear()}{" "}
                    </p>
                </div>
            </footer>
        );
    }

}

export default Footer;
