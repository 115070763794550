//
//  @file EnrollCard.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import EnrollBase from "views/User/EnrollBase.jsx";

import EnrollCardStep1 from "views/User/EnrollCardStep1.jsx";
import EnrollCardStep2 from "views/User/EnrollCardStep2.jsx";
import EnrollCardStep3 from "views/User/EnrollCardStep3.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDSession from "scripts/RapidaIDSession.jsx";

import ImgTile from "assets/img/TileRFID.png";

class EnrollCard extends Component
{

    constructor(props)
    {

        super(props);

        this.TokenInfo = {
            UUID: "",
            Name: "",
            Data: "",
            PIN: ""
        };

        this.TokenData = [ ];

        this.OnPrepareEdit = this.OnPrepareEdit.bind(this);
        this.OnTokenEnrolled = this.OnTokenEnrolled.bind(this);
        this.OnTokenRemoved = this.OnTokenRemoved.bind(this);

    }

    componentDidMount()
    {

        this.ReloadTokenData();

    }

    ReloadTokenData()
    {

        this.TokenData.splice(0, this.TokenData.length);

        var pData = RapidaIDSession.GetCardData();
        for(var iItem = 0; iItem < pData.length; iItem++)
            this.TokenData.push({ UUID: pData[iItem].UUID, Name: pData[iItem].Name });

        this.forceUpdate();

        console.log(pData);

    }

    OnPrepareEdit(iItem, OnResult)
    {

        this.TokenInfo.UUID = iItem === -1 ? "" : this.TokenData[iItem].UUID;
        this.TokenInfo.Name = iItem === -1 ? "" : this.TokenData[iItem].Name;
        this.TokenInfo.Data = "";
        this.TokenInfo.PIN = "";
        this.TokenInfo.CPIN = "";

        var pSteps = [
            { name: global.StrName, component: <EnrollCardStep1 OnResult={OnResult} TokenInfo={this.TokenInfo}/> },
            { name: global.StrCard, component: <EnrollCardStep2 OnResult={OnResult} TokenInfo={this.TokenInfo}/> },
            { name: global.StrPIN, component: <EnrollCardStep3 OnResult={OnResult} TokenInfo={this.TokenInfo}/> },
        ];

        return pSteps;
    }

    OnTokenEnrolled(iItem)
    {

        console.log("EnrollCard::OnTokenEnrolled()");

        this.ReloadTokenData();
        /*if(iItem === -1)
            this.TokenData.push({ UUID: this.TokenInfo.UUID, Name: this.TokenInfo.Name });
        else
        {
            this.TokenData[iItem].UUID = this.TokenInfo.UUID;
            this.TokenData[iItem].Name = this.TokenInfo.Name;
        }*/

    }

    OnTokenRemoved(iItem)
    {
        console.log("EnrollCard::OnTokenRemoved(" + iItem + ")");
        RapidaIDService.UserCardDel(this, this.TokenData[iItem].UUID, function(pThis, eResult) {
            pThis.ReloadTokenData();
            //if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                //pThis.TokenData.splice(iItem, 1);
        });
    }

    render()
    {
        return (
            <EnrollBase 
                ImgTile={ImgTile} 
                EnrollTitle={global.StrEnrollNewToken} 
                BriefDesc={global.StrHereEnrollUpdateDeleteToken}
                TokenData={this.TokenData}
                TokenInfo={this.TokenInfo}
                OnPrepareEdit={this.OnPrepareEdit}
                OnTokenEnrolled={this.OnTokenEnrolled}
                OnTokenRemoved={this.OnTokenRemoved}
            />
        );
    }

}

export default EnrollCard;
