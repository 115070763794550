//
//  @file PromptWizard.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import SweetAlert from "react-bootstrap-sweetalert";

import StepZilla from "react-stepzilla";

class PromptWizard extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
        };
        
    }

    render()
    {
        const { CustomWidth } = this.props;
        let pStyle = CustomWidth !== undefined ? { width: CustomWidth } : {}
        pStyle.display = "block";
        return (
            <SweetAlert
                style={pStyle}
                title={""}
                showConfirm={false}
                closeOnClickOutside={false}
                onConfirm={() => this.props.OnResult(true)}
                onCancel={() => this.props.OnResult(false)}
            >
                <span style={{ fontSize: '14px', textAlign: 'left' }}>
                    {this.props.title}
                    <StepZilla
                        steps={this.props.steps}
                        stepsNavigation={false}
                        showNavigation={false}
                        preventEnterSubmission={false}
                        nextButtonCls="btn btn-prev btn-info btn-fill pull-right btn-wd"
                        backButtonCls="btn btn-next btn-default btn-fill pull-left btn-wd"
                    />
                </span>
            </SweetAlert>
        );
    }

}

export default PromptWizard;
