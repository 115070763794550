//
//  @file EnrollFPRTStep2b.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.07.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React from "react";

import { Row, Col } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

import ImgFingerNormal from "assets/img/FingerNormal.png";

const EStateEnroll = 0;
const EStateFailed = 1;
const EStateVerify = 2;
//const EStateSameFound = 3;
const EStateComplete = 4;

class EnrollFPRTStep2b extends React.Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            State: EStateEnroll,
            StatusText: global.StrConnectingDeviceWait,
            ProgressText: "",
            QualityText: ""
        }

        this.OnCancel = this.OnCancel.bind(this);
        this.OnContinue = this.OnContinue.bind(this);
        this.OnStartOver = this.OnStartOver.bind(this);
        this.OnCompleted = this.OnCompleted.bind(this);

        this.OnResponseBIOEnroll = this.OnResponseBIOEnroll.bind(this);
        this.OnResponseBIOIdentify = this.OnResponseBIOIdentify.bind(this);

        this.SetStatus = this.SetStatus.bind(this);
        this.PerformEnroll = this.PerformEnroll.bind(this);
        this.VerifyDuplicate = this.VerifyDuplicate.bind(this);

        this.ServiceRequest = null;

    }

    componentDidMount()
    {
        this.SavedOnResponseBIOEnroll = this.props.Channel.OnResponseBIOEnrollHandler;
        this.props.Channel.OnResponseBIOEnrollHandler = this.OnResponseBIOEnroll;
        this.SavedOnResponseBIOIdentify = this.props.Channel.OnResponseBIOIdentifyHandler;
        this.props.Channel.OnResponseBIOIdentifyHandler = this.OnResponseBIOIdentify;
        this.OnStartOver();
    }

    componentWillUnmount()
    {
        this.props.Channel.OnResponseBIOEnrollHandler = this.SavedOnResponseBIOEnroll;
        this.props.Channel.OnResponseBIOIdentifyHandler = this.SavedOnResponseBIOIdentify;
    }

    SetStatus(eState, pStatusText, pProgressText, pQualityText)
    {
        if(pProgressText === undefined)
            pProgressText = this.state.ProgressText;
        if(pQualityText === undefined)
            pQualityText = this.state.QualityText;
        this.setState({ State: eState, StatusText: pStatusText, ProgressText: pProgressText, QualityText: pQualityText });
    }

    PerformEnroll()
    {
        this.SetStatus(EStateEnroll, global.StrConnectingDeviceWait, "", "");
        this.FingerName = RapidaIDMainDefs.BioFingerToString(this.props.FingerID);
        console.log(this.props.FingerID + ", " + this.FingerName);
        this.props.Channel.RequestBIOEnroll(this.props.Channel.BIODevice, "", this.FingerName);
    }

    VerifyDuplicate(sTemplate)
    {
        if(this.props.Channel.BIOStorage)
        {
            this.props.TokenInfo.Data.push({ Finger: this.FingerName });
            this.OnCompleted();
            return;
        }
        this.SetStatus(EStateVerify, global.StrConnectingDeviceWait, "", "");
        this.props.TokenInfo.Data.push(JSON.parse(sTemplate));
        this.props.Channel.RequestBIOIdentify(this.props.Channel.BIODevice, "", JSON.stringify(this.props.TokenInfo.Data));
    }

    OnCancel()
    {
        this.props.OnCancel();
    }

    OnContinue()
    {
        this.props.OnContinue();
    }

    OnStartOver()
    {
        this.PerformEnroll();
    }
    
    OnCompleted()
    {
        this.SetStatus(EStateComplete, global.StrNewFingerEnrolled, "", "");
    }

    OnResponseBIOEnroll(eResult, ePrompt, eQuality, nProgress, sTemplate)
    {
        console.log("EnrollFPRTStep2b::OnResponseBIOEnroll(" + eResult + ", " + ePrompt + ", " + eQuality + ", " + nProgress + ", " + sTemplate + ")");
        if(eResult === RapidaIDMainDefs.EBioResultFailed)
            this.SetStatus(EStateFailed, (<center><div className="text-danger">{global.StrEnrollmentFailed}</div></center>));
        else if(eResult === RapidaIDMainDefs.EBioResultProgress)
            this.SetStatus(EStateEnroll, RapidaIDMainDefs.BioPromptToString(ePrompt), nProgress + global.StrPercDone, RapidaIDMainDefs.BioQualityToString(eQuality));
        else if(eResult === RapidaIDMainDefs.EBioResultAlreadyEnrolled)
            this.SetStatus(EStateFailed, (<center><div className="text-danger">{global.StrThisFingerAlreadyEnrolled}</div></center>), null, null);
        else if(eResult === RapidaIDMainDefs.EBioResultSuccess)
            this.VerifyDuplicate(sTemplate);
    }

    OnResponseBIOIdentify(eResult, ePrompt, eQuality, sTemplate, iTemplate, sUserInfo)
    {
        console.log("EnrollFPRTStep2b::OnResponseBIOIdentify(" + eResult + ", " + ePrompt + ", " + eQuality + ", " + sTemplate + ", " + iTemplate + ", " + sUserInfo + ")");
        if(eResult === RapidaIDMainDefs.EBioResultProgress)
            this.setState({ StatusText: RapidaIDMainDefs.BioPromptToString(ePrompt) });
        else if(eResult === RapidaIDMainDefs.EBioResultFailed)
        {
            this.SetStatus(EStateFailed, (<center><div className="text-danger">{global.StrIdentifyFailed}</div></center>));
            this.props.TokenInfo.Data.pop();
        }
        else if(eResult === RapidaIDMainDefs.EBioResultSuccess)
        {
            if(iTemplate !== this.props.TokenInfo.Data.length - 1)
            {
                this.SetStatus(EStateFailed, (<center><div className="text-danger">{global.StrThisFingerAlreadyEnrolled}.</div></center>));
                this.props.TokenInfo.Data.pop();
            }
            else
                this.OnCompleted();
        }
        else if(eResult === RapidaIDMainDefs.EBioResultNotFound)
            this.OnCompleted();
    }

    render()
    {
        return (
            <div className="wizard-step">
                <br/>
                <br/>
                <br/>
                <center>{global.StrPleaseSelectFingerEnrollDelete}</center>
                <br/>
                <Row>
                    <Col sm={12}>
                        <center>
                            <img src={ImgFingerNormal} alt="FingerScan" width="96" height="96"/>
                            <br/>
                            {this.state.StatusText}
                            <br/>
                            {this.state.ProgressText}
                            <br/>
                            {this.state.QualityText}
                        </center>
                    </Col>
                </Row>
                <br/>
                <br/>
                <center>
                    {this.state.State !== EStateComplete && <Button fill onClick={this.OnCancel}>{global.StrCancel}</Button>}&nbsp;
                    {this.state.State === EStateComplete && <Button fill onClick={this.OnContinue}>{global.StrContinue}</Button>}
                    {this.state.State === EStateFailed && <Button fill onClick={this.OnStartOver}>{global.StrStartOver}</Button>}
                </center>
            </div>
        );
    }

}

export default EnrollFPRTStep2b;
