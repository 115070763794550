//
//  @file UserCard.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

export class UserCard extends Component
{
    render()
    {
        return (
            <div className="card card-user">
              <div className="image">
                <img src={this.props.bgImage} alt="..." />
              </div>
              <div className="content">
                <div className="author">
                  <a href="#pablo">
                    <img
                      className="avatar border-gray"
                      src={this.props.avatar}
                      alt="..."
                    />
                    <h4 className="title">
                      {this.props.name}
                      <br />
                      <small>{this.props.userName}</small>
                    </h4>
                  </a>
                </div>
                <p className="description text-center">{this.props.description}</p>
              </div>
              <hr />
              <div className="text-center">{this.props.socials}</div>
            </div>
        );
    }
}

export default UserCard;
