//
//  @file ActivatePage.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";

import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";
import RapidaIDUtils from "scripts/RapidaIDUtils.jsx";

const EStateRequested = 0;
const EStateActivated = 1;
const EStateFailed = 2;

class ActivatePage extends Component
{

    constructor(props)
    {

        super(props);
        
        this.state = {
            CardContent: this.CardContentRequested(),
            State: EStateRequested
        };

        this.RefCard = React.createRef();
        
        this.OnContinue = this.OnContinue.bind(this);

    }

    componentDidMount()
    {

        //console.log(this.props.location.search);

        const pParams = new URLSearchParams(this.props.location.search);
        const sUserInfo = pParams.get("user");
        var sRegKey = pParams.get("key");
        sRegKey = sRegKey.length > 2 ? sRegKey.substring(1, sRegKey.length - 1) : "";

        console.log(sUserInfo);
        console.log(sRegKey);

        RapidaIDService.LoginActivate(this, sUserInfo, sRegKey, function (pThis, pResponse) {
            if(pResponse.Result === RapidaIDMainDefs.ERROR_SUCCESS)
                pThis.setState({ State: EStateActivated, CardContent: pThis.CardContentActivated() });
            else
                pThis.setState({ State: EStateFailed, CardContent: pThis.CardContentError(pResponse.Result) });
        });

        RapidaIDUtils.AddComponent(this);

    }

    componentWillUnmount()
    {

        RapidaIDUtils.DelComponent(this);

    }

    CardContentRequested()
    {
        return (
            <div>
                <center>
                    {global.StrActivatingPleaseWait}
                </center>
            </div>
        );
    }

    CardContentActivated()
    {
        return (
            <div>
                <center>
                    {global.StrUserActivatedPleaseLogin}
                </center>
            </div>
        );
    }

    CardContentError(nError)
    {
        return (
            <div>
                <center>
                    {global.StrActivationErrorSS}{nError}
                </center>
            </div>
        );
    }

    OnContinue()
    {
        window.location = "#/auth/login-page";
    }

    render()
    {
        return (
            <Grid>
                <Row>
                    <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                        <form>
                            <Card
                              ref={this.RefCard}
                              title="Activation"
                              textCenter
                              content={this.state.CardContent}
                              ftTextCenter
                              legend={
                                <div>
                                    <Button onClick={this.OnContinue} hidden={this.state.State !== EStateActivated}>{global.StrContinue}</Button>
                                </div>
                              }
                            />
                        </form>
                    </Col>
                </Row>
            </Grid>
        );
    }
    
}

export default ActivatePage;
