//
//  @file EnrollCardStep3.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React from "react";

import { Row, Col, FormGroup, FormControl, ControlLabel } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

const EStatePIN = 0;
const EStateUpdate = 1;
const EStateError = 2;
const EStateDone = 3;

class EnrollCardStep3 extends React.Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            State: EStatePIN,
            TextStatus: null
        };

        this.OnCancel = this.OnCancel.bind(this);
        this.OnContinue = this.OnContinue.bind(this);

    }

    componentWillUnmount()
    {
        this.ServiceRequest = RapidaIDService.Abort(this.ServiceRequest);
    }

    isValidated()
    {
        return !this.IsValidated() || this.state.State !== EStateDone;
    }

    IsValidated()
    {
        var sError = null;
        if(this.props.TokenInfo.PIN.length < 4)
            sError = global.StrPINShouldBeComplexity;
        else if(this.props.TokenInfo.PIN !== this.props.TokenInfo.CPIN)
            sError = global.StrPINShouldMatchConfirmation;
        if(sError)
            this.setState({ TextStatus: (<small className="text-danger">{sError}</small>) });
        return !sError;
    }

    OnCancel()
    {
        this.props.OnResult(false);
    }

    OnContinue()
    {
        if(!this.IsValidated())
            return;
        this.setState({ State: EStateUpdate, TextStatus: global.StrPleaseWaitUpdatingInfo });
        var pData = this.props.TokenInfo;
        this.ServiceRequest = RapidaIDService.UserCardSet(this, pData.UUID, pData.Name, pData.Data, pData.PIN, function(pThis, eResult) {
            if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                pThis.props.OnResult(true);
            else
                pThis.setState({State: EStateError, TextStatus: <div className="text-danger">{global.StrFailedToUpdateInfo}({global.StrErrorSemiSpace}{eResult}).</div>});
        });
    }

    render()
    {
        //<Row noGutters={true}>
        return (
            <div className="wizard-step">
                <br/>
                <br/>
                <br/>
                <center>{global.StrPleaseFillRequiredInfo}</center>
                <br/>
                <center>{this.state.TextStatus}</center>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <ControlLabel>
                                <span style={{fontSize: '14px', fontWeight: 'normal' }}>
                                    {global.StrPIN} <span className="text-danger">*</span>
                                </span>
                            </ControlLabel>
                            <FormControl
                                type="password"
                                name="PIN"
                                placeholder={global.StrPIN}
                                defaultValue=""
                                autoFocus
                                disabled={this.state.State !== EStatePIN}
                                onChange={event => this.props.TokenInfo.PIN = event.target.value}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <ControlLabel>
                                <span style={{fontSize: '14px', fontWeight: 'normal' }}>
                                    Confirm PIN <span className="text-danger">*</span>
                                </span>
                            </ControlLabel>
                            <FormControl
                                type="password"
                                name="ConfirmPIN"
                                placeholder={global.StrConfirmPIN}
                                defaultValue=""
                                disabled={this.state.State !== EStatePIN}
                                onKeyPress={event => { if (event.key === "Enter") { this.OnContinue(); } }}
                                onChange={event => this.props.TokenInfo.CPIN = event.target.value}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <center>
                    <Button fill onClick={this.OnCancel}>{global.StrCancel}</Button>
                    <Button fill onClick={this.OnContinue} disabled={this.state.State !== EStatePIN}>{global.StrFinish}</Button>
                </center>
            </div>
        );
    }

}

export default EnrollCardStep3;
