//
//  @file PromptTextArea.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import {
  //Grid,
  //Row,
  //Col,
  //FormGroup,
  //ControlLabel,
  //FormControl,
  //Form,
  //InputGroup
} from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import SweetAlert from "react-bootstrap-sweetalert";

import FormInputText from "components/FormInputs/FormInputText.jsx";

class PromptTextArea extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            TextError: null
        }

        this.OnResult = this.OnResult.bind(this);

    }

    /*componentWillMount()
    {
    }*/

    OnResult(fResult)
    {
        if(fResult)
        {
            /*let sError = "";
            if(this.props.ItemInfo.Name.length < 5)
                sError = "Name should 5 or more symbols";
            if(sError !== "")
            {
                this.setState({ TextError: (<center><small className="text-danger">{sError}</small></center>) });
                return;
            }*/
        }
        this.props.OnResult(this.props.Parent, this, fResult);
    }

    render()
    {
        const { ItemInfo, FieldName } = this.props;
        return (
            <SweetAlert
                style={{ display: "block" }}
                title={""}
                openAnim={false}
                showConfirm={false}
                closeOnClickOutside={false}
                onConfirm={() => this.props.OnResult(true)}
                onCancel={() => this.props.OnResult(false)}
            >
                <span style={{ fontSize: '14px', textAlign: 'left' }}>
                {this.props.title}
                    <FormInputText autoFocus placeholder="Configuration" componentClass="textarea" rows="12" style={{fontFamily: 'monospace'}} defaultValue={ItemInfo[FieldName]} onChange={event => ItemInfo[FieldName] = event.target.value}/>
                    {this.state.TextError}
                    <br/>
                    <center>
                        <Button fill onClick={() => this.OnResult(true)}>Save</Button>
                        <Button fill onClick={() => this.OnResult(false)}>Cancel</Button>
                    </center>
                </span>
            </SweetAlert>
        );
    }

}

export default PromptTextArea;
