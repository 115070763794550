//
//  @file EnrollFPRTHands.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.07.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React from "react";

import ImgHandLeft from "assets/img/HandLeft.png";
import ImgHandRight from "assets/img/HandRight.png";

import EnrollFPRTFinger from "views/User/EnrollFPRTFinger.jsx";

import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

class EnrollFPRTHands extends React.Component
{

    constructor(props)
    {

        super(props);

        this.state = {
        }

        this.OnFingerClicked = this.OnFingerClicked.bind(this);

    }

    OnFingerClicked(e)
    {
        //console.log(e);
        //e.SetEnrolled(false);
        this.props.OnFingerClicked(e);
    }

    render()
    {
        const { EnrolledFingers } = this.props;
        return (
            <div>
                <img src={ImgHandLeft} alt="LeftHand" width="256" height="256"/>
                <img src={ImgHandRight} alt="RightHand" width="256" height="256"/>
                <EnrollFPRTFinger OnClick={ e => { this.OnFingerClicked(e); } } EnrolledFingers={EnrolledFingers} FingerID={RapidaIDMainDefs.EBioFingerLLittle} PosX="23px" PosY="77px"/>
                <EnrollFPRTFinger OnClick={ e => { this.OnFingerClicked(e); } } EnrolledFingers={EnrolledFingers} FingerID={RapidaIDMainDefs.EBioFingerLRing} PosX="68px" PosY="35px"/>
                <EnrollFPRTFinger OnClick={ e => { this.OnFingerClicked(e); } } EnrolledFingers={EnrolledFingers} FingerID={RapidaIDMainDefs.EBioFingerLMiddle} PosX="113px" PosY="5px"/>
                <EnrollFPRTFinger OnClick={ e => { this.OnFingerClicked(e); } } EnrolledFingers={EnrolledFingers} FingerID={RapidaIDMainDefs.EBioFingerLIndex} PosX="158px" PosY="45px"/>
                <EnrollFPRTFinger OnClick={ e => { this.OnFingerClicked(e); } } EnrolledFingers={EnrolledFingers} FingerID={RapidaIDMainDefs.EBioFingerLThumb} PosX="213px" PosY="160px"/>
                <EnrollFPRTFinger OnClick={ e => { this.OnFingerClicked(e); } } EnrolledFingers={EnrolledFingers} FingerID={RapidaIDMainDefs.EBioFingerRThumb} PosX="280px" PosY="160px"/>
                <EnrollFPRTFinger OnClick={ e => { this.OnFingerClicked(e); } } EnrolledFingers={EnrolledFingers} FingerID={RapidaIDMainDefs.EBioFingerRIndex} PosX="335px" PosY="45px"/>
                <EnrollFPRTFinger OnClick={ e => { this.OnFingerClicked(e); } } EnrolledFingers={EnrolledFingers} FingerID={RapidaIDMainDefs.EBioFingerRMiddle} PosX="380px" PosY="5px"/>
                <EnrollFPRTFinger OnClick={ e => { this.OnFingerClicked(e); } } EnrolledFingers={EnrolledFingers} FingerID={RapidaIDMainDefs.EBioFingerRRing} PosX="425px" PosY="35px"/>
                <EnrollFPRTFinger OnClick={ e => { this.OnFingerClicked(e); } } EnrolledFingers={EnrolledFingers} FingerID={RapidaIDMainDefs.EBioFingerRLittle} PosX="470px" PosY="77px"/>
            </div>
        );
    }

}

export default EnrollFPRTHands;
