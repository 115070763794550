//
//  @file AuthCardOTP.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import { FormGroup, ControlLabel } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import FormLoginInput from "components/FormInputs/FormLoginInput.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";

import ImgTile from "assets/img/TileOTP.png";

class AuthCardOTP extends Component
{
    
    constructor(props)
    {

        super(props);

        this.state = {
            IsRequest: false
        };

        this.OnSubmitClicked = this.OnSubmitClicked.bind(this);
        this.OnCancelClicked = this.OnCancelClicked.bind(this);
        
        this.RefPassword = React.createRef();

    }

    OnSubmitClicked(sPassword)
    {

        this.setState({ IsRequest: true });

        RapidaIDService.LoginCheckOTP(this, this.props.AuthKey, this.props.AuthUser, sPassword, function (pThis, pResponse) {
            pThis.setState({ IsRequest: false });
            pThis.props.OnResult(pResponse.Result, pResponse);
        });

    }

    OnCancelClicked()
    {
        this.props.OnCancel();
    }

    render()
    {
        return (
            <div>
                <center><img src={ImgTile} alt="TileOTP" width="96" height="96"/></center>
                <br/>
                <FormGroup>
                    <ControlLabel>{global.StrOneTimePassword}</ControlLabel>
                    <FormLoginInput OnSubmit={this.OnSubmitClicked} placeholder={global.StrOneTimePassword} type="password" defaultValue="" disabled={this.state.IsRequest}/>
                </FormGroup>
                <br/>
                <center>
                    <Button bsStyle="info" fill wd onClick={this.OnCancelClicked.bind(this)} disabled={this.state.IsRequest}>
                        {global.StrCancel}
                    </Button>
                </center>
            </div>
        );
    }
    
}

export default AuthCardOTP;
