//
//  @file FormLoginInput.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { InputGroup } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import FormInputText from "components/FormInputs/FormInputText.jsx";

export class FormLoginInput extends Component
{

    constructor(props)
    {

        super(props);

        this.OnSubmitClicked = this.OnSubmitClicked.bind(this);

        this.RefPassword = React.createRef();

    }

    OnSubmitClicked()
    {

        this.props.OnSubmit(this.RefPassword.value);

    }

    ResetValue()
    {
        this.RefPassword.value = "";
    }

    render()
    {
        const { placeholder, type, defaultValue, disabled } = this.props;
        return (
            <InputGroup>
                <FormInputText 
                    className="login-input" 
                    inputRef={(input) => this.RefPassword = input} 
                    placeholder={placeholder} 
                    type={type} 
                    defaultValue={defaultValue} 
                    disabled={disabled} 
                    onKeyPress={event => { if (event.key === "Enter") { this.OnSubmitClicked(); event.preventDefault(); } }} 
                    autoFocus/>
                <InputGroup.Button>
                    <Button fill onClick={this.OnSubmitClicked} disabled={disabled}>
                        <img width="1" height="22" alt=""/>
                        &gt;
                    </Button>
                </InputGroup.Button>
            </InputGroup>
        );
    }

}

export default FormLoginInput;
