//
//  @file RegisterPage.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { Grid, Row, Col, Media, FormControl, FormGroup } from "react-bootstrap";

import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";
import RapidaIDUtils from "scripts/RapidaIDUtils.jsx";

import SweetAlert from "react-bootstrap-sweetalert";

const EStateInputData = 0;
const EStateActivation = 1;

class RegisterPage extends Component
{

    constructor(props)
    {

        super(props);
        
        this.state = {
            Alert: null,
            EmailError: null,
            PasswordError: null,
            ConfirmError: null,
            IsRequest: false,
            State: EStateInputData
        };
        
        this.ActivationLink = null;

        this.RefEmail = React.createRef();
        this.RefPassword = React.createRef();
        this.RefConfirm = React.createRef();

        this.OnSubmit = this.OnSubmit.bind(this);
        this.OnShowAlert = this.OnShowAlert.bind(this);
        this.OnHideAlert = this.OnHideAlert.bind(this);
        this.OnEmailChange = this.OnEmailChange.bind(this);
        this.OnPasswordChange = this.OnPasswordChange.bind(this);
        this.OnConfirmChange = this.OnConfirmChange.bind(this);

    }

    componentDidMount()
    {        

        RapidaIDUtils.AddComponent(this);

    }

    componentWillUnmount()
    {

        RapidaIDUtils.DelComponent(this);

    }

    OnSubmit()
    {

        if(!this.ValidateFields())
        {
            this.OnShowAlert(global.StrValidationError, global.StrPleaseCheckEPCFields);
            return;
        }

        this.setState({ IsRequest: true });

        RapidaIDService.LoginRegister(this, this.RefEmail.value, this.RefPassword.value, function (pThis, pResponse) {
            pThis.setState({ IsRequest: false });
            if(pResponse.Result === RapidaIDMainDefs.ERROR_SUCCESS)
            {
                pThis.ActivationLink = "#/auth/activate-page" + pResponse.Link;
                pThis.setState({ State: EStateActivation });
            }
            else
                pThis.OnShowAlert(global.StrRegistrationError, pResponse.Result);
        });

    }

    OnShowAlert(sTitle, sMessage)
    {
        this.setState({
            Alert: (
                <SweetAlert
                  style={{ display: "block" }}
                  title={sTitle}
                  onConfirm={() => this.OnHideAlert(this)}
                  onCancel={() => this.OnHideAlert(this)}
                  confirmBtnBsStyle="info"
                >
                    {sMessage}
                </SweetAlert>
            )
        });
    }
    
    OnHideAlert()
    {
        this.setState({ Alert: null });
    }
    
    OnEmailChange(event)
    {
        var pError = !RapidaIDUtils.IsValidaEmail(this.RefEmail.value) ? (<small className="text-danger">{global.StrEmailFormatShouldBe}<i>john@doe.com</i>.</small>) : null;
        if(this.state.EmailError !== pError)
            this.setState({ EmailError: pError });
        //console.log("RegisterPage::OnEmailChange() -> " + pError);
        return pError === null;
    }

    OnPasswordChange(event)
    {
        var pError = !RapidaIDUtils.IsValidPassword(this.RefPassword.value) ? (<small className="text-danger">{global.StrPasswordShouldBeFormat}</small>) : null;
        if(this.state.PasswordError !== pError)
            this.setState({ PasswordError: pError });
        this.OnConfirmChange(null);
        //console.log("RegisterPage::OnPasswordChange() -> " + pError);
        return pError === null;
    }

    OnConfirmChange(event)
    {
        var pError = this.RefPassword.value !== this.RefConfirm.value ? (<small className="text-danger">{global.StrConfShouldMatchPassword}</small>) : null;
        if(this.state.ConfirmError !== pError)
            this.setState({ ConfirmError: pError });
        //console.log("RegisterPage::OnConfirmChange() -> " + pError);
        return pError === null;
    }

    ValidateFields()
    {
        if(!this.OnEmailChange(null))
            return false;
        if(!this.OnPasswordChange(null))
            return false;
        if(!this.OnConfirmChange(null))
            return false;
        return true;//!this.state.EmailError && !this.state.PasswordError && !this.state.ConfirmError;
    }

    GetFormContent()
    {
        if(this.state.State === EStateInputData)
            return this.GetRegisterForm();
        if(this.state.State === EStateActivation)
            return this.GetActivationForm();
        return (<div></div>);
    }

    GetActivationForm()
    {
        return (
            <div>
                <Media>
                    <Media.Body>
                        {global.StrActivationMailHasBeenSent}
                        <br/>
                        <a href={this.ActivationLink}>Activate</a>
                    </Media.Body>
                </Media>
            </div>
        );
    }

    GetRegisterForm()
    {
        return (
            <div>
                <FormGroup>
                    <FormControl inputRef={(input) => this.RefEmail = input} onChange={event => this.OnEmailChange(event)} type="email" placeholder={global.StrEnterEmail} autoFocus disabled={this.state.IsRequest}/>
                    {this.state.EmailError}
                </FormGroup>
                <FormGroup>
                    <FormControl inputRef={(input) => this.RefPassword = input} onChange={event => this.OnPasswordChange(event)} type="password" placeholder={global.StrPassword} autoComplete="off" disabled={this.state.IsRequest}/>
                    {this.state.PasswordError}
                </FormGroup>
                <FormGroup>
                    <FormControl inputRef={(input) => this.RefConfirm = input} onChange={event => this.OnConfirmChange(event)} type="password" placeholder={global.StrPasswordConf} autoComplete="off" disabled={this.state.IsRequest}/>
                    {this.state.ConfirmError}
                </FormGroup>
                <center>
                    <Button wd fill neutral onClick={this.OnSubmit} disabled={this.state.IsRequest}>{global.StrRegister}</Button>
                </center>
            </div>
        );
    }

    render()
    {
        return (
            <Grid>
                {this.state.Alert}
                <Row>
                    <Col md={8} mdOffset={2}>
                        <div className="header-text">
                            <h2>RapidaID {global.StrAccount}</h2>
                            <h4>{global.StrRegisterForFreeToUseMFASSO}</h4>
                            <hr />
                        </div>
                    </Col>
                    <Col md={4} mdOffset={2}>
                        <Media>
                            <Media.Left>
                                <div className="icon">
                                    <i className="pe-7s-user" />
                                </div>
                            </Media.Left>
                            <Media.Body>
                                {global.StrWithRIDUseOneAuthMethod}
                            </Media.Body>
                        </Media>
                        <Media>
                            <Media.Left>
                                <div className="icon">
                                    <i className="pe-7s-graph1" />
                                </div>
                            </Media.Left>
                            <Media.Body>
                                {global.StrMFAAccessWithRoaming}
                            </Media.Body>
                        </Media>
                        <Media>
                            <Media.Left>
                                <div className="icon">
                                    <i className="pe-7s-headphones" />
                                </div>
                            </Media.Left>
                            <Media.Body>
                                {global.StrSSOForNativeAppsAndWeb}
                            </Media.Body>
                        </Media>
                    </Col>
                    <Col md={4}>
                        <form>
                            <Card
                              plain
                              content={this.GetFormContent()}
                              ftTextCenter
                            />
                        </form>
                    </Col>
                </Row>
            </Grid>
        );
    }
    
}

export default RegisterPage;
