//
//  @file EnrollPushStep3.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React from "react";

import Button from "components/CustomButton/CustomButton.jsx";

class EnrollPushStep3 extends React.Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            TextError: null
        };

        this.OnContinue = this.OnContinue.bind(this);

    }

    isValidated()
    {
        return true;
    }

    OnContinue()
    {
        if(!this.isValidated())
            return;
        this.props.OnResult(true);
    }

    render()
    {
        //<Row noGutters={true}>
        return (
            <div className="wizard-step">
                <br/>
                <br/>
                <br/>
                <center>{global.StrNewPushEnrollReady}</center>
                <br/>
                <br/>
                <br/>
                <center>{global.StrClickFinishToCloseEnroll}</center>
                <br/>
                <br/>
                <center>
                    <Button fill onClick={this.OnContinue}>{global.StrFinish}</Button>
                </center>
            </div>
        );
    }

}

export default EnrollPushStep3;
