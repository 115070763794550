//
//  @file RapidaIDMainDefs.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

class RapidaIDMainDefs
{

    static EAuthMethodSystemUNP = 1;
    static EAuthMethodOnlineUNP = 2;
    static EAuthMethodPush = 3;
    static EAuthMethodOTP = 4;
    static EAuthMethodCard = 5;
    static EAuthMethodMAGS = 6;
    static EAuthMethodFIDO = 7;
    static EAuthMethodFPRT = 8;

    static ELogonEventFailed = 1;
    static ELogonEventSuccess = 2;
    static ELogonEventLogon = 3;
    static ELogonEventLogoff = 4;

    static EStateNewUser = 0;
    static EStateSteady = 1;
    static EStateBlocked = 2;

    static ELanguageEnglish = 0;
    static ELanguageRussian = 1;

    static EBioDeviceInvalid = 0;
    static EBioDeviceWBF = 1;
    static EBioDeviceUPEK = 2;
    static EBioDeviceUAU = 3;
    static EBioDeviceLFPR = 4;
    static EBioDeviceBioKey = 5;

    static EBioFingerInvalid = -1;
    static EBioFingerAny = 0;
    static EBioFingerRThumb = 1;
    static EBioFingerRIndex = 2;
    static EBioFingerRMiddle = 3;
    static EBioFingerRRing = 4;
    static EBioFingerRLittle = 5;
    static EBioFingerLThumb = 6;
    static EBioFingerLIndex = 7;
    static EBioFingerLMiddle = 8;
    static EBioFingerLRing = 9;
    static EBioFingerLLittle = 10;

    static EBioResultSuccess = 0;
    static EBioResultFailed = -1;
    static EBioResultProgress = -2;
    static EBioResultBadCapture = -3;
    static EBioResultNotFound = -4;
    static EBioResultAlreadyEnrolled = -5;
    static EBioResultShowHTMLPage = -6;

    static EBioPromptPutFinger = 1;
    static EBioPromptLiftFinger = 2;
    static EBioPromptScanFinger = 3;
    static EBioPromptCleanFinger = 4;

    static EBioQualityCenterHarder = 1;
    static EBioQualityTooHigh = 2;
    static EBioQualityTooLow = 3;
    static EBioQualityTooLeft = 4;
    static EBioQualityTooRight = 5;
    static EBioQualityTooFast = 6;
    static EBioQualityTooSlow = 7;
    static EBioQualityPoorQuality = 8;
    static EBioQualityTooSkewed = 9;
    static EBioQualityTooShort = 10;
    static EBioQualityQualityIsLow = 11;
    static EBioQualityUnknown = 12;

    static ERROR_SUCCESS = 0;
    static ERROR_UNKNOWN_REQUEST = 1;
    static ERROR_FILE_NOT_FOUND = 2;
    static ERROR_HOST_NOT_REGISTERED = 3;
    static ERROR_INVALID_REG_DATA = 4;
    static ERROR_ACCESS_DENIED = 5;
    static ERROR_PUSH_TIMEOUT = 6;
    static ERROR_PUSH_REJECTED = 7;
    static ERROR_NOT_READY = 8;
    static ERROR_BAD_LENGTH = 9;
    static ERROR_INVALID_ARGUMENT = 10;
    static ERROR_INVALID_USERNAME = 11;
    static ERROR_INVALID_PASSWORD = 12;
    static ERROR_INVALID_UUID = 13;
    static ERROR_INVALID_PIN = 14;
    static ERROR_INVALID_TOKEN = 15;
    static ERROR_INVALID_ANSWERS = 16;
    static ERROR_INVALID_CARD = 17;
    static ERROR_INVALID_MAGSTRIPE = 18;
    static ERROR_INVALID_FINGERPRINT = 19;
    static ERROR_INVALID_FIDO = 20;
    static ERROR_INVALID_OTP = 21;
    static ERROR_INVALID_PUSH = 22;
    static ERROR_INVALID_UNP = 23;
    static ERROR_INVALID_FIELD_X = 24;
    static ERROR_USER_NOT_FOUND = 25;
    static ERROR_REQUEST_FAILED = 26;
    static ERROR_MOBILE_NOT_REGISTERED = 27;
    static ERROR_LOCALACCOUNT_EXIST = 28;
    static ERROR_DOMAIN_INACCESSIBLE = 29;
    static ERROR_INVALIDUSERNAMEORPASSWORD = 30;
    static ERROR_PASSWORDCHANGE_REQUIRED = 31;
    static ERROR_REQUEST_TIMEOUT = 32;
    static ERROR_SERVICE_NOT_CONNECTED = 33;
    static ERROR_REQUEST_BUSY = 34;
    static ERROR_NETWORK_ERROR = 35;
    static ERROR_DEVICE_NOT_CONFIGURED = 36;
    static ERROR_METHOD_NOT_ENROLLED = 37;
    static ERROR_CARD_ENROLLED_OTHER = 38;
    static ERROR_PASSWORD_MUST_CHANGE = 39;
    static ERROR_PASSWORD_EXPIRED = 40;
    static ERROR_USERNAME_EXISTS = 41;
    static ERROR_USER_NOT_LOGGED = 42;
    static ERROR_INVALID_QRCODE = 43;
    static ERROR_INVALID_ITEM = 44;
    static ERROR_INVALID_TIMESTAMP = 45;
    static ERROR_INVALID_BASE32 = 46;
    static ERROR_U2FS_MEMORY_ERROR = 47;
    static ERROR_U2FS_JSON_ERROR = 48;
    static ERROR_U2FS_BASE64_ERROR = 49;
    static ERROR_U2FS_CRYPTO_ERROR = 50;
    static ERROR_U2FS_ORIGIN_ERROR = 51;
    static ERROR_U2FS_CHALLENGE_ERROR = 52;
    static ERROR_U2FS_SIGNATURE_ERROR = 53;
    static ERROR_U2FS_FORMAT_ERROR = 54;
    static ERROR_U2FH_MEMORY_ERROR = 55;
    static ERROR_U2FH_TRANSPORT_ERROR = 56;
    static ERROR_U2FH_JSON_ERROR = 57;
    static ERROR_U2FH_BASE64_ERROR = 58;
    static ERROR_U2FH_NO_U2F_DEVICE = 59;
    static ERROR_U2FH_AUTHENTICATOR_ERROR = 60;
    static ERROR_U2FH_TIMEOUT_ERROR = 61;
    static ERROR_U2FH_SIZE_ERROR = 62;

    static AuthThemeDefault = "unp,push,otp,rfid,mags,fido,fprt";
    static AuthMethodsNameList = [ "", "sunp", "ounp", "push", "otp", "rfid", "mags", "fido", "fprt" ];

    static AuthMethodToString(eMethod)
    {
        let pMethod = [ "", global.StrSystemUNP, global.StrOnlineUNP, global.StrPushMobile, global.StrOneTimePassword, global.StrRFIDCard, global.StrMagstripe, global.StrFIDOToken, global.StrFingerprint ];
        return pMethod[eMethod];
    }

    static AuthMethodFromString(sMethod)
    {
        let eMethod = RapidaIDMainDefs.AuthMethodsNameList.indexOf(sMethod);
        return eMethod;
    }

    static AuthThemeFromString(sAuthTheme)
    {
        let pAuthSetResult = [];
        let pAuthSetName = sAuthTheme.toLowerCase().split(";");
        for(let iSet = 0; iSet < pAuthSetName.length; iSet++)
        {
            let pMethodResult = [];
            let pMethodName = pAuthSetName[iSet].split(",");
            for(let iMethod = 0; iMethod < pMethodName.length; iMethod++)
            {
                let sMethod = pMethodName[iMethod] === "unp" ? "ounp" : pMethodName[iMethod];
                let eMethod = RapidaIDMainDefs.AuthMethodsNameList.indexOf(sMethod);
                if(eMethod === -1)
                    return RapidaIDMainDefs.AuthThemeFromString(RapidaIDMainDefs.AuthThemeDefault);
                pMethodResult.push(eMethod);
            }
            pAuthSetResult.push(pMethodResult);
        }
        return pAuthSetResult;
    }

    static LogonEventToString(eEvent)
    {
        let pEvents = [ "", global.StrAttemptFailed, global.StrAttemptSuccess, global.StrLogonSuccess, global.StrLogoffSuccess ];
        return pEvents[eEvent];
    }

    static ErrorToString(nError)
    {
        return "Error: " + nError;
    }

    static BioPromptToString(ePrompt)
    {
        let pString = [ "", global.StrPutFinger, global.StrLiftFinger, global.StrScanFinger, global.StrCleanFinger ];
        return pString[ePrompt];
    }

    static BioQualityToString(eQuality)
    {
        let pString = [ "", global.StrCenterHarder, global.StrTooHigh, global.StrTooLow, global.StrTooLeft, global.StrTooRight, global.StrTooFast, global.StrTooSlow, global.StrPoorQuality, global.StrTooSkewed, global.StrTooShort, global.StrQualityIsLow, global.StrUnknown ];
        return pString[eQuality];
    }

    static BioFingerName()
    {
        let pString = [ "Any", "RThumb", "RIndex", "RMiddle", "RRing", "RLittle", "LThumb", "LIndex", "LMiddle", "LRing", "LLittle" ];
        return pString;
    }

    static BioFingerToString(eFinger)
    {
        return RapidaIDMainDefs.BioFingerName()[eFinger];
    }

    static BioFingerFromString(sFinger)
    {
        return RapidaIDMainDefs.BioFingerName().indexOf(sFinger);
    }

};

export default RapidaIDMainDefs;
