//
//  @file AuthCardDone.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import Button from "components/CustomButton/CustomButton.jsx";

class AuthCardDone extends Component
{
    
    constructor(props)
    {

        super(props);

        this.OnCancelClicked = this.OnCancelClicked.bind(this);

    }

    OnCancelClicked()
    {
        this.props.OnCancel();
    }

    render()
    {
        return (
            <div>
                <center><h4>{global.StrLoginSucceeded}</h4></center>
                <br/>
                <center>{global.StrPleaseWaitLoading}</center>
                <br/>
                <center>
                    <Button bsStyle="info" fill wd onClick={this.OnCancelClicked.bind(this)}>
                        {global.StrCancel}
                    </Button>
                </center>
            </div>
        );
    }
    
}

export default AuthCardDone;
