//
//  @file index.js
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/sass/lbd.scss?v=1.2.0";
import "assets/css/demo.css";
import "assets/css/pe-icon-7-stroke.css";

import './config.js';
import './Strings.js';

import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import UserLayout from "layouts/User.jsx";

import RapidaIDSession from "scripts/RapidaIDSession.jsx";

function OnRenderLayout(props, fAdmin)
{
    //console.log("OnRenderLayout() -> " + RapidaIDSession.GetSessionKey());
    if(!RapidaIDSession.GetSessionKey())
        return (<Redirect to="/auth/login-page"/>);
    return fAdmin ? (<AdminLayout {...props}/>) : (<UserLayout {...props}/>);
}

ReactDOM.render(
    <HashRouter>
        <Switch>
            <Route path="/auth" render={props => <AuthLayout {...props} />}/>
            <Route path="/admin" render={props => OnRenderLayout(props, true)}/>
            <Route path="/user" render={props => OnRenderLayout(props, false)}/>
            <Redirect from="/" to="/auth/login-page" />
            <Redirect from="/user" to="/user/activity" />
            <Redirect from="/admin" to="/admin/activity" />
        </Switch>
    </HashRouter>,
    document.getElementById("root")
);
