//
//  @file AuthCardFPRT.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 30.07.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import { FormGroup } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import FormLoginInput from "components/FormInputs/FormLoginInput.jsx";

import RapidaIDChannel from "scripts/RapidaIDChannel.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";
import RapidaIDService from "scripts/RapidaIDService.jsx";

import ImgTile from "assets/img/TileFPRT.png";

const EStateInit = 0;
const EStateFetch = 1;
const EStateWaitScan = 2;
const EStateWaitPIN = 3;
const EStateCheckPIN = 4;
const EStateError = 5;

class AuthCardFPRT extends Component
{
    
    constructor(props)
    {

        super(props);

        this.state = {
            State: EStateInit,
            StatusText: null,
            QualityText: null
        };

        this.OnSubmitClicked = this.OnSubmitClicked.bind(this);
        this.OnCancelClicked = this.OnCancelClicked.bind(this);
        this.OnStartOverClicked = this.OnStartOverClicked.bind(this);

        this.OnResponseBIODevices = this.OnResponseBIODevices.bind(this);
        this.OnResponseBIOIdentify = this.OnResponseBIOIdentify.bind(this);

        this.ServiceRequest = null;

    }

    componentDidMount()
    {
        //this.OnInitialize();
        setTimeout(function(pThis) { pThis.OnInitialize(); }, 100, this);
    }

    componentWillUnmount()
    {
        if(this.ServiceRequest)
        {
            this.ServiceRequest = RapidaIDService.Abort(this.ServiceRequest);
            this.ServiceRequest = null;
        }
    }

    SetStatus(eState, pStatus, pQuality)
    {
        if(pStatus === undefined)
            pStatus = this.state.StatusText;
        if(pQuality === undefined)
            pQuality = this.state.QualityText;
        this.setState({ State: eState, StatusText: pStatus, QualityText: pQuality });
    }

    OnInitialize()
    {
        this.SetStatus(EStateInit, (<div>Initializing, please wait.</div>));
        this.props.Channel.RequestBIODevices();
    }

    OnFetchData()
    {

        this.setState({ State: EStateFetch, StatusText: global.StrRetrievingDataPleaseWait });

        this.ServiceRequest = RapidaIDService.LoginFetchFPRT(this, this.props.AuthKey, this.props.AuthUser, function (pThis, pResponse) {
            pThis.ServiceRequest = null;
            if(pResponse.Result !== RapidaIDMainDefs.ERROR_SUCCESS)
            {
                let pError = (<div className="text-danger">{global.StrLoadDataFailedErrorSemiSpace}{pResponse.Result}.</div>);
                pThis.SetStatus(EStateError, pError);
            }
            else
                pThis.OnScanFinger(pResponse.Items);
        });

    }

    OnCheckData(sPIN)
    {
        let eState = this.state.State;
        this.setState({ State: EStateCheckPIN });
        this.ServiceRequest = RapidaIDService.LoginCheckFPRT(this, this.props.AuthKey, this.props.AuthUser, sPIN, function (pThis, pResponse) {
            pThis.ServiceRequest = null;
            if(pResponse.Result !== RapidaIDMainDefs.ERROR_SUCCESS)
            {
                let pError = (<center><small className="text-danger">{global.StrServerVerifyFailedErrorSemiSpace}{pResponse.Result}.</small></center>);
                pThis.SetStatus(eState, pError);
                return;
            }
            pThis.props.OnResult(pResponse.Result, pResponse);
        });
    }

    OnScanFinger(pFingerSets)
    {
        this.FingerSets = pFingerSets;
        this.SetStatus(EStateWaitScan, global.StrInitializingScannerPleaseWait);
        this.props.Channel.RequestBIOIdentify(this.BIODevice, this.props.AuthUser, JSON.stringify(pFingerSets));
    }

    OnSubmitClicked(sPIN)
    {

        this.OnCheckData(sPIN);

    }

    OnCancelClicked()
    {

        this.props.OnCancel();

    }

    OnStartOverClicked()
    {

        this.OnInitialize();

    }

    OnResponseBIODevices(eResult, pDevices, pChars)
    {
        console.log("AuthCardFPRT::OnResponseBIODevices(" + eResult + ", " + pDevices + ", " + pChars + ")");
        if(pDevices.length)
        {
            this.BIODevice = pDevices[0];
            this.BIOStorage = RapidaIDChannel.BioIsDeviceChar(pChars[0], RapidaIDChannel.BioCharsStorage);
            this.OnFetchData();
            //this.OnScanFinger();
        }
        else
            this.SetStatus(EStateError, (<div className="text-danger">{global.StrNoFingerprintDevicesFound}</div>));
    }

    OnResponseBIOIdentify(eResult, ePrompt, eQuality, sTemplate, iTemplate, sUserInfo)
    {
        console.log("AuthCardFPRT::OnResponseBIOIdentify(" + eResult + ", " + ePrompt + ", " + eQuality + ", " + sTemplate + ", " + iTemplate + ", " + sUserInfo + ")");
        if(eResult === RapidaIDMainDefs.EBioResultProgress)
        {
            let sPrompt = RapidaIDMainDefs.BioPromptToString(ePrompt);
            let sQuality = this.BadQuality === undefined ? RapidaIDMainDefs.BioQualityToString(eQuality) : this.BadQuality;
            this.SetStatus(EStateWaitScan, sPrompt, sQuality);
            this.BadQuality = undefined;
        }
        if(eResult === RapidaIDMainDefs.EBioResultBadCapture)
        {
            this.BadQuality = RapidaIDMainDefs.BioQualityToString(eQuality);
            this.SetStatus(EStateWaitScan, undefined, global.StrBadQualitySS + this.BadQuality);
            this.OnScanFinger(this.FingerSets);
        }
        else if(eResult === RapidaIDMainDefs.EBioResultNotFound)
            this.SetStatus(EStateError, global.StrFingerNotFound, null);
        else if(eResult === RapidaIDMainDefs.EBioResultFailed)
            this.SetStatus(EStateError, global.StrFingerCaptureError, null);
        else if(eResult === RapidaIDMainDefs.EBioResultSuccess)
            this.SetStatus(EStateWaitPIN, global.StrPleaseEnterPIN, null);
    }

    render()
    {
        return (
            <div>
                <center><img src={ImgTile} alt="TileFPRT" width="96" height="96"/></center>
                <br/>
                <center>{this.state.StatusText}</center><br/>
                <center>{this.state.QualityText}</center><br/>
                <FormGroup hidden={this.state.State !== EStateWaitPIN}>
                    <FormLoginInput OnSubmit={this.OnSubmitClicked} placeholder={global.StrPIN} type="password" defaultValue=""/>
                </FormGroup>
                <br/>
                <center>
                    <Button fill onClick={this.OnCancelClicked}>{global.StrCancel}</Button>&nbsp;
                    {this.state.State === EStateError && <Button fill onClick={this.OnStartOverClicked}>{global.StrStartOver}</Button>}
                </center>
            </div>
        );
    }

}

export default AuthCardFPRT;
