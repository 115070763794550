//
//  @file AuthCard.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
//import Spinner from 'react-bootstrap/Spinner'
//import ReactHtmlParser from 'react-html-parser';
//import RenderHTML from 'react-render-html';
//var HtmlToReact = require('html-to-react');
//var HtmlToReactParser = require('html-to-react').Parser;
import { Grid, Row, Col } from "react-bootstrap";

import Card from "components/Card/Card.jsx";

import AuthCardUser from "components/Auth/AuthCardUser.jsx";
import AuthCardMethod from "components/Auth/AuthCardMethod.jsx";
import AuthCardUNP from "components/Auth/AuthCardUNP.jsx";
import AuthCardPush from "components/Auth/AuthCardPush.jsx";
import AuthCardOTP from "components/Auth/AuthCardOTP.jsx";
import AuthCardRFID from "components/Auth/AuthCardRFID.jsx";
import AuthCardMAGS from "components/Auth/AuthCardMAGS.jsx";
import AuthCardFIDO from "components/Auth/AuthCardFIDO.jsx";
import AuthCardFPRT from "components/Auth/AuthCardFPRT.jsx";
import AuthCardDone from "components/Auth/AuthCardDone.jsx";

import RapidaIDChannel from "scripts/RapidaIDChannel.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";
import RapidaIDUtils from "scripts/RapidaIDUtils.jsx";

//import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";

import SweetAlert from "react-bootstrap-sweetalert";

const EStateUserPrompt = -1;
const EStateSelectMethod = -2;
const EStateAuthDone = -3;

class AuthCard extends Component
{
    
    //MyProp = 100;

    constructor(props)
    {

        super(props);

        this.state = {
            Alert: null,
            show: false,
            cardHidden: true,
            cardContent: "",
            buttonHidden: false,
        };

        this.RefCard = React.createRef();
        this.RefTile = React.createRef();
        this.ShowTiles = null;

        this.OnHideAlert = this.OnHideAlert.bind(this);
        this.OnUserEntered = this.OnUserEntered.bind(this);
        this.OnUserPrompt = this.OnUserPrompt.bind(this);
        this.OnTileClicked = this.OnTileClicked.bind(this);
        this.OnTileResult = this.OnTileResult.bind(this);

        this.OnRFIDCardChanged = this.OnRFIDCardChanged.bind(this);
        this.OnMAGSCardDetected = this.OnMAGSCardDetected.bind(this);
        this.OnResponseFIDOEnroll = this.OnResponseFIDOEnroll.bind(this);
        this.OnResponseFIDOAuthenticate = this.OnResponseFIDOAuthenticate.bind(this);
        this.OnResponseFIDOHostAuth = this.OnResponseFIDOHostAuth.bind(this);
        this.OnResponseBIODevices = this.OnResponseBIODevices.bind(this);
        this.OnResponseBIOIdentify = this.OnResponseBIOIdentify.bind(this);

        this.SavedLang = global.LangName;
        this.ActiveState = EStateUserPrompt;
        this.AuthUser = "";
        this.AuthKey = null;

        this.Channel = null;

    }

    componentDidMount()
    {        

        this.Channel = new RapidaIDChannel(null, this.OnRFIDCardChanged, this.OnMAGSCardDetected, this.OnResponseFIDOEnroll, this.OnResponseFIDOAuthenticate, this.OnResponseFIDOHostAuth, this.OnResponseBIODevices, null, null, null, this.OnResponseBIOIdentify);
        this.Channel.Create();

        this.SetCardContentUserPrompt();

        setTimeout(
            function() {
                this.setState({ cardHidden: false });
            }.bind(this),
            100
        );

        RapidaIDUtils.AddComponent(this);

    }

    componentWillUnmount()
    {

        this.Channel.Destroy();
        this.Channel = null;

        RapidaIDUtils.DelComponent(this);

    }

    OnForceUpdate()
    {
        //this.RefCard.current.forceUpdate();
        this.RefTile.current.forceUpdate();
    }

    OnShowAlert(sTitle, sMessage)
    {
        this.setState({
            Alert: (
                <SweetAlert
                  style={{ display: "block" }}
                  title={sTitle}
                  onConfirm={() => this.OnHideAlert(this)}
                  onCancel={() => this.OnHideAlert(this)}
                  confirmBtnBsStyle="info"
                >
                    {sMessage}
                </SweetAlert>
            )
        });
    }

    OnHideAlert()
    {
        console.log("AuthCard::OnHideAlert()");
        this.setState({ Alert: null });
    }

    OnUserEntered(sUserInfo, sLang, pAuthKey, pAuthTheme)
    {
        console.log("AuthCard::OnUserEntered() -> " + sUserInfo);
        //if(sLang !== global.LangName)
        this.SavedLang = global.LangName;
        global.SetLang(sLang);
        RapidaIDUtils.ForceUpdateComponents();
        this.AuthUser = sUserInfo;
        this.AuthKey = pAuthKey;
        this.AuthStateNext({ AuthTheme: pAuthTheme });
        //this.setState({ buttonHidden: true });
        //this.SetCardContentTiles(true, true, true, true, true);
    }

    OnUserPrompt()
    {
        console.log("AuthCard::OnUserPrompt()");
        global.SetLang(this.SavedLang);
        RapidaIDUtils.ForceUpdateComponents();
        this.SetCardContentUserPrompt();
    }

    OnTileClicked(eTile)
    {
        console.log("AuthCard::OnTileClicked() -> " + eTile + ", " + AuthCardMethod.ETileUNP);
        this.SetCardContentTile(eTile);
    }

    OnTileResult(eResult, pResponse)
    {
        console.log("AuthCard::OnTileResult() -> " + eResult);
        if(!eResult)
            this.AuthStateNext(pResponse);
        else
            this.OnShowAlert(global.StrAuthFailed, global.StrErrorSemiSpace + eResult);
    }

    OnRFIDCardChanged(sCardID, fPresent)
    {
        console.log("AuthCard::OnRFIDCardChanged(" + sCardID + ", " + fPresent + ")");
        if(this.RefTile.current && this.RefTile.current.OnRFIDCardChanged)
            this.RefTile.current.OnRFIDCardChanged(sCardID, fPresent);
    }

    OnMAGSCardDetected(sCardID)
    {
        console.log("AuthCard::OnMAGSCardDetected(" + this.ActiveState + ", " + sCardID + ")");
        if(this.ActiveState === EStateSelectMethod && this.ShowTiles[AuthCardMethod.ETileMAGS])
        {
            this.OnTileClicked(AuthCardMethod.ETileMAGS);
            setTimeout(function(pThis) {
                pThis.RefTile.current.OnMAGSCardDetected(sCardID);
            }, 500, this);
        }
        else if(this.ActiveState === AuthCardMethod.ETileMAGS && this.RefTile.current && this.RefTile.current.OnMAGSCardDetected)
            this.RefTile.current.OnMAGSCardDetected(sCardID);
    }

    OnResponseFIDOEnroll(eResult, sPublicKey, sKeyHandle)
    {
        console.log("AuthCard::OnResponseFIDOEnroll(" + eResult + ")");
    }

    OnResponseFIDOAuthenticate(eResult)
    {
        console.log("AuthCard::OnResponseFIDOAuthenticate(" + eResult + ")");
        if(this.RefTile.current && this.RefTile.current.OnResponseFIDOAuthenticate)
            this.RefTile.current.OnResponseFIDOAuthenticate(eResult);
    }

    OnResponseFIDOHostAuth(eResult, iAuthItem, sHostResult)
    {
        console.log("AuthCard::OnResponseFIDOHostAuth(" + eResult + ", " + iAuthItem + ")");
        if(this.RefTile.current && this.RefTile.current.OnResponseFIDOHostAuth)
            this.RefTile.current.OnResponseFIDOHostAuth(eResult, iAuthItem, sHostResult);
    }

    OnResponseBIODevices(eResult, pDevices, pChars)
    {
        console.log("AuthCard::OnResponseBIODevices(" + eResult + ", " + pDevices + ", " + pChars + ")");
        console.log("tile: " + this.RefTile.current);
        if(this.RefTile.current && this.RefTile.current.OnResponseBIODevices)
            this.RefTile.current.OnResponseBIODevices(eResult, pDevices, pChars);
    }

    OnResponseBIOIdentify(eResult, ePrompt, eQuality, sTemplate, iTemplate, sUserInfo)
    {
        console.log("AuthCard::OnResponseBIOIdentify(" + eResult + ", " + ePrompt + ", " + eQuality + ", " + sTemplate + ", " + iTemplate + ", " + sUserInfo + ")");
        if(this.RefTile.current && this.RefTile.current.OnResponseBIOIdentify)
            this.RefTile.current.OnResponseBIOIdentify(eResult, ePrompt, eQuality, sTemplate, iTemplate, sUserInfo);
    }

    AuthStateNext(pResponse)
    {
        if(pResponse.SessionKey !== undefined)
        {
            this.SetCardContentTile(EStateAuthDone);
            this.props.OnResult(RapidaIDMainDefs.ERROR_SUCCESS, pResponse);
            return;
        }
        let pShow = [ false, false, false, false, false, false, false, false, false, false ];
        let pTiles = pResponse.AuthTheme.split(",");
        console.log(pTiles);
        for(let iTile = 0; iTile < pTiles.length; iTile++)
            pShow[RapidaIDMainDefs.AuthMethodFromString(pTiles[iTile])] = true;
        this.ShowTiles = pShow;
        if(pTiles.length === 1)
            this.SetCardContentTile(pTiles[0]);
        else
            this.SetCardContentTiles(pShow[1] || pShow[2], pShow[3], pShow[4], pShow[5], pShow[6], pShow[7], pShow[8]);
    }

    SetCardContentUserPrompt()
    {
        this.ActiveState = EStateUserPrompt;
        this.setState({ cardContent: (<AuthCardUser ref={this.RefTile} UserInfo="" OnUserEntered={this.OnUserEntered} OnCancel={this.props.OnCancel}></AuthCardUser>) });
    }

    SetCardContentTiles(fShowUNP, fShowPush, fShowOTP, fShowRFID, fShowMAGS, fShowFIDO, fShowFPRT)
    {
        this.ActiveState = EStateSelectMethod;
        this.setState({ cardContent: (<AuthCardMethod ref={this.RefTile} OnTile={this.OnTileClicked} OnCancel={this.OnUserPrompt} ShowUNP={fShowUNP} ShowPush={fShowPush} ShowOTP={fShowOTP} ShowRFID={fShowRFID} ShowMAGS={fShowMAGS} ShowFIDO={fShowFIDO} ShowFPRT={fShowFPRT}/>) });
    }

    SetCardContentTile(eTile)
    {
        var Result;
        if(eTile === AuthCardMethod.ETileUNP)
            Result = (<AuthCardUNP ref={this.RefTile} Channel={this.Channel} AuthUser={this.AuthUser} AuthKey={this.AuthKey} OnResult={this.OnTileResult} OnCancel={this.OnUserPrompt}/>);
        else if(eTile === AuthCardMethod.ETilePush)
            Result = (<AuthCardPush ref={this.RefTile} Channel={this.Channel} AuthUser={this.AuthUser} AuthKey={this.AuthKey} OnResult={this.OnTileResult} OnCancel={this.OnUserPrompt}/>);
        else if(eTile === AuthCardMethod.ETileOTP)
            Result = (<AuthCardOTP ref={this.RefTile} Channel={this.Channel} AuthUser={this.AuthUser} AuthKey={this.AuthKey} OnResult={this.OnTileResult} OnCancel={this.OnUserPrompt}/>);
        else if(eTile === AuthCardMethod.ETileRFID)
            Result = (<AuthCardRFID ref={this.RefTile} Channel={this.Channel} AuthUser={this.AuthUser} AuthKey={this.AuthKey} OnResult={this.OnTileResult} OnCancel={this.OnUserPrompt} Cards={this.Channel.GetRFIDCards()}/>);
        else if(eTile === AuthCardMethod.ETileMAGS)
            Result = (<AuthCardMAGS ref={this.RefTile} Channel={this.Channel} AuthUser={this.AuthUser} AuthKey={this.AuthKey} OnResult={this.OnTileResult} OnCancel={this.OnUserPrompt}/>);
        else if(eTile === AuthCardMethod.ETileFIDO)
            Result = (<AuthCardFIDO ref={this.RefTile} Channel={this.Channel} AuthUser={this.AuthUser} AuthKey={this.AuthKey} OnResult={this.OnTileResult} OnCancel={this.OnUserPrompt}/>);
        else if(eTile === AuthCardMethod.ETileFPRT)
            Result = (<AuthCardFPRT ref={this.RefTile} Channel={this.Channel} AuthUser={this.AuthUser} AuthKey={this.AuthKey} OnResult={this.OnTileResult} OnCancel={this.OnUserPrompt}/>);
        else if(eTile === EStateAuthDone)
            Result = (<AuthCardDone ref={this.RefTile} Channel={this.Channel} AuthUser={this.AuthUser} AuthKey={this.AuthKey} OnCancel={this.OnUserPrompt}/>);
        this.ActiveState = eTile;
        this.setState({ cardContent: Result });
    }

    render() {
        return (
            <Grid>
                {this.state.Alert}
                <Row>
                    <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                        <form>
                            <Card
                              ref={this.RefCard}
                              hidden={this.state.cardHidden}
                              textCenter
                              content={this.state.cardContent}
                              ftTextCenter
                            />
                        </form>
                    </Col>
                </Row>
            </Grid>
        );
    }

}

export default AuthCard;
