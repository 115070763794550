//
//  @file EnrollFPRTStep2a.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.07.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React from "react";

import { Row, Col } from "react-bootstrap";

import SweetAlert from "react-bootstrap-sweetalert";

import Button from "components/CustomButton/CustomButton.jsx";

import EnrollFPRTHands from "views/User/EnrollFPRTHands.jsx";

import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

class EnrollFPRTStep2a extends React.Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            Prompt: null
        }

        this.OnCancel = this.OnCancel.bind(this);
        this.OnContinue = this.OnContinue.bind(this);
        this.OnRemoveFinger = this.OnRemoveFinger.bind(this);
        this.OnFingerClicked = this.OnFingerClicked.bind(this);
        this.OnResponseBIODelete = this.OnResponseBIODelete.bind(this);

    }

    componentDidMount()
    {
        this.SavedOnResponseBIODelete = this.props.Channel.OnResponseBIODeleteHandler;
        this.props.Channel.OnResponseBIODeleteHandler = this.OnResponseBIODelete;
    }

    componentWillUnmount()
    {
        this.props.Channel.OnResponseBIODeleteHandler = this.SavedOnResponseBIODelete;
    }

    OnCancel()
    {
        this.props.OnCancel();
    }

    OnContinue()
    {
        this.props.OnContinue();
    }

    OnRemoveFinger(eFingerIndex, eFingerID)
    {
        console.log("remove: " + eFingerIndex + ", " + eFingerID);
        this.RemoveFingerID = eFingerID;
        this.props.Channel.RequestBIODelete(this.props.Channel.BIODevice, "", eFingerIndex);
    }

    OnFingerClicked(e)
    {
        //console.log("OnFingerClicked()");
        if(!e.IsEnrolled())
        {
            this.props.OnFingerClicked(e.props.FingerID);
            return;
        }
        let pThis = this;
        function OnResultYes() { pThis.OnRemoveFinger(e.FingerIndex, e.props.FingerID); }
        function OnResultNo() { pThis.setState({ Prompt: null }); }
        this.setState({
            Prompt: (
                <SweetAlert
                    style={{ display: "block" }}
                    title={global.StrConfirmationRequired}
                    onConfirm={() => OnResultYes()}
                    onCancel={() => OnResultNo()}
                    confirmBtnBsStyle="info"
                    confirmBtnText={global.StrYes}
                    cancelBtnText={global.StrNo}
                    showCancel
                >
                    {global.StrDoYouWantToDeleteFinger}
                </SweetAlert>
            )
        });
    }

    OnResponseBIODelete(eResult, iFinger)
    {
        this.setState({ Prompt: null });
        if(eResult !== RapidaIDMainDefs.EBioResultSuccess)
            return;
        let TokenInfo = this.props.TokenInfo;
        for(let iFinger = 0; iFinger < TokenInfo.Data.length; iFinger++)
        {
            let eFinger = RapidaIDMainDefs.BioFingerFromString(TokenInfo.Data[iFinger].Finger);
            if(eFinger === this.RemoveFingerID)
            {
                TokenInfo.Data.splice(iFinger, 1);
                break;
            }
        }
        this.forceUpdate();
    }

    render()
    {

        const { TokenInfo } = this.props;
        console.log(TokenInfo);
        let pFingers = [ -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1 ];
        for(let iFinger = 0; iFinger < TokenInfo.Data.length; iFinger++)
        {
            let eFinger = RapidaIDMainDefs.BioFingerFromString(TokenInfo.Data[iFinger].Finger);
            if(eFinger !== -1)
                pFingers[eFinger] = iFinger;
        }
        console.log(pFingers);
        return (
            <div className="wizard-step">
                {this.state.Prompt}
                <br/>
                <br/>
                <br/>
                <center>{global.StrPleaseSelectFingerEnrollDelete}</center>
                <br/>
                <Row>
                    <Col sm={12}>
                        <EnrollFPRTHands EnrolledFingers={pFingers} OnFingerClicked={ e => { this.OnFingerClicked(e); } }/>
                    </Col>
                </Row>
                <br/>
                <br/>
                <center>
                    <Button fill onClick={this.OnCancel}>{global.StrCancel}</Button>&nbsp;
                    <Button fill onClick={this.OnContinue}>{global.StrContinue}</Button>
                </center>
            </div>
        );
    }

}

export default EnrollFPRTStep2a;
