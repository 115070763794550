//
//  @file EnrollFPRT.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.07.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import EnrollBase from "views/User/EnrollBase.jsx";

import EnrollFPRTStep1 from "views/User/EnrollFPRTStep1.jsx";
import EnrollFPRTStep2 from "views/User/EnrollFPRTStep2.jsx";
import EnrollFPRTStep3 from "views/User/EnrollFPRTStep3.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDSession from "scripts/RapidaIDSession.jsx";
import RapidaIDChannel from "scripts/RapidaIDChannel.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

import PromptWebKey from "components/Prompt/PromptWebKey.jsx";
import PromptProgress from "components/Prompt/PromptProgress.jsx";

import ImgTile from "assets/img/TileFPRT.png";

class EnrollFPRT extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            Prompt: null
        };

        this.TokenInfo = {
            UUID: "",
            Name: "",
            Data: "",
            PIN: "",
            CPIN: ""
        };

        this.TokenData = [];

        this.OnPrepareEdit = this.OnPrepareEdit.bind(this);
        this.OnTokenEnrolled = this.OnTokenEnrolled.bind(this);
        this.OnTokenRemoved = this.OnTokenRemoved.bind(this);

        this.OnChannelReady = this.OnChannelReady.bind(this);
        this.OnResponseBIODevices = this.OnResponseBIODevices.bind(this);
        this.OnResponseBIOEnrolled = this.OnResponseBIOEnrolled.bind(this);
        this.OnResponseBIODelete = this.OnResponseBIODelete.bind(this);
        this.OnResponseBIOEnroll = this.OnResponseBIOEnroll.bind(this);
        this.OnResponseBIOIdentify = this.OnResponseBIOIdentify.bind(this);

        this.ServiceRequest = null;

    }

    componentDidMount()
    {
        this.ReloadTokenData();
    }

    componentWillUnmount()
    {
        this.Channel.Destroy();
        this.Channel = null;
        if(this.ServiceRequest)
        {
            this.ServiceRequest.abort();
            this.ServiceRequest = null;
        }
    }

    ConnectChannel()
    {
        if(this.Channel)
        {
            this.Channel.Destroy();
            this.Channel = null;
        }
        this.Channel = new RapidaIDChannel(this.OnChannelReady, null, null, null, null, null, this.OnResponseBIODevices, this.OnResponseBIOEnrolled, this.OnResponseBIODelete, this.OnResponseBIOEnroll, this.OnResponseBIOIdentify);
        this.Channel.Create();
    }

    ReloadTokenData()
    {

        function OnRequestInit(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrRetrievingDataPleaseWait);
            pPrompt.ServiceRequest = RapidaIDService.UserFPRTGet(pThis, function(pThis, eResult, pData) {
                pThis.TokenData.splice(0, pThis.TokenData.length);
                if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                {
                    for(let iItem = 0; iItem < pData.length; iItem++)
                        pThis.TokenData.push({ UUID: pData[iItem].UUID, Name: pData[iItem].Name, Data: pData[iItem].Data });
                    pThis.setState({ Prompt: null });
                    //pThis.forceUpdate();
                }
                else
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
                pThis.ConnectChannel();
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={OnRequestInit} OnCancel={() => this.setState({Prompt: null})}/>});

    }

    OnPrepareEdit(iItem, OnResult)
    {

        if(this.Channel.BIOEnrollUI)
        {
            this.Channel.RequestBIOEnroll(this.Channel.BIODevice, RapidaIDSession.GetUserInfo(), "");
            this.setState({Prompt: <PromptProgress Parent={this} OnCancel={() => this.setState({Prompt: null})}/>});
            return;
        }

        this.TokenInfo.UUID = iItem === -1 ? "NEW" : this.TokenData[iItem].UUID;
        this.TokenInfo.Name = iItem === -1 ? "" : this.TokenData[iItem].Name;
        this.TokenInfo.Data = iItem === -1 ? [] : this.TokenData[iItem].Data;
        this.TokenInfo.PIN = "";
        this.TokenInfo.CPIN = "";

        var pSteps = [
            { name: global.StrName, component: <EnrollFPRTStep1 OnResult={OnResult} TokenInfo={this.TokenInfo}/> },
            { name: global.StrFingerprint, component: <EnrollFPRTStep2 OnResult={OnResult} TokenInfo={this.TokenInfo} Channel={this.Channel}/> },
            { name: global.StrPIN, component: <EnrollFPRTStep3 OnResult={OnResult} TokenInfo={this.TokenInfo}/> }
        ];

        return pSteps;
    }

    OnTokenEnrolled(iItem)
    {

        console.log("EnrollFPRT::OnTokenEnrolled()");

        this.ReloadTokenData();
        /*if(iItem === -1)
            this.TokenData.push({ UUID: this.TokenInfo.UUID, Name: this.TokenInfo.Name });
        else
        {
            this.TokenData[iItem].UUID = this.TokenInfo.UUID;
            this.TokenData[iItem].Name = this.TokenInfo.Name;
        }*/

    }

    OnTokenRemoved(iItem)
    {
        console.log("EnrollFPRT::OnTokenRemoved(" + iItem + ")");
        this.ServiceRequest = RapidaIDService.UserFPRTDel(this, this.TokenData[iItem].UUID, function(pThis, eResult) {
            pThis.ReloadTokenData();
            //if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                //pThis.TokenData.splice(iItem, 1);
        });
    }

    OnChannelReady(fReady)
    {
        console.log("EnrollFPRT::OnChannelReady(" + fReady + ")");
        console.log(this.Channel);
        if(fReady)
            this.Channel.RequestBIODevices();
        /*else
        {
            this.Channel.BIOEnrollUI = true;
            this.OnResponseBIOEnroll(RapidaIDMainDefs.EBioResultShowHTMLPage, "", "", "", "", "650\n450\nws://127.0.0.1:54915\nwebkey\n1596598741\n1DSdXo6yqY6Sbpt/WQynSQ==");
        }*/
    }

    OnResponseBIODevices(pDevices, pChars)
    {
        console.log("EnrollFPRT::OnResponseBIODevices(" + pDevices + ", " + pChars + ")");
        if(pDevices !== null && pDevices.length)
        {
            this.Channel.BIODevice = pDevices[0];
            this.Channel.BIOStorage = RapidaIDChannel.BioIsDeviceChar(pChars[0], RapidaIDChannel.BioCharsStorage);
            this.Channel.BIOEnrollUI = RapidaIDChannel.BioIsDeviceChar(pChars[0], RapidaIDChannel.BioCharsEnrollUI);
            if(this.Channel.BIOStorage)
            {
                if(!this.Channel.BIOEnrollUI)
                    this.Channel.RequestBIOEnrolled(this.Channel.BIODevice);
                else
                {
                    let sDeviceName = RapidaIDChannel.BioGetDeviceName(this.Channel.BIODevice);
                    this.TokenData.push({ UUID: "-1", Name: sDeviceName, Data: [], Default: true });
                    this.forceUpdate();
                }
            }
        }
        //else
        //    this.setState({ Prompt: <PromptHTML ContentData="sometestdata<hr/><input type='button' value='test'/>"/> });
    }

    OnResponseBIOEnrolled(eResult, pEnrolled)
    {
        console.log("EnrollFPRT::OnResponseBIOEnrolled(" + eResult + ", " + pEnrolled + ")");
        let pData = [];
        for(let iFinger = 0; iFinger < pEnrolled.length; iFinger++)
            pData.push({ Finger: pEnrolled[iFinger] });
        this.TokenData.push({ UUID: "-1", Name: global.StrDeviceStorage, Data: pData });
        this.forceUpdate();
    }

    OnResponseBIODelete(eResult, iFinger)
    {
        console.log("EnrollFPRT::OnResponseBIODelete(" + eResult + ", " + iFinger + ")");
    }

    OnResponseBIOEnroll(eResult, ePrompt, eQuality, nProgress, sTemplate, sExtraData)
    {
        if(this.Channel.BIOEnrollUI)
        {
            if(eResult === RapidaIDMainDefs.EBioResultShowHTMLPage)
                this.setState({ Prompt: <PromptWebKey AllowCancel ConfData={sExtraData} OnCancel={() => this.setState({ Prompt: null })}/> });
            else
                this.setState({ Prompt: null });
        }
        console.log("EnrollFPRT::OnResponseBIOEnroll(" + eResult + ", " + ePrompt + ", " + eQuality + ", " + nProgress + ", " + sTemplate);// + ", " + sExtraData + ")");
    }

    OnResponseBIOIdentify(eResult, ePrompt, eQuality, sTemplate, iTemplate, sUserInfo)
    {
        console.log("EnrollFPRT::OnResponseBIOIdentify(" + eResult + ", " + ePrompt + ", " + eQuality + ", " + sTemplate + ", " + iTemplate + ", " + sUserInfo + ")");
    }

    render()
    {
        return (
            <div>
                {this.state.Prompt}
                <EnrollBase 
                    ImgTile={ImgTile} 
                    CustomWidth="540px"
                    EnrollTitle={global.StrEnrollNewSet} 
                    BriefDesc={global.StrHereEnrollUpdateDeleteToken}
                    TokenData={this.TokenData}
                    TokenInfo={this.TokenInfo}
                    OnPrepareEdit={this.OnPrepareEdit}
                    OnTokenEnrolled={this.OnTokenEnrolled}
                    OnTokenRemoved={this.OnTokenRemoved}
                />
            </div>
        );
    }

}

export default EnrollFPRT;
