//
//  @file Admin.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import NotificationSystem from "react-notification-system";

import AdminSidebar from "components/Sidebar/AdminSidebar.jsx";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import image from "assets/img/full-screen-image-3.jpg";

import AdminRoutes from "AdminRoutes.js";

import { style } from "variables/Variables.jsx";

var ps;

class AdminLayout extends Component
{

    constructor(props)
    {
        super(props);
        this.state = {
            _notificationSystem: null,
            image: image,
            color: "black",
            hasImage: true,
            navbar: false,
            mini: false,
            fixedClasses: "dropdown"
        };
    }

    componentDidMount()
    {
        this.setState({ _notificationSystem: this.refs.notificationSystem });
        if (navigator.platform.indexOf("Win") > -1)
            ps = new PerfectScrollbar(this.refs.mainPanel);
    }

    componentWillUnmount()
    {
        if (navigator.platform.indexOf("Win") > -1)
            ps.destroy();
    }

    componentDidUpdate(e)
    {
        if (navigator.platform.indexOf("Win") > -1)
        {
            setTimeout(() => {
                ps.update();
            }, 350);
        }
        if (e.history.action === "PUSH")
        {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            this.refs.mainPanel.scrollTop = 0;
        }
        if (
            window.innerWidth < 993 &&
            e.history.action === "PUSH" &&
            document.documentElement.className.indexOf("nav-open") !== -1
        )
            document.documentElement.classList.toggle("nav-open");
    }

    componentWillMount()
    {
        if (document.documentElement.className.indexOf("nav-open") !== -1)
            document.documentElement.classList.toggle("nav-open");
    }

    handleNotificationClick = position => {
        var color = Math.floor(Math.random() * 4 + 1);
        var level;
        switch (color)
        {
            case 1:
                level = "success";
                break;
            case 2:
                level = "warning";
                break;
            case 3:
                level = "error";
                break;
            case 4:
                level = "info";
                break;
            default:
                break;
        }
        this.state._notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-gift" />,
            message: (
                <div>
                    Welcome to <b>Dashboard</b> - a beautiful freebie for
                    every web developer.
                </div>
            ),
            level: level,
            position: position,
            autoDismiss: 15
        });
    };

    handleImageClick = image => {
        this.setState({ image: image });
    };

    handleColorClick = color => {
        this.setState({ color: color });
    };

    handleHasImage = hasImage => {
        this.setState({ hasImage: hasImage });
    };

    handleNavbarClick = navbar => {
        this.setState({ navbar: navbar });
    };

    handleMiniClick = () => {
        this.setState({ mini: !this.state.mini });
        document.body.classList.toggle("sidebar-mini");
    };

    handleFixedClick = () => {
        if (this.state.fixedClasses === "dropdown")
            this.setState({ fixedClasses: "dropdown show-dropdown open" });
        else
            this.setState({ fixedClasses: "dropdown" });
    };

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse)
                return this.getRoutes(prop.views);
            if (prop.layout === "/admin")
            {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        key={key}
                        render={routeProps => (
                            <prop.component
                                {...routeProps}
                                handleClick={this.handleNotificationClick}
                            />
                        )}
                    />
                );
            }
            else
                return null;
        });
    };

    render()
    {
        return (
            <div className="wrapper">
                <NotificationSystem ref="notificationSystem" style={style} />
                <AdminSidebar
                    {...this.props}
                    image={this.state.image}
                    color={this.state.color}
                    hasImage={this.state.hasImage}
                    mini={this.state.mini}
                />
                <div
                    className={
                        "main-panel" +
                        (this.props.location.pathname === "/maps/full-screen-maps"
                          ? " main-panel-maps"
                          : "")
                    }
                    ref="mainPanel"
                >
                    <AdminNavbar
                        {...this.props}
                        handleMiniClick={this.handleMiniClick}
                        navbar={this.state.navbar}
                    />
                    <Switch>{this.getRoutes(AdminRoutes)}</Switch>
                    <Footer fluid />
                    {/*<FixedPlugin
                        handleImageClick={this.handleImageClick}
                        handleColorClick={this.handleColorClick}
                        handleHasImage={this.handleHasImage}
                        handleNavbarClick={this.handleNavbarClick}
                        handleMiniClick={this.handleMiniClick}
                        bgColor={this.state["color"]}
                        bgImage={this.state["image"]}
                        mini={this.state["mini"]}
                        handleFixedClick={this.handleFixedClick}
                        fixedClasses={this.state.fixedClasses}
                    />*/}
                </div>
            </div>
        );
    }

}

export default AdminLayout;
