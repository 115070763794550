//
//  @file FilesStrings.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";

import Select from "react-select";

import Button from "components/CustomButton/CustomButton.jsx";

import FormInputText from "components/FormInputs/FormInputText.jsx";

class FilesStrings extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            LanguageID: global.LangListEN.indexOf(global.LangName),
            TextValue: ""
        };

        this.LanguageOptions = [
            { label: "Language", isDisabled: true },
            { value: 0, label: "Raw array" },
        ];

        for(let iLang = 0; iLang < global.LangListEN.length; iLang++)
            this.LanguageOptions.push({ value: iLang + 1, label: global.LangListEN[iLang] });

        this.OnLangSelected = this.OnLangSelected.bind(this);
        this.OnImportClicked = this.OnImportClicked.bind(this);
        this.OnResetClicked = this.OnResetClicked.bind(this);

        this.RefTextEdit = React.createRef();

    }

    componentDidMount()
    {

        this.OnLangSelected(this.LanguageOptions[1]);

    }

    OnLangSelected(pValue)
    {

        this.setState({ LanguageID: pValue.value });

        let sValue = "";
        for(let iStr = 0; !pValue.value && iStr < global.StrList.length; iStr++)
            sValue += "\"" + global.StrList[iStr] + "\", " + ((iStr > 0 && ((iStr + 1) % (global.LangListEN.length + 1)) === 0) ? "\n" : "");
        for(let iStr = 0; pValue.value && iStr < global.StrList.length; iStr += global.LangListEN.length + 1)
            sValue += global.StrList[iStr + pValue.value] + "\n";

        this.RefTextEdit.current.SetText(sValue);

    }

    OnImportClicked()
    {

        let iLang = this.state.LanguageID;
        if(!iLang)
            return;

        let sValue = this.RefTextEdit.current.GetText();
        let pStr = sValue.split("\n");
        for(let iStr = 0, iStrNew = 0; iStr < global.StrList.length; iStr += global.LangListEN.length + 1, iStrNew++)
            global.StrList[iStr + iLang] = pStr[iStrNew];

    }

    OnResetClicked()
    {
    }

    render()
    {
        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col xs={2}>
                            <Select className="react-select" classNamePrefix="react-select" name="Language" placeholder="Language"
                                value={this.LanguageOptions[this.state.LanguageID + 1]}
                                onChange={value => this.OnLangSelected(value)}
                                options={this.LanguageOptions}
                            />
                        </Col>
                        <Col xs={10}>
                            <Button fill onClick={() => this.OnImportClicked()}>{global.StrImport}</Button>&nbsp;&nbsp;
                            <Button fill onClick={() => this.OnResetClicked()}>{global.StrReset}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <br/>
                            <FormInputText ref={this.RefTextEdit} autoFocus wrap="off" placeholder="" componentClass="textarea" rows="20" style={{fontFamily: 'monospace'}} onChange={event => this.TextValue = event.target.value}/>
                        </Col>
                    </Row>
                    <hr/>
                </Grid>
            </div>
        );
    }

}

export default FilesStrings;
