//
//  @file EnrollBase.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";

import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";

import SweetAlert from "react-bootstrap-sweetalert";

import PromptWizard from "components/Prompt/PromptWizard.jsx";

class EnrollBase extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            Prompt: null,
            Wizard: null
        };

        this.OnShowPrompt = this.OnShowPrompt.bind(this);
        this.OnHidePrompt = this.OnHidePrompt.bind(this);
        this.OnShowWizard = this.OnShowWizard.bind(this);
        this.OnHideWizard = this.OnHideWizard.bind(this);
        this.OnNewTokenClicked = this.OnNewTokenClicked.bind(this);
        this.OnUpdateClicked = this.OnUpdateClicked.bind(this);
        this.OnDeleteClicked = this.OnDeleteClicked.bind(this);

    }

    OnShowPrompt(sTitle, sMessage, OnResult)
    {
        function OnResultYes() { OnResult(true); }
        function OnResultNo() { OnResult(false); }
        this.setState({
            Prompt: (
                <SweetAlert
                    style={{ display: "block" }}
                    title={sTitle}
                    onConfirm={() => OnResultYes()}
                    onCancel={() => OnResultNo()}
                    confirmBtnBsStyle="info"
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                >
                    {sMessage}
                </SweetAlert>
            )
        });
    }

    OnHidePrompt()
    {
        console.log("EnrollBase::OnHidePrompt()");
        this.setState({ Prompt: null });
    }

    OnShowWizard(iItem, OnResult)
    {
        var pSteps = this.props.OnPrepareEdit(iItem, OnResult);
        if(pSteps)
            this.setState({ Wizard: (<PromptWizard OnResult={OnResult} steps={pSteps} title={this.props.EnrollTitle} CustomWidth={this.props.CustomWidth}/>) });
    }

    OnHideWizard()
    {
        console.log("EnrollBase::OnHideWizard()");
        this.setState({ Wizard: null });
    }

    OnNewTokenClicked()
    {
        this.OnUpdateClicked(-1);
    }

    OnUpdateClicked(iItem)
    {
        var pThis = this;
        this.OnShowWizard(iItem, function (fSuccess) {
            pThis.OnHideWizard();
            if(fSuccess)
                pThis.props.OnTokenEnrolled(iItem);
        });
    }

    OnDeleteClicked(iItem)
    {
        var pThis = this;
        this.OnShowPrompt("", global.StrDoYouWantToDeleteToken, 
            function(fYes) {
                if(fYes)
                    pThis.props.OnTokenRemoved(iItem);
                pThis.OnHidePrompt();
            },
        );
        this.forceUpdate();
    }

    GetEnrollments()
    {
        var pResult = [];
        var pItems = this.props.TokenData;
        for(var iItem = 0; iItem < pItems.length; iItem++)
            pResult.push(this.GetEnrollment(iItem, pItems[iItem]));
        return pResult;
    }

    GetEnrollment(iItem, pItem)
    {
        var pCard = (
            <Row key={"item#" + iItem}>
                <Col md={12}>
                    <Card content={this.GetEnrollmentCard(iItem, pItem)}/>
                </Col>
            </Row>
        );
        return pCard;
    }

    GetEnrollmentCard(iItem, pItem)
    {
        console.log(pItem);
        return (
            <Grid fluid={true} style={{ paddingLeft: 0 }}>
                <Row>
                    <Col sm={12}>
                        <img src={this.props.ImgTile} alt="Tile" width="64" height="64"/>
                        &nbsp;&nbsp;&nbsp;{pItem.Name}
                        <Button fill onClick={e => this.OnDeleteClicked(iItem)} style={{float: "right", marginLeft: 5}} disabled={pItem.Default === true}>{global.StrDelete}</Button>
                        <Button fill onClick={e => this.OnUpdateClicked(iItem)} style={{float: "right"}} disabled={pItem.AllowEdit === false}>{global.StrUpdate}</Button>
                    </Col>
                </Row>
            </Grid>
        );
    }

    render()
    {
        return (
            <div className="main-content">
                <Grid fluid>
                    {this.state.Wizard}
                    {this.state.Prompt}
                    <Row>
                        <Col md={12}>
                            {this.props.BriefDesc}
                            <br/>
                            <br/>
                            <Button fill onClick={this.OnNewTokenClicked}>{this.props.AddTitle === undefined ? global.StrAddNew : this.props.AddTitle}</Button>
                        </Col>
                    </Row>
                    <hr/>
                    {this.GetEnrollments()}
                </Grid>
            </div>
        );
    }

}

export default EnrollBase;
