//
//  @file HostsPolicy.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";

import ReactTable from "react-table";

import SweetAlert from "react-bootstrap-sweetalert";

import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CheckboxOwnerData.jsx";

import PromptProgress from "components/Prompt/PromptProgress.jsx";

import HostsPolicyEdit from "views/Admin/HostsPolicyEdit.jsx";

import RapidaIDChannel from "scripts/RapidaIDChannel.jsx";
import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

class HostsPolicy extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            TableData: [],
            Prompt: null
        }

        this.OnToggleRow = this.OnToggleRow.bind(this);
        this.IsCheckedRow = this.IsCheckedRow.bind(this);
        this.OnPolicyAppend = this.OnPolicyAppend.bind(this);
        this.OnPolicyDelete = this.OnPolicyDelete.bind(this);
        this.OnPolicyChange = this.OnPolicyChange.bind(this);

    }

    componentWillMount()
    {

        this.OnDataFetch(0, 100);

    }

    OnDataPrepare(pData)
    {
        for(let iItem = 0; iItem < pData.length; iItem++)
        {
            pData[iItem].RowIndex = iItem;
            pData[iItem].IsChecked = false;
            pData[iItem].Actions = (
                <div className="actions-right">
                    <Button onClick={() => {this.OnPolicyChange(iItem);}} bsStyle="info" simple icon>
                        <i className="fa fa-edit" />
                    </Button>{" "}
                    <Button onClick={() => {this.OnPolicyDelete(iItem);}} bsStyle="danger" simple icon>
                        <i className="fa fa-times" />
                    </Button>{" "}
                </div>
            );
        }
        return pData;
    }

    OnDataFetch(iRecordPos, nRecordCount)
    {

        function OnRequestInit(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrRetrievingDataPleaseWait);
            pPrompt.ServiceRequest = RapidaIDService.AdminHostsPolicyGet(pThis, iRecordPos, nRecordCount, function(pThis, eResult, pData) {
                if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                    pThis.setState({TableData: pThis.OnDataPrepare(pData), Prompt: null});
                else
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={OnRequestInit} OnCancel={() => this.setState({Prompt: null})}/>});

    }

    OnDataDelete(pRows, pItems)
    {

        function OnRequestInit(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrRetrievingDataPleaseWait);
            pPrompt.ServiceRequest = RapidaIDService.AdminHostsPolicyDel(pThis, 0, 100, pItems, function(pThis, eResult, pData) {
                if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                    pThis.setState({TableData: pThis.OnDataPrepare(pData), Prompt: null});
                else
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={OnRequestInit} OnCancel={() => this.setState({Prompt: null})}/>});

    }

    OnDataUpdate(pItem)
    {

        function OnRequestInit(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrPleaseWaitUpdatingInfo);
            pPrompt.ServiceRequest = RapidaIDService.AdminHostsPolicySet(pThis, 0, 100, pItem.UUID, pItem.Name, pItem.Desc, pItem.Value, function(pThis, eResult, pData) {
                if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                {
                    RapidaIDChannel.PerformSync("");
                    pThis.setState({TableData: pThis.OnDataPrepare(pData), Prompt: null});
                }
                else
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={OnRequestInit} OnCancel={() => this.setState({Prompt: null})}/>});

    }

    OnToggleRow(pRow)
    {
        //checked={this.state.SelectedRows[pRowInfo.original.title.props.children] === true}
        //console.log("OnToggleRow() -> " + pRow);
        //console.log(pRow.original.RowIndex);
        if(!pRow)
        {
            let pData = this.state.TableData, iItem = 0;
            let fChecked = !this.IsCheckedRow(null);
            for(iItem = 0; iItem < pData.length; iItem++)
                pData[iItem].IsChecked = fChecked;
        }
        else
            pRow.original.IsChecked = !pRow.original.IsChecked;
        this.forceUpdate();
    }

    IsCheckedRow(pRow)
    {
        if(!pRow)
        {
            let pData = this.state.TableData, iItem = 0;
            for(iItem = 0; iItem < pData.length && pData[iItem].IsChecked; iItem++);
            return iItem === pData.length && pData.length;
        }
        return pRow.original.IsChecked;
    }

    OnPolicyAppend()
    {
        console.log("HostsPolicy::OnPolicyAppend()");
        this.OnPolicyChange(-1);
    }

    OnPolicyChange(iRowIndex)
    {
        console.log("HostsPolicy::OnPolicyChange()");
        function OnResult(pThis, pPrompt, fResult) {
            //console.log("OnResult() -> " + pPrompt.props.ItemInfo.Name);
            pThis.setState({Prompt: null});
            if(fResult)
                pThis.OnDataUpdate(pPrompt.props.ItemInfo);
        }
        let pItemInfo = iRowIndex === -1 ? {UUID: "", Name: "", Desc: "", Value: ""} : this.state.TableData[iRowIndex];
        this.setState({Prompt: <HostsPolicyEdit Parent={this} OnResult={OnResult} ItemInfo={pItemInfo}/>});
    }

    OnPolicyDelete(iRowIndex)
    {
        console.log("HostsPolicy::OnPolicyDelete()");
        let pData = this.state.TableData, pCheckedIndex = [], pCheckedUUID = [];
        for(let iItem = 0; iItem < pData.length; iItem++)
        {
            if(pData[iItem].IsChecked)
            {
                pCheckedIndex.push(iItem);
                pCheckedUUID.push(pData[iItem].UUID);
            }
        }
        if(!pCheckedIndex.length)
        {
            pCheckedIndex.push(iRowIndex);
            pCheckedUUID.push(pData[iRowIndex].UUID);
        }
        let sMessage = global.StrDoYouWantToRemove + " " + pCheckedIndex.length + " " + global.StrPolicies + "?";
        function OnDeleteRows(pThis, fDelete)
        {
            pThis.setState({Prompt: null});
            if(fDelete)
                pThis.OnDataDelete(pCheckedIndex, pCheckedUUID);
        }
        this.setState({
            Prompt: (
                <SweetAlert
                    style={{ display: "block" }}
                    title={global.StrUserActionRequired}
                    onConfirm={() => OnDeleteRows(this, true)}
                    onCancel={() => OnDeleteRows(this, false)}
                    confirmBtnBsStyle="info"
                    confirmBtnText={global.StrYes}
                    cancelBtnText={global.StrNo}
                    showCancel
                >
                    {sMessage}
                </SweetAlert>
            )
        });
    }

    render()
    {
        return (
            <div className="main-content">
                <Grid fluid>
                {this.state.Prompt}
                    <Row>
                        <Col md={12}>
                            {global.StrHostsPoliciesAddChangeHere}
                            <br/>
                            <br/>
                            <Button fill onClick={this.OnPolicyAppend}>{global.StrAddNew}</Button>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                      <Col md={12}>
                        <Card
                            category={global.StrHostsPolicies}
                            content={
                                <ReactTable
                                    data={this.state.TableData}
                                    filterable
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: (pRowInfo) => {
                                                return (<Checkbox checked={this.IsCheckedRow(pRowInfo)} onChange={() => this.OnToggleRow(pRowInfo)}/>);
                                            },
                                            Header: (sTitle) => {
                                                return (<Checkbox checked={this.IsCheckedRow(null)} onChange={() => this.OnToggleRow(null)}/>);
                                            },
                                            filterable: false,
                                            sortable: false,
                                            width: 40
                                        },
                                        {
                                            Header: global.StrName,
                                            accessor: "Name",
                                            width: 250
                                        },
                                        {
                                            Header: global.StrDesc,
                                            accessor: "Desc"
                                        },
                                        {
                                            Header: global.StrActions,
                                            accessor: "Actions",
                                            width: 125,
                                            sortable: false,
                                            filterable: false
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                />
                            }
                        />
                      </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

}

export default HostsPolicy;
