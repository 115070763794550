//
//  @file AuthNavbar.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component/*, useState*/ } from "react";
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

//import Select from "react-select";

/*import {
  Dropdown,
  DropdownMenu,
  MenuItem,
  NavDropdown
} from "react-bootstrap";*/

import RapidaIDSession from "scripts/RapidaIDSession.jsx";
import RapidaIDUtils from "scripts/RapidaIDUtils.jsx";

const ImgFlagUS = require("../../assets/img/flags/US.png");
const ImgFlagRU = require("../../assets/img/flags/RU.png");
const ImgFlagDE = require("../../assets/img/flags/DE.png");
const ImgFlagFR = require("../../assets/img/flags/FR.png");
const ImgFlagIT = require("../../assets/img/flags/IT.png");
const ImgFlagES = require("../../assets/img/flags/ES.png");
const ImgFlagPT = require("../../assets/img/flags/PT.png");
const ImgFlagJP = require("../../assets/img/flags/JP.png");
const ImgFlagKR = require("../../assets/img/flags/KR.png");
const ImgFlagCN = require("../../assets/img/flags/CN.png");
const ImgFlagVN = require("../../assets/img/flags/VN.png");
const ImgFlagIN = require("../../assets/img/flags/IN.png");

class PagesHeader extends Component
{

    constructor(props)
    {

        super(props);

        this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);

        console.log("PagesHeader::ctor() -> Lang: " + RapidaIDSession.GetLang());

        this.ImgFlags = [ ImgFlagUS, ImgFlagRU, ImgFlagDE, ImgFlagFR, ImgFlagIT, ImgFlagES, ImgFlagPT, ImgFlagJP, ImgFlagKR, ImgFlagCN, ImgFlagVN, ImgFlagIN ];

        if(global.LangName !== RapidaIDSession.GetLang())
            global.SetLang(RapidaIDSession.GetLang());

        let sLang = RapidaIDSession.GetLang();
        let iLang = global.LangListEN.indexOf(sLang);

        this.state = {
            width: window.innerWidth,
            Language: global.LangListNV[iLang],
            LanguageFlag: this.ImgFlags[iLang]
        };

        this.OnLanguageClicked = this.OnLanguageClicked.bind(this);
        this.OnLanguageSelected = this.OnLanguageSelected.bind(this);

    }

    // function that sets the class to active of the active page
    activeRoute(routeName)
    {
        return window.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    }

    // function that shows/hides sidebar on responsive
    mobileSidebarToggle(e)
    {
        document.documentElement.classList.toggle("nav-open");
    }

    updateWidth()
    {
        this.setState({ width: window.innerWidth });
    }

    componentDidMount()
    {
        window.addEventListener("resize", this.updateWidth.bind(this));
        RapidaIDUtils.AddComponent(this);
    }

    componentWillUnmount()
    {
        RapidaIDUtils.DelComponent(this);
    }

    GetLanguageMenu()
    {
        let pLanguages = [];
        for(let iLang = 0; iLang < global.LangListNV.length; iLang++)
            pLanguages.push((<li key={iLang}><a href="#pablo" onClick={e => this.OnLanguageSelected(e, iLang)}><img src={this.ImgFlags[iLang]} alt={global.LangListNV[iLang]}/> {global.LangListNV[iLang]}</a></li>));
        return (
            <ul className="dropdown-menu dropdown-menu-left">
                {pLanguages}
            </ul>
        );
    }

    OnLanguageClicked(e)
    {
        e.preventDefault();
        //e.target.nextSibling.classList.toggle("show");
        let pDropdown = e.target.tagName.toLowerCase() === "p" ? e.target.parentNode.nextSibling : e.target.nextSibling;
        //console.log(e.target.tagName + ", " + pDropdown);
        pDropdown.classList.toggle("open");
    }

    OnLanguageSelected(e, iLang)
    {
        let sLang = global.LangListEN[iLang];
        console.log("OnLanguageSelected: " + e.target.innerHTML + ", " + iLang + ", " + sLang);
        e.preventDefault();
        e.target.parentNode.parentNode.parentNode.classList.toggle("open");
        global.SetLang(sLang);
        this.setState({ Language: global.LangListNV[iLang], LanguageFlag: this.ImgFlags[iLang] });
        RapidaIDSession.SetLang(sLang);
        //let iPath = window.location.href.indexOf("#/");
        //let sPath = window.location.href.substring(iPath + 2);
        //console.log("path: " + sPath);
        //window.location = "#?" + sPath;
        RapidaIDUtils.ForceUpdateComponents();
    }

    render()
    {
        return (
            <Navbar
              collapseOnSelect
              inverse
              className="navbar-primary navbar-transparent navbar-absolute"
            >
                <Navbar.Header>
                    <Navbar.Brand>
                        <NavLink to={"/dashboard"} className="nav-link">
                            {this.state.width > 429
                              ? "RapidaID"
                              : "RapidaID"}
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={this.mobileSidebarToggle} />
                </Navbar.Header>
                <Navbar.Collapse>
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <a href="#pablo" onClick={e => this.OnLanguageClicked(e)}>
                                {/*<i className="pe-7s-global" />*/}
                                <img src={this.state.LanguageFlag} alt={this.state.Language}/>
                                <p>{this.state.Language}</p>
                            </a>
                            <div className="dropdown">
                                {this.GetLanguageMenu()}
                            </div>
                        </li>
                        <li className={this.activeRoute("login-page")}>
                            <NavLink to={"/auth/login-page"} className="nav-link">
                                <i className="fa fa-drivers-license-o" />
                                <p>{global.StrLogin}</p>
                            </NavLink>
                        </li>
                        <li className={this.activeRoute("register-page")}>
                            <NavLink to={"/auth/register-page"} className="nav-link">
                                <i className="fa fa-user-circle-o" />
                                <p>{global.StrRegister}</p>
                            </NavLink>
                        </li>
                    </ul>
                </Navbar.Collapse>
            </Navbar>
        );
    }

}

export default PagesHeader;
