//
//  @file UserProfile.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import { FormGroup, ControlLabel, FormControl, Grid, Row, Col } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import UserCard from "components/Card/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import RapidaIDSession from "scripts/RapidaIDSession.jsx";

import avatar from "assets/img/faces/face-0.jpg";

class UserProfile extends Component
{
    render()
    {
        return (
          <div className="main-content">
            <Grid fluid>
              <Row>
                <Col md={8}>
                  <Card
                    title={global.StrEditProfile}
                    content={
                      <form>
                        <FormInputs
                          ncols={["col-md-5", "col-md-3", "col-md-4"]}
                          proprieties={[
                            {
                              label: global.StrCompany,
                              type: "text",
                              bsClass: "form-control",
                              placeholder: global.StrCompany,
                              defaultValue: ""
                            },
                            {
                              label: global.StrUsername,
                              type: "text",
                              bsClass: "form-control",
                              placeholder: global.StrUsername,
                              defaultValue: RapidaIDSession.GetUserInfo(),
                              disabled: true
                            },
                            {
                              label: global.StrEmailAddress,
                              type: "email",
                              bsClass: "form-control",
                              placeholder: global.StrEmailAddress,
                              defaultValue: RapidaIDSession.GetUserInfo(),
                              disabled: true
                            }
                          ]}
                        />
                        <FormInputs
                          ncols={["col-md-6", "col-md-6"]}
                          proprieties={[
                            {
                              label: global.StrFirstName,
                              type: "text",
                              bsClass: "form-control",
                              placeholder: global.StrFirstName,
                              defaultValue: ""
                            },
                            {
                              label: global.StrLastName,
                              type: "text",
                              bsClass: "form-control",
                              placeholder: global.StrLastName,
                              defaultValue: ""
                            }
                          ]}
                        />
                        <FormInputs
                          ncols={["col-md-12"]}
                          proprieties={[
                            {
                              label: global.StrHomeAdress,
                              type: "text",
                              bsClass: "form-control",
                              placeholder: global.StrHomeAdress,
                              defaultValue: ""
                            }
                          ]}
                        />
                        <FormInputs
                          ncols={["col-md-4", "col-md-4", "col-md-4"]}
                          proprieties={[
                            {
                              label: global.StrCity,
                              type: "text",
                              bsClass: "form-control",
                              placeholder: global.StrCity,
                              defaultValue: ""
                            },
                            {
                              label: global.StrCountry,
                              type: "text",
                              bsClass: "form-control",
                              placeholder: global.StrCountry,
                              defaultValue: ""
                            },
                            {
                              label: global.StrPostalCode,
                              type: "number",
                              bsClass: "form-control",
                              placeholder: global.StrZIPCode
                            }
                          ]}
                        />

                        <div className="row">
                          <div className="col-md-12">
                            <FormGroup controlId="formControlsTextarea">
                              <ControlLabel>{global.StrAboutMe}</ControlLabel>
                              <FormControl
                                rows="5"
                                componentClass="textarea"
                                bsClass="form-control"
                                placeholder={global.StrYourDescription}
                                defaultValue=""
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <Button bsStyle="info" pullRight fill type="submit">
                          {global.StrUpdate}
                        </Button>
                        <div className="clearfix" />
                      </form>
                    }
                  />
                </Col>
                <Col md={4}>
                  <UserCard
                    bgImage="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400"
                    avatar={avatar}
                    name={RapidaIDSession.GetUserInfo()}
                    userName={RapidaIDSession.GetUserInfo()}
                    description={
                      <span>
                        <br />
                        <br />
                      </span>
                    }
                    socials={
                      <div>
                        <Button simple>
                          <i className="fa fa-facebook-square" />
                        </Button>
                        <Button simple>
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button simple>
                          <i className="fa fa-google-plus-square" />
                        </Button>
                      </div>
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        );
    }
}

export default UserProfile;
