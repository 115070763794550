//
//  @file CheckboxOwnerData.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

class CustomCheckbox extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
        };

    }

    render()
    {
        const { inline, ...rest } = this.props;
        //const classes = inline !== undefined ? "checkbox checkbox-inline" : "checkbox";
        return (
            <input
              type="checkbox"
              {...rest}
            />
        );
    }

}

export default CustomCheckbox;
