//
//  @file AuthCardUser.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import { FormGroup, ControlLabel } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import FormLoginInput from "components/FormInputs/FormLoginInput.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

class AuthCardUser extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            IsRequest: false
        };

        this.OnSubmitClicked = this.OnSubmitClicked.bind(this);
        this.OnCancelClicked = this.OnCancelClicked.bind(this);

        this.UserInfo = "";

    }
    
    GetUserInfo()
    {
        return this.UserInfo;
    }

    OnSubmitClicked(sUserInfo)
    {
        //this.setState({ UserInfo: this.RefUserInfo.value });
        //console.log("OnContinueClicked() -> " + this.state.UserInfo);
        this.UserInfo = sUserInfo;

        this.setState({ IsRequest: true });

        RapidaIDService.LoginInit(this, this.UserInfo, function (pThis, pResponse) {
            pThis.setState({ IsRequest: false });
            if(pResponse.Result === RapidaIDMainDefs.ERROR_SUCCESS)
                pThis.props.OnUserEntered(pThis.UserInfo, pResponse.UserLang, pResponse.AuthKey, pResponse.AuthTheme);
        });

    }

    OnCancelClicked()
    {
        this.props.OnCancel();
    }

    render()
    {
        const { UserInfo } = this.props;
        return (
            <div>
                <center><h4>{global.StrLogin}</h4></center>
                <br/>
                <FormGroup>
                    <ControlLabel>{global.StrEmailAddress}</ControlLabel>
                    <FormLoginInput OnSubmit={this.OnSubmitClicked} placeholder={global.StrEnterEmail} type="email" defaultValue={UserInfo} disabled={this.state.IsRequest}/>
                </FormGroup>
                <br/>
                <center>
                    <Button bsStyle="info" fill wd onClick={this.OnCancelClicked.bind(this)}>
                        {global.StrCancel}
                    </Button>
                </center>
            </div>
        );
    }

}

export default AuthCardUser;
