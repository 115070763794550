//
//  @file LoginPage.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import AuthCard from "components/Auth/AuthCard.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";
import RapidaIDSession from "scripts/RapidaIDSession.jsx";
import RapidaIDUtils from "scripts/RapidaIDUtils.jsx";

class LoginPage extends Component
{

    constructor(props)
    {

        super(props);
        
        console.log("LoginPage::LoginPage()");
        
        this.OnAuthCancel = this.OnAuthCancel.bind(this);
        this.OnAuthResult = this.OnAuthResult.bind(this);

        var sSessionKey = RapidaIDSession.GetSessionKey();
        if(sSessionKey && sSessionKey !== "")
        {
            RapidaIDSession.ResetSession();
            RapidaIDService.LoginClose(this, sSessionKey, function (pThis, pResponse) { });
        }

    }

    componentDidMount()
    {
        /*setTimeout(
            function() {
                this.setState({ cardHidden: false });
            }.bind(this),
            100
        );*/
        RapidaIDUtils.AddComponent(this);
    }

    componentWillUnmount()
    {
        RapidaIDUtils.DelComponent(this);
    }

    OnAuthCancel()
    {
        window.location = "#/auth/register-page";
    }

    OnAuthResult(eResult, pResponse)
    {
        if(eResult !== RapidaIDMainDefs.ERROR_SUCCESS)
            return;
        //global.Config.UserData.UserInfo = pResponse.UserInfo;
        //global.Config.UserData.SessionKey = pResponse.SessionKey;
        RapidaIDSession.SetSessionInfo(pResponse);
        if(pResponse.UserPage !== "")
            window.location = pResponse.UserPage;
        else
            window.location = "#/user/activity";
    }

    render()
    {
        return (<AuthCard OnResult={this.OnAuthResult} OnCancel={this.OnAuthCancel}/>);
    }

}

export default LoginPage;
