//
//  @file User.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
//import { Redirect } from 'react-router-dom'

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import NotificationSystem from "react-notification-system";

import UserSidebar from "components/Sidebar/UserSidebar.jsx";
import UserNavbar from "components/Navbars/UserNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import RapidaIDUtils from "scripts/RapidaIDUtils.jsx";

import UserRoutes from "UserRoutes.js";

import { style } from "variables/Variables.jsx";

import image from "assets/img/full-screen-image-2.jpg";

var ps;

class UserLayout extends Component
{

    constructor(props)
    {
        super(props);
        this.state = {
            _notificationSystem: null,
            image: image,
            color: "black",
            hasImage: true,
            navbar: false,
            mini: false,
            fixedClasses: "dropdown show-dropdown open"
        };
    }

    componentWillMount()
    {
        if (document.documentElement.className.indexOf("nav-open") !== -1)
            document.documentElement.classList.toggle("nav-open");
    }

    componentDidMount()
    {
        this.setState({ _notificationSystem: this.refs.notificationSystem });
        if (navigator.platform.indexOf("Win") > -1)
            ps = new PerfectScrollbar(this.refs.mainPanel);
        RapidaIDUtils.AddComponent(this);
    }

    componentWillUnmount()
    {
        if (navigator.platform.indexOf("Win") > -1)
            ps.destroy();
        RapidaIDUtils.DelComponent(this);
    }

    componentDidUpdate(e)
    {
        if (navigator.platform.indexOf("Win") > -1)
        {
            setTimeout(() => {
              ps.update();
            }, 350);
        }
        if (e.history.action === "PUSH")
        {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            this.refs.mainPanel.scrollTop = 0;
        }
        if (
            window.innerWidth < 993 &&
            e.history.action === "PUSH" &&
            document.documentElement.className.indexOf("nav-open") !== -1
        )
            document.documentElement.classList.toggle("nav-open");
    }

    // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
    handleNotificationClick = position =>
    {

        var color = Math.floor(Math.random() * 4 + 1);
        var level;
        switch (color)
        {
          case 1:
            level = "success";
            break;
          case 2:
            level = "warning";
            break;
          case 3:
            level = "error";
            break;
          case 4:
            level = "info";
            break;
          default:
            break;
        }

        this.state._notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-gift" />,
            message: (
              <div>
                Welcome to <b>Dashboard</b> - a beautiful freebie for
                every web developer.
              </div>
            ),
            level: level,
            position: position,
            autoDismiss: 15
        });
    };

    handleImageClick = image =>
    {
        this.setState({ image: image });
    };

    handleColorClick = color =>
    {
        this.setState({ color: color });
    };

    handleHasImage = hasImage =>
    {
        this.setState({ hasImage: hasImage });
    };

    handleNavbarClick = navbar =>
    {
        this.setState({ navbar: navbar });
    };

    handleMiniClick = () =>
    {
        this.setState({ mini: !this.state.mini });
        document.body.classList.toggle("sidebar-mini");
    };

    handleFixedClick = () =>
    {
        if (this.state.fixedClasses === "dropdown")
            this.setState({ fixedClasses: "dropdown show-dropdown open" });
        else
            this.setState({ fixedClasses: "dropdown" });
    };
    
    getRoutes = routes =>
    {
      return routes.map((prop, key) =>
      {
        if (prop.collapse)
            return this.getRoutes(prop.views);
        if (prop.layout === "/user")
        {
            return (
              <Route
                path={prop.layout + prop.path}
                key={key}
                render={routeProps => (
                  <prop.component
                    {...routeProps}
                    handleClick={this.handleNotificationClick}
                  />
                )}
              />
            );
        }
        else
          return null;
      });
    };

    render()
    {
        //return (<Redirect to='/auth/login-page'/>);
        return (
            <div className="wrapper">
                <NotificationSystem ref="notificationSystem" style={style} />
                <UserSidebar
                    {...this.props}
                    image={this.state.image}
                    color={this.state.color}
                    hasImage={this.state.hasImage}
                    mini={this.state.mini}
                />
                <div
                    className={
                        "main-panel" +
                        (this.props.location.pathname === "/maps/full-screen-maps"
                          ? " main-panel-maps"
                          : "")
                    }
                    ref="mainPanel"
                >
                    <UserNavbar
                        {...this.props}
                        handleMiniClick={this.handleMiniClick}
                        navbar={this.state.navbar}
                    />
                    <Switch>{this.getRoutes(UserRoutes)}</Switch>
                    <Footer fluid />
                </div>
            </div>
        );
    }

}

export default UserLayout;
