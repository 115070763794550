//
//  @file RapidaIDUtils.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

class RapidaIDUtils
{

    static GActiveComponentList = [];

    static IsValidaEmail(sText)
    {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(sText);
    }

    static IsValidPassword(sText)
    {
        var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        return re.test(sText);
    }

    static OTPCreateSecret()
    {
        var pBase32Set = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
        var sRandSecret = "";//OF3WK4TRO5SXE4LX";
        for(var iSym = 0; iSym < 16; iSym++)
            sRandSecret += pBase32Set.charAt(Math.floor(Math.random() * pBase32Set.length));
        return sRandSecret;
    }

    static InitStringArray(pArray, sValue, nCount)
    {
        for(let iItem = 0; iItem < nCount; iItem++)
            pArray.push(sValue);
        return pArray;
    }

    static CopyObject(pSrc, pDst)
    {
        for(let pProp in pSrc)
        {
                pDst[pProp] = pSrc[pProp];
                console.log(pProp);
        }
    }

    static JSONFormat(sValue)
    {
        try
        {
            if(typeof(sValue) == "string")
                return JSON.stringify(JSON.parse(sValue), null, 2);
            return JSON.stringify(sValue, null, 2);
        }
        catch(e)
        {
        }
        return sValue;
    }

    static JSONPacked(sValue)
    {
        return JSON.stringify(JSON.parse(sValue));
    }

    static JSONPack(pObject)
    {
        return JSON.stringify(pObject);
    }

    static JSONParse(sText)
    {
        try
        {
            return JSON.parse(sText);
        }
        catch(e)
        {
        }
        return null;
    }

    static JSONReformat(sText)
    {
        try
        {
            return JSON.stringify(JSON.parse(sText), null, 2);
        }
        catch(e)
        {
        }
    }

    static JSONRepacked(sText)
    {
        try
        {
            return JSON.stringify(JSON.parse(sText));
        }
        catch(e)
        {
        }
    }

    static JSONEscape(sText)
    {
        if (typeof(sText) !== "string")
            return sText;
        return sText
          .replace(/["]/g, '"')//.replace(/[\"]/g, '\\"')
          .replace(/[\\]/g, '\\\\')
          .replace(/[/]/g, '/')//.replace(/[\/]/g, '\\/')
          .replace(/[\b]/g, '\\b')
          .replace(/[\f]/g, '\\f')
          .replace(/[\n]/g, '\\n')
          .replace(/[\r]/g, '\\r')
          .replace(/[\t]/g, '\\t'); 
    }

    static AddComponent(pInstance)
    {
        if(RapidaIDUtils.GActiveComponentList.indexOf(pInstance) === -1)
            RapidaIDUtils.GActiveComponentList.push(pInstance);
    }

    static DelComponent(pInstance)
    {
        let iIndex = RapidaIDUtils.GActiveComponentList.indexOf(pInstance);
        if(iIndex !== -1)
            RapidaIDUtils.GActiveComponentList = RapidaIDUtils.GActiveComponentList.splice(iIndex, 1);
    }

    static ForceUpdateComponents()
    {
        for(let iIndex = 0; iIndex < RapidaIDUtils.GActiveComponentList.length; iIndex++)
        {
            RapidaIDUtils.GActiveComponentList[iIndex].forceUpdate();
            if(RapidaIDUtils.GActiveComponentList[iIndex].OnForceUpdate !== undefined)
                RapidaIDUtils.GActiveComponentList[iIndex].OnForceUpdate();
        }
    }

};

export default RapidaIDUtils;
