//
//  @file EnrollFPRTStep2.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.07.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React from "react";

import EnrollFPRTStep2a from "views/User/EnrollFPRTStep2a.jsx";
import EnrollFPRTStep2b from "views/User/EnrollFPRTStep2b.jsx";

const EStateSelect = 1;
const EStateEnroll = 2;

class EnrollFPRTStep2 extends React.Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            State: EStateSelect
        }

        this.OnCancelSelect = this.OnCancelSelect.bind(this);
        this.OnCancelEnroll = this.OnCancelEnroll.bind(this);
        this.OnContinueSelect = this.OnContinueSelect.bind(this);
        this.OnContinueEnroll = this.OnContinueEnroll.bind(this);
        this.OnFingerClicked = this.OnFingerClicked.bind(this);

    }

    isValidated()
    {
        return true;
    }

    OnCancelSelect()
    {
        this.props.OnResult(false);
    }

    OnCancelEnroll()
    {
        this.setState({ State: EStateSelect });
    }

    OnContinueSelect()
    {
        this.props.jumpToStep(2);
    }

    OnContinueEnroll()
    {
        this.setState({ State: EStateSelect });
    }

    OnFingerClicked(eFingerID)
    {
        this.setState({ State: EStateEnroll, FingerID: eFingerID });
    }

    render()
    {
        const { Channel, TokenInfo } = this.props;
        return (
            <div className="wizard-step">
                {this.state.State === EStateSelect && <EnrollFPRTStep2a TokenInfo={TokenInfo} Channel={Channel} OnCancel={this.OnCancelSelect} OnContinue={this.OnContinueSelect} OnFingerClicked={ e => { this.OnFingerClicked(e); } }/>}
                {this.state.State === EStateEnroll && <EnrollFPRTStep2b TokenInfo={TokenInfo} Channel={Channel} OnCancel={this.OnCancelEnroll} OnContinue={this.OnContinueEnroll} FingerID={this.state.FingerID}/>}
            </div>
        );
    }

}

export default EnrollFPRTStep2;
