//
//  @file EnrollMAGSStep2.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 06.02.21.
//  Copyright 2021 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React from "react";

import Button from "components/CustomButton/CustomButton.jsx";

import RapidaIDChannel from "scripts/RapidaIDChannel.jsx";

const EStateWaitCard = 0;
const EStateVerifyCard = 1;
const EStateError = 2;

class EnrollMAGSStep2 extends React.Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            TextStatus: global.StrConnectingDeviceWait,
            State: EStateWaitCard
        };

        this.OnCancel = this.OnCancel.bind(this);
        this.OnStartOver = this.OnStartOver.bind(this);
        this.OnChannelReady = this.OnChannelReady.bind(this);
        this.OnMAGSCardDetected = this.OnMAGSCardDetected.bind(this);

        this.CardID = "";

    }

    componentDidMount()
    {
        this.Channel = new RapidaIDChannel(this.OnChannelReady, null, this.OnMAGSCardDetected, null, null);
        this.Channel.Create();
    }

    componentWillUnmount()
    {
        this.Channel.Destroy();
        this.Channel = null;
    }

    isValidated()
    {
        var sError = null;
        if(this.props.TokenInfo.Name === "")
            sError = global.StrEmptyTokenNameNotAllowed;
        if(sError)
            this.ShowStatus(true, {sError});
        return !sError;
    }

    OnCancel()
    {
        this.props.OnResult(false);
    }

    OnStartOver()
    {
        this.ShowStatus(false, global.StrApplyYourCard, EStateWaitCard);
    }

    OnChannelReady(fReady)
    {
        console.log("EnrollMAGSStep2::OnChannelReady(" + fReady + ")");
        if(fReady)
            this.ShowStatus(false, global.StrApplyYourCard, EStateWaitCard);
        else
            this.ShowStatus(true, global.StrUnableConnectHardware);
    }

    OnMAGSCardDetected(sCardID)
    {
        console.log("EnrollMAGSStep2::OnMAGSCardDetected(" + this.state.State + ", " + sCardID + ")");
        if(this.state.State === EStateWaitCard)
        {
            this.CardID = sCardID;
            this.ShowStatus(false, global.StrApplyYourCardAgain, EStateVerifyCard);
        }
        else if(this.state.State === EStateVerifyCard)
        {
            if(sCardID !== this.CardID)
            {
                this.CardID = "";
                this.ShowStatus(false, global.StrApplyYourCard, EStateWaitCard);
            }
            else
            {
                this.props.TokenInfo.Data = sCardID;
                this.props.jumpToStep(2);
            }
        }
    }

    ShowStatus(fError, sText, eState)
    {
        if(fError)
            this.setState({ TextStatus: (<div className="text-danger">{sText}.</div>), State: EStateError });
        else
            this.setState({ TextStatus: (<div>{sText}.</div>), State: eState});
    }

    render()
    {
        return (
            <div className="wizard-step">
                <br/>
                <br/>
                <br/>
                <center>{global.StrPleaseCheckStatusMessages}</center>
                <br/>
                <br/>
                <center>{this.state.TextStatus}</center>
                <br/>
                <br/>
                <br/>
                <center>
                    {(this.state.State === EStateError || this.state.State === EStateWaitCard) && <Button fill onClick={this.OnCancel}>{global.StrCancel}</Button>}&nbsp;
                    {this.state.State === EStateError && <Button fill onClick={this.OnStartOver}>{global.StrStartOver}</Button>}
                </center>
            </div>
        );
    }

}

export default EnrollMAGSStep2;
