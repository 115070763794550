//
//  @file RapidaIDChannel.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import QWebChannel from "scripts/QWebChannel.js";

import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

class RapidaIDChannel
{

    static BioCharsStorage = "Storage";
    static BioCharsEnrollUI = "EnrollUI";

    constructor(pOnChannelReady, pOnRFIDCardChangedHandler, pOnMAGSCardDetectedHandler, pOnResponseFIDOEnrollHandler, pOnResponseFIDOAuthenticateHandler, pOnResponseFIDOHostAuthHandler, pOnResponseBIODevices, pOnResponseBIOEnrolled, pOnResponseBIODelete, pOnResponseBIOEnroll, pOnResponseBIOIdentify)
    {
        
        console.log("RapidaIDChannel::RapidaIDChannel()");

        this.OnSocketOpen = this.OnSocketOpen.bind(this);
        this.OnSocketError = this.OnSocketError.bind(this);
        this.OnSocketClose = this.OnSocketClose.bind(this);
        this.OnChannelOpened = this.OnChannelOpened.bind(this);

        this.OnRFIDCardChanged = this.OnRFIDCardChanged.bind(this);
        this.OnMAGSCardDetected = this.OnMAGSCardDetected.bind(this);
        this.OnResponseFIDOEnroll = this.OnResponseFIDOEnroll.bind(this);
        this.OnResponseFIDOAuthenticate = this.OnResponseFIDOAuthenticate.bind(this);
        this.OnResponseFIDOHostAuth = this.OnResponseFIDOHostAuth.bind(this);

	this.OnResponseBIODevices = this.OnResponseBIODevices.bind(this);
	this.OnResponseBIOEnrolled = this.OnResponseBIOEnrolled.bind(this);
	this.OnResponseBIODelete = this.OnResponseBIODelete.bind(this);
	this.OnResponseBIOEnroll = this.OnResponseBIOEnroll.bind(this);
	this.OnResponseBIOIdentify = this.OnResponseBIOIdentify.bind(this);

        this.OnChannelReadyHandler = pOnChannelReady;
        this.OnRFIDCardChangedHandler = pOnRFIDCardChangedHandler;
        this.OnMAGSCardDetectedHandler = pOnMAGSCardDetectedHandler;
        this.OnResponseFIDOEnrollHandler = pOnResponseFIDOEnrollHandler;
        this.OnResponseFIDOAuthenticateHandler = pOnResponseFIDOAuthenticateHandler;
        this.OnResponseFIDOHostAuthHandler = pOnResponseFIDOHostAuthHandler;

	this.OnResponseBIODevicesHandler = pOnResponseBIODevices;
	this.OnResponseBIOEnrolledHandler = pOnResponseBIOEnrolled;
	this.OnResponseBIODeleteHandler = pOnResponseBIODelete;
	this.OnResponseBIOEnrollHandler = pOnResponseBIOEnroll;
	this.OnResponseBIOIdentifyHandler = pOnResponseBIOIdentify;

        this.Socket = null;
        this.Channel = null;

    }

    Create()
    {
        
        this.Socket = new WebSocket("ws://localhost:65522");
        this.Socket.onopen = this.OnSocketOpen;
        this.Socket.onerror = this.OnSocketError;
        this.Socket.onclose = this.OnSocketClose;
        
    }

    Destroy()
    {

        if(this.Socket)
        {
            this.Socket.close();
            this.Socket = null;
        }

        if(this.Channel)
        {
            this.Channel = null;
        }

    }

    OnSocketOpen()
    {
        console.log("RapidaIDChannel::OnSocketOpened()");
        this.Channel = new QWebChannel(this.Socket, this.OnChannelOpened);
    }

    OnSocketError(error)
    {
        console.log("RapidaIDChannel::OnSocketError() -> " + error);
        if(this.OnChannelReadyHandler)
            this.OnChannelReadyHandler(false);
        //document.getElementById("ElLabelPrompt").innerText = "Connection to reader failed, make sure RapidaID Client is installed.";
    };

    OnSocketClose()
    {
        console.log("RapidaIDChannel::OnSocketClose");
        //document.getElementById("ElLabelPrompt").innerText = "Connection to reader failed, make sure RapidaID Client is installed.";
    };

    OnChannelOpened(channel)
    {
        console.log("RapidaIDChannel::OnChannelOpened(" + channel + ")");
        channel.objects.DeviceAccess.OnRFIDCardChanged.connect(this.OnRFIDCardChanged);
        channel.objects.DeviceAccess.OnMAGSCardDetected.connect(this.OnMAGSCardDetected);
        channel.objects.DeviceAccess.ResponseFIDOEnroll.connect(this.OnResponseFIDOEnroll);
        channel.objects.DeviceAccess.ResponseFIDOAuthenticate.connect(this.OnResponseFIDOAuthenticate);
        channel.objects.DeviceAccess.ResponseFIDOHostAuth.connect(this.OnResponseFIDOHostAuth);
        channel.objects.DeviceAccess.ResponseBIODevices.connect(this.OnResponseBIODevices);
        channel.objects.DeviceAccess.ResponseBIOEnrolled.connect(this.OnResponseBIOEnrolled);
        channel.objects.DeviceAccess.ResponseBIODelete.connect(this.OnResponseBIODelete);
        channel.objects.DeviceAccess.ResponseBIOEnroll.connect(this.OnResponseBIOEnroll);
        channel.objects.DeviceAccess.ResponseBIOIdentify.connect(this.OnResponseBIOIdentify);
        if(this.OnChannelReadyHandler)
            this.OnChannelReadyHandler(true);
    }

    OnRFIDCardChanged(sCardID, fPresent)
    {
        console.log("RapidaIDChannel::OnRFIDCardChanged(" + sCardID + ", " + fPresent + ")");
        if(this.OnRFIDCardChangedHandler && this.OnRFIDCardChangedHandler !== undefined)
            this.OnRFIDCardChangedHandler(sCardID, fPresent);
    }

    OnMAGSCardDetected(sCardID)
    {
        console.log("RapidaIDChannel::OnMAGSCardDetected(" + sCardID + ")");
        if(this.OnMAGSCardDetectedHandler && this.OnMAGSCardDetectedHandler !== undefined)
            this.OnMAGSCardDetectedHandler(sCardID);
    }

    OnResponseFIDOEnroll(eResult, sPublicKey, sKeyHandle)
    {
        console.log("RapidaIDChannel::OnResponseFIDOEnroll(" + eResult + ")");
        if(this.OnResponseFIDOEnrollHandler && this.OnResponseFIDOEnrollHandler !== undefined)
            this.OnResponseFIDOEnrollHandler(eResult, sPublicKey, sKeyHandle);
    }

    OnResponseFIDOAuthenticate(eResult)
    {
        console.log("RapidaIDChannel::OnResponseFIDOAuthenticate(" + eResult + ")");
        if(this.OnResponseFIDOAuthenticateHandler && this.OnResponseFIDOAuthenticateHandler !== undefined)
            this.OnResponseFIDOAuthenticateHandler(eResult);
    }

    OnResponseFIDOHostAuth(eResult, iAuthItem, sHostResult)
    {
        console.log("RapidaIDChannel::OnResponseFIDOHostAuth(" + eResult + ", " + iAuthItem + ")");
        if(this.OnResponseFIDOHostAuthHandler && this.OnResponseFIDOHostAuthHandler !== undefined)
            this.OnResponseFIDOHostAuthHandler(eResult, iAuthItem, sHostResult);
    }

    OnResponseBIODevices(eResult, pDevices, pChars)
    {
        console.log("RapidaIDChannel::OnResponseBIODevices(" + eResult + ", " + pDevices + ", " + pChars + ")");
        if(this.OnResponseBIODevicesHandler && this.OnResponseBIODevicesHandler !== undefined)
            this.OnResponseBIODevicesHandler(eResult, pDevices, pChars);
    }

    OnResponseBIOEnrolled(eResult, pEnrolled)
    {
        console.log("RapidaIDChannel::OnResponseBIOEnrolled(" + eResult + ", " + pEnrolled + ")");
        if(this.OnResponseBIOEnrolledHandler && this.OnResponseBIOEnrolledHandler !== undefined)
            this.OnResponseBIOEnrolledHandler(eResult, pEnrolled);
    }

    OnResponseBIODelete(eResult, iFinger)
    {
        console.log("RapidaIDChannel::OnResponseBIODelete(" + eResult + ", " + iFinger + ")");
        if(this.OnResponseBIODeleteHandler && this.OnResponseBIODeleteHandler !== undefined)
            this.OnResponseBIODeleteHandler(eResult, iFinger);
    }

    OnResponseBIOEnroll(eResult, ePrompt, eQuality, nProgress, sTemplate, sExtraData)
    {
        console.log("RapidaIDChannel::OnResponseBIOEnroll(" + eResult + ", " + ePrompt + ", " + eQuality + ", " + nProgress + ", " + sTemplate);// + ", " + sExtraData + ")");
        if(this.OnResponseBIOEnrollHandler && this.OnResponseBIOEnrollHandler !== undefined)
            this.OnResponseBIOEnrollHandler(eResult, ePrompt, eQuality, nProgress, sTemplate, sExtraData);
    }

    OnResponseBIOIdentify(eResult, ePrompt, eQuality, sTemplate, iTemplate, sUserInfo)
    {
        console.log("RapidaIDChannel::OnResponseBIOIdentify(" + eResult + ", " + ePrompt + ", " + eQuality + ", " + sTemplate + ", " + iTemplate + ", " + sUserInfo + ")");
        if(this.OnResponseBIOIdentifyHandler && this.OnResponseBIOIdentifyHandler !== undefined)
            this.OnResponseBIOIdentifyHandler(eResult, ePrompt, eQuality, sTemplate, iTemplate, sUserInfo);
    }

    GetRFIDCards()
    {
        console.log("RapidaIDChannel::GetRFIDCards()");
        return this.Channel ? this.Channel.objects.DeviceAccess.RFIDCards : [];
    }

    RequestFIDOEnroll(sAppURL, sAppID)
    {
        console.log("RapidaIDChannel::RequestFIDOEnroll()");
        if(this.Channel)
            this.Channel.objects.DeviceAccess.RequestFIDOEnroll(sAppURL, sAppID);
        else
            this.OnResponseFIDOEnroll(RapidaIDMainDefs.ERROR_NOT_READY);
    }

    RequestFIDOAuthenticate(sAppURL, sAppID, sPublicKey, sKeyHandle)
    {
        console.log("RapidaIDChannel::RequestFIDOAuthenticate()");
        if(this.Channel)
            this.Channel.objects.DeviceAccess.RequestFIDOAuthenticate(sAppURL, sAppID, sPublicKey, sKeyHandle);
        else
            this.OnResponseFIDOAuthenticate(RapidaIDMainDefs.ERROR_NOT_READY);
    }

    RequestFIDOHostAuth(sItems)
    {
        console.log("RapidaIDChannel::RequestFIDOHostAuth()");
        if(this.Channel)
            this.Channel.objects.DeviceAccess.RequestFIDOHostAuth(sItems);
        else
            this.OnResponseFIDOHostAuth(RapidaIDMainDefs.ERROR_NOT_READY);
    }

    RequestBIODevices()
    {
        console.log("RapidaIDChannel::RequestBIODevices()");
        if(this.Channel)
            this.Channel.objects.DeviceAccess.RequestBIODevices();
        else
            this.OnResponseBIODevices(RapidaIDMainDefs.ERROR_NOT_READY, [], []);
    }

    RequestBIOEnrolled(sDevice, sUserInfo)
    {
        console.log("RapidaIDChannel::RequestBIOEnrolled()");
        if(this.Channel)
            this.Channel.objects.DeviceAccess.RequestBIOEnrolled(sDevice, sUserInfo);
        else
            this.OnResponseBIOEnrolled(RapidaIDMainDefs.ERROR_NOT_READY);
    }

    RequestBIODelete(sDevice, sUserInfo, iFinger)
    {
        console.log("RapidaIDChannel::RequestBIODelete()");
        if(this.Channel)
            this.Channel.objects.DeviceAccess.RequestBIODelete(sDevice, sUserInfo, iFinger);
        else
            this.OnResponseBIODelete(RapidaIDMainDefs.ERROR_NOT_READY);
    }

    RequestBIOEnroll(sDevice, sUserInfo, sFinger)
    {
        console.log("RapidaIDChannel::RequestBIOEnroll()");
        if(this.Channel)
            this.Channel.objects.DeviceAccess.RequestBIOEnroll(sDevice, sUserInfo, sFinger);
        else
            this.OnResponseBIOEnroll(RapidaIDMainDefs.ERROR_NOT_READY);
    }

    RequestBIOIdentify(sDevice, sUserInfo, sTemplates)
    {
        console.log("RapidaIDChannel::RequestBIOIdentify()");
        if(this.Channel)
            this.Channel.objects.DeviceAccess.RequestBIOIdentify(sDevice, sUserInfo, sTemplates);
        else
            this.OnResponseBIOIdentify(RapidaIDMainDefs.ERROR_NOT_READY);
    }

    RequestPerformSync(sUserInfo)
    {
        console.log("RapidaIDChannel::RequestPerformSync()");
        if(this.Channel)
            this.Channel.objects.DeviceAccess.RequestPerformSync(sUserInfo);
    }

    static BioIsDeviceChar(sDevChars, sChar)
    {
        let pDevChars = sDevChars.split(",");
        return pDevChars.indexOf(sChar) !== -1;
    }

    static BioGetDeviceName(sDevName)
    {
        return sDevName.substring(sDevName.indexOf(">") + 1);
    }

    static PerformSync(sUserInfo)
    {
        let pChannel = new RapidaIDChannel(function(fReady) {
            if(fReady)
                pChannel.RequestPerformSync(sUserInfo);
            pChannel.Destroy();
        });
        pChannel.Create();
    }

};

export default RapidaIDChannel;
