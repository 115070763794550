//
//  @file HostsPolicyEdit.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import {
  //Grid,
  //Row,
  //Col,
  //FormGroup,
  //ControlLabel,
  //FormControl,
  //Form,
  //InputGroup
} from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import SweetAlert from "react-bootstrap-sweetalert";

import FormInputText from "components/FormInputs/FormInputText.jsx";

class HostsPolicyEdit extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            TextError: null
        }

        this.OnResult = this.OnResult.bind(this);

    }

    /*componentWillMount()
    {
    }*/

    OnResult(fResult)
    {
        if(fResult)
        {
            let sError = "";
            if(this.props.ItemInfo.Name.length < 5)
                sError = global.StrNameShouldBe;
            else if(this.props.ItemInfo.Desc.length < 5)
                sError = global.StrDescShouldBe;
            if(sError !== "")
            {
                this.setState({ TextError: (<center><small className="text-danger">{sError}</small></center>) });
                return;
            }
        }
        this.props.OnResult(this.props.Parent, this, fResult);
    }

    render()
    {
        return (
            <SweetAlert
                style={{ display: "block" }}
                title={""}
                openAnim={false}
                showConfirm={false}
                closeOnClickOutside={false}
                onConfirm={() => this.props.OnResult(true)}
                onCancel={() => this.props.OnResult(false)}
            >
                <span style={{ fontSize: '14px', textAlign: 'left' }}>
                {this.props.title}
                    <FormInputText placeholder={global.StrPolicyName} defaultValue={this.props.ItemInfo.Name} onChange={event => this.props.ItemInfo.Name = event.target.value} autoFocus/>
                    <br/>
                    <FormInputText placeholder={global.StrPolicyDesc} defaultValue={this.props.ItemInfo.Desc} onChange={event => this.props.ItemInfo.Desc = event.target.value}/>
                    <br/>
                    <FormInputText placeholder={global.StrConfiguration} componentClass="textarea" wrap="off" rows="12" style={{fontFamily: 'monospace'}} defaultValue={this.props.ItemInfo.Value} onChange={event => this.props.ItemInfo.Value = event.target.value}/>
                    {this.state.TextError}
                    <br/>
                    <center>
                        <Button fill onClick={() => this.OnResult(true)}>{global.StrSave}</Button>
                        <Button fill onClick={() => this.OnResult(false)}>{global.StrCancel}</Button>
                    </center>
                </span>
            </SweetAlert>
        );
    }

}

export default HostsPolicyEdit;
