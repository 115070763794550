//
//  @file HostsViewEdit.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import {
  //Grid,
  Row,
  Col,
  //FormGroup,
  //ControlLabel,
  //FormControl,
  //Form,
  //InputGroup
} from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import SweetAlert from "react-bootstrap-sweetalert";

import FormInputText from "components/FormInputs/FormInputText.jsx";

class HostsViewEdit extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            TextError: null
        }

        this.OnResult = this.OnResult.bind(this);

    }

    /*componentWillMount()
    {
    }*/

    OnResult(fResult)
    {
        if(fResult)
        {
            /*let sError = "";
            if(this.props.ItemInfo.Name.length < 5)
                sError = "Name should 5 or more symbols";
            else if(this.props.ItemInfo.Desc.length < 5)
                sError = "Description should 5 or more symbols";
            if(sError !== "")
            {
                this.setState({ TextError: (<center><small className="text-danger">{sError}</small></center>) });
                return;
            }*/
        }
        this.props.OnResult(this.props.Parent, this, fResult);
    }

    render()
    {
        return (
            <SweetAlert
                style={{ display: "block" }}
                title={""}
                openAnim={false}
                showConfirm={false}
                closeOnClickOutside={false}
                onConfirm={() => this.props.OnResult(true)}
                onCancel={() => this.props.OnResult(false)}
            >
                <span style={{ fontSize: '14px', textAlign: 'left' }}>
                {this.props.title}
                    <Row>
                        <Col sm={12}>
                            <FormInputText placeholder="UUID" defaultValue={this.props.ItemInfo.UUID} onChange={event => this.props.ItemInfo.UUID = event.target.value} autoFocus/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col sm={12}>
                            <FormInputText placeholder="HashData" defaultValue={this.props.ItemInfo.HashData} onChange={event => this.props.ItemInfo.HashData = event.target.value}/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={6}>
                            <FormInputText placeholder="HostName" defaultValue={this.props.ItemInfo.HostName} onChange={event => this.props.ItemInfo.HostName = event.target.value}/>
                        </Col>
                        <Col xs={6}>
                            <FormInputText placeholder="IPAddress" defaultValue={this.props.ItemInfo.IPAddress} onChange={event => this.props.ItemInfo.IPAddress = event.target.value}/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={6}>
                            <FormInputText placeholder="HostPlatform" defaultValue={this.props.ItemInfo.HostPlatform} onChange={event => this.props.ItemInfo.HostPlatform = event.target.value}/>
                        </Col>
                        <Col xs={6}>
                            <FormInputText placeholder="HostVersion" defaultValue={this.props.ItemInfo.HostVersion} onChange={event => this.props.ItemInfo.HostVersion = event.target.value}/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={6}>
                            <FormInputText placeholder="SoftVersion" defaultValue={this.props.ItemInfo.SoftVersion} onChange={event => this.props.ItemInfo.SoftVersion = event.target.value}/>
                        </Col>
                        <Col xs={6}>
                            <FormInputText placeholder="CreatedTime" defaultValue={this.props.ItemInfo.CreatedTime} onChange={event => this.props.ItemInfo.CreatedTime = event.target.value}/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={6}>
                            <FormInputText placeholder="UpdatedTime" defaultValue={this.props.ItemInfo.UpdatedTime} onChange={event => this.props.ItemInfo.UpdatedTime = event.target.value}/>
                        </Col>
                        <Col xs={6}>
                            <FormInputText placeholder="AccessedTime" defaultValue={this.props.ItemInfo.AccessedTime} onChange={event => this.props.ItemInfo.AccessedTime = event.target.value}/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={6}>
                            <FormInputText placeholder="PushToken" defaultValue={this.props.ItemInfo.PushToken} onChange={event => this.props.ItemInfo.PushToken = event.target.value}/>
                        </Col>
                        <Col xs={6}>
                            <FormInputText placeholder="PushInfo" defaultValue={this.props.ItemInfo.PushInfo} onChange={event => this.props.ItemInfo.PushInfo = event.target.value}/>
                        </Col>
                    </Row>
                    <br/>
                    <FormInputText placeholder="HostInfo" defaultValue={this.props.ItemInfo.HostInfo} onChange={event => this.props.ItemInfo.HostInfo = event.target.value}/>
                    <br/>
                    <FormInputText placeholder="PolicyData" defaultValue={this.props.ItemInfo.PolicyData} onChange={event => this.props.ItemInfo.PolicyData = event.target.value}/>
                    <br/>
                    {this.state.TextError}
                    <br/>
                    <center>
                        <Button fill onClick={() => this.OnResult(true)}>{global.StrSave}</Button>
                        <Button fill onClick={() => this.OnResult(false)}>{global.StrCancel}</Button>
                    </center>
                </span>
            </SweetAlert>
        );
    }

}

export default HostsViewEdit;
