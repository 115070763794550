//
//  @file EnrollOTPStep1.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React from "react";

import { Row, Col, FormGroup, ControlLabel } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import FormInputText from "components/FormInputs/FormInputText.jsx";

class EnrollOTPStep1 extends React.Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            TextError: null
        };

        this.OnCancel = this.OnCancel.bind(this);
        this.OnContinue = this.OnContinue.bind(this);

    }

    isValidated()
    {
        var sError = null;
        if(this.props.TokenInfo.Name === "")
            sError = global.StrEmptyTokenNameNotAllowed;
        if(sError)
            this.setState({ TextError: (<small className="text-danger">{sError}</small>) });
        return !sError;
    }

    OnCancel()
    {
        this.props.OnResult(false);
    }

    OnContinue()
    {
        if(!this.isValidated())
            return;
        this.props.jumpToStep(1);
    }

    render()
    {
        //<Row noGutters={true}>
        return (
            <div className="wizard-step">
                <br/>
                <br/>
                <br/>
                <center>{global.StrPleaseFillRequiredInfo}</center>
                <br/>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <ControlLabel>
                                <span style={{fontSize: '14px', fontWeight: 'normal' }}>
                                    {global.StrTokenName} <span className="text-danger">*</span>
                                </span>
                            </ControlLabel>
                            <FormInputText
                                name="TokenName"
                                placeholder={global.StrForExampleToken1}
                                defaultValue={this.props.TokenInfo.Name}
                                autoFocus
                                autoComplete="off"
                                disabled={this.props.TokenInfo.Default === true}
                                onChange={event => this.props.TokenInfo.Name = event.target.value}
                            />
                            <center>{this.state.TextError}</center>
                        </FormGroup>
                    </Col>
                </Row>
                <br/>
                <br/>
                <center>
                    <Button fill onClick={this.OnCancel}>{global.StrCancel}</Button>&nbsp;
                    <Button fill onClick={this.OnContinue}>{global.StrContinue}</Button>
                </center>
            </div>
        );
    }

}

export default EnrollOTPStep1;
