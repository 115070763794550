//
//  @file config.js.rel
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

module.exports = global.Config =  {
    ServiceURL: "https://rapida.us/Service/",
    LoginAPI: "lapi.php",
    AdminAPI: "aapi.php",
    UserAPI: "uapi.php",
    SyncAPI: "sapi.php",
    UserData: {
        Counter: 0,
        UserInfo: "",
        SessionKey: null,
        IsUserAdmin: false,
        PushData: [],
        OTPData: [],
        CardData: [],
        MAGSData: [],
        FIDOData: [],
        FPRTData: []
    }
};

