//
//  @file AuthCardMethod.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import Button from "components/CustomButton/CustomButton.jsx";

import ImgTileUNP from "assets/img/TileUNP.png";
import ImgTilePush from "assets/img/TilePush.png";
import ImgTileOTP from "assets/img/TileOTP.png";
import ImgTileRFID from "assets/img/TileRFID.png";
import ImgTileMAGS from "assets/img/TileMAGS.png";
import ImgTileFIDO from "assets/img/TileFIDO.png";
import ImgTileFPRT from "assets/img/TileFPRT.png";

class AuthCardMethod extends Component
{

    static ETileUNP = 2;
    static ETilePush = 3;
    static ETileOTP = 4;
    static ETileRFID = 5;
    static ETileMAGS = 6;
    static ETileFIDO = 7;
    static ETileFPRT = 8;

    constructor(props)
    {

        super(props);

        this.OnTileClicked = this.OnTileClicked.bind(this);
        this.OnCancelClicked = this.OnCancelClicked.bind(this);

    }

    componentDidLoad()
    {
    }

    OnTileClicked(pEvent, eTile)
    {
        //console.log("OnTileClicked() -> " + eTile);
        pEvent.preventDefault();
        this.props.OnTile(eTile);
    }

    OnCancelClicked()
    {
        this.props.OnCancel();
    }

    render()
    {
        const { ShowUNP, ShowPush, ShowOTP, ShowRFID, ShowMAGS, ShowFIDO, ShowFPRT } = this.props;
        const ReactUNP = (<a href="#pablo" onClick={e => this.OnTileClicked(e, AuthCardMethod.ETileUNP)}><img src={ImgTileUNP} alt="TileUNP" width="64" height="64" title={global.StrStrUsernameAndPassword}/></a>);
        const ReactPush = (<a href="#pablo" onClick={e => this.OnTileClicked(e, AuthCardMethod.ETilePush)}><img src={ImgTilePush} alt="TilePush" width="64" height="64" title={global.StrPushMobile}/></a>);
        const ReactOTP = (<a href="#pablo" onClick={e => this.OnTileClicked(e, AuthCardMethod.ETileOTP)}><img src={ImgTileOTP} alt="TileOTP" width="64" height="64" title={global.StrOneTimePassword}/></a>);
        const ReactRFID = (<a href="#pablo" onClick={e => this.OnTileClicked(e, AuthCardMethod.ETileRFID)}><img src={ImgTileRFID} alt="TileRFID" width="64" height="64" title={global.StrRFIDCard}/></a>);
        const ReactMAGS = (<a href="#pablo" onClick={e => this.OnTileClicked(e, AuthCardMethod.ETileMAGS)}><img src={ImgTileMAGS} alt="TileMAGS" width="64" height="64" title={global.StrMagstripe}/></a>);
        const ReactFIDO = (<a href="#pablo" onClick={e => this.OnTileClicked(e, AuthCardMethod.ETileFIDO)}><img src={ImgTileFIDO} alt="TileFIDO" width="64" height="64" title={global.StrFIDOToken}/></a>);
        const ReactFPRT = (<a href="#pablo" onClick={e => this.OnTileClicked(e, AuthCardMethod.ETileFPRT)}><img src={ImgTileFPRT} alt="TileFPRT" width="64" height="64" title={global.StrFingerprint}/></a>);
        return (
            <div>
                <center><h4>{global.SelectAuthMethod}</h4></center>
                <br/>
                <center>
                { ShowUNP && ReactUNP }
                { ShowPush && ReactPush }
                { ShowOTP && ReactOTP }
                { ShowRFID && ReactRFID }
                { ShowMAGS && ReactMAGS }
                { ShowFIDO && ReactFIDO }
                { ShowFPRT && ReactFPRT }
                </center>
                <br/>
                <center>
                    <Button bsStyle="info" fill wd onClick={this.OnCancelClicked.bind(this)}>
                        {global.StrCancel}
                    </Button>
                </center>
            </div>
        );
    }
    
}

export default AuthCardMethod;
