//
//  @file EnrollFIDOStep2.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React from "react";

import Button from "components/CustomButton/CustomButton.jsx";

import RapidaIDChannel from "scripts/RapidaIDChannel.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

const EStateGetServerKey = 0;
const EStateGetHostKey = 1;
//const EStateSendHostKey = 2;
const EStateError = 3;
//const EStateFinished = 4;

class EnrollFIDOStep2 extends React.Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            TextStatus: global.StrConnectingDeviceWait,
            State: EStateGetServerKey
        };

        this.OnCancel = this.OnCancel.bind(this);
        this.OnStartOver = this.OnStartOver.bind(this);
        this.OnChannelReady = this.OnChannelReady.bind(this);
        this.OnResponseFIDOEnroll = this.OnResponseFIDOEnroll.bind(this);

    }

    componentDidMount()
    {
        this.Channel = new RapidaIDChannel(this.OnChannelReady, null, null, this.OnResponseFIDOEnroll, null);
        this.Channel.Create();
    }

    componentWillUnmount()
    {
        this.Channel.Destroy();
        this.Channel = null;
    }

    isValidated()
    {
        var sError = null;
        if(this.props.TokenInfo.Name === "")
            sError = global.StrEmptyTokenNameNotAllowed;
        if(sError)
            this.ShowStatus(true, {sError});
        return !sError;
    }

    OnCancel()
    {
        this.props.OnResult(false);
    }

    OnStartOver()
    {
        this.Channel.RequestFIDOEnroll(this.props.TokenInfo.AppURL, this.props.TokenInfo.AppID);
        this.ShowStatus(false, global.StrTouchFIDOToken);
    }

    OnChannelReady(fReady)
    {
        console.log("AuthCard::OnChannelReady(" + fReady + ")");
        if(fReady)
        {
            this.ShowStatus(false, global.StrTouchFIDOToken);
            this.Channel.RequestFIDOEnroll(this.props.TokenInfo.AppURL, this.props.TokenInfo.AppID);
        }
        else
            this.ShowStatus(true, global.StrUnableConnectHardware);
    }

    OnResponseFIDOEnroll(eResult, sPublicKey, sKeyHandle)
    {
        console.log("AuthCard::OnResponseFIDOEnroll(" + eResult + ")");
        if(eResult !== RapidaIDMainDefs.ERROR_SUCCESS)
            this.ShowStatus(true, global.StrUnableConnectHardware + eResult + "." + global.StrCheckTokenPresent);
        else
        {
            this.props.TokenInfo.PublicKey = sPublicKey;
            this.props.TokenInfo.KeyHandle = sKeyHandle;
            this.props.jumpToStep(2);
        }
    }
    
    ShowStatus(fError, sText)
    {
        if(fError)
            this.setState({ TextStatus: (<div className="text-danger">{sText}.</div>), State: EStateError });
        else
            this.setState({ TextStatus: (<div>{sText}.</div>), State: EStateGetHostKey });
    }

    render()
    {
        return (
            <div className="wizard-step">
                <br/>
                <br/>
                <br/>
                <center>{global.StrPleaseCheckStatusMessages}</center>
                <br/>
                <br/>
                <center>{this.state.TextStatus}</center>
                <br/>
                <br/>
                <br/>
                <center>
                    {this.state.State === EStateError && <Button fill onClick={this.OnCancel}>{global.StrCancel}</Button>}&nbsp;
                    {this.state.State === EStateError && <Button fill onClick={this.OnStartOver}>{global.StrStartOver}</Button>}
                </center>
            </div>
        );
    }

}

export default EnrollFIDOStep2;
