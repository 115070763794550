//
//  @file UsersViewEdit.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import {
  //Grid,
  Row,
  Col,
  //FormGroup,
  //ControlLabel,
  //FormControl,
  //Form,
  //InputGroup
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";

import Select from "react-select";

import Button from "components/CustomButton/CustomButton.jsx";

import SweetAlert from "react-bootstrap-sweetalert";

import FormInputText from "components/FormInputs/FormInputText.jsx";

//import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";
import RapidaIDUtils from "scripts/RapidaIDUtils.jsx";

import ImgTileMain from "assets/img/TileMain.png";
import ImgTilePolicy from "assets/img/TilePolicy.png";
import ImgTileAcc from "assets/img/TileAcc.png";
import ImgTileSSO from "assets/img/TileSSO.png";
import ImgTilePush from "assets/img/TilePush.png";
import ImgTileOTP from "assets/img/TileOTP.png";
import ImgTileRFID from "assets/img/TileRFID.png";
import ImgTileMAGS from "assets/img/TileMAGS.png";
import ImgTileFIDO from "assets/img/TileFIDO.png";
import ImgTileFPRT from "assets/img/TileFPRT.png";

const ETileMain = 0;
const ETilePolicy = 1;
const ETileAcc = 2;
const ETileSSO = 3;
const ETilePush = 4;
const ETileOTP = 5;
const ETileRFID = 6;
const ETileMAGS = 7;
const ETileFIDO = 8;
const ETileFPRT = 9;
const ETileAll = 10;

class UsersViewEdit extends Component
{

    constructor(props)
    {

        super(props);

        this.StateOptions = [
            { label: "State", isDisabled: true },
            { value: 0, label: global.StrNewUser },
            { value: 1, label: global.StrSteady },
            { value: 2, label: global.StrBlocked }
        ];

        this.LanguageOptions = [
            { label: "Language", isDisabled: true }
            //{ value: 0, label: "English" },
            //{ value: 1, label: "Russian" },
        ];

        this.state = {
            ActiveTile: ETileMain,
            StateID: parseInt(this.props.ItemInfo.StateID),
            Language: parseInt(this.props.ItemInfo.Language),
            TextError: null
        }

        for(let iLang = 0; iLang < global.LangListEN.length; iLang++)
        	this.LanguageOptions.push({ value: iLang, label: global.LangListEN[iLang] });

        this.OnResult = this.OnResult.bind(this);

        this.m_target = React.createRef();
    }

    /*componentWillMount()
    {
    }*/

    OnResult(fResult)
    {
        if(fResult)
        {
            /*let sError = "";
            if(this.props.ItemInfo.Name.length < 5)
                sError = "Name should 5 or more symbols";
            else if(this.props.ItemInfo.Desc.length < 5)
                sError = "Description should 5 or more symbols";
            if(sError !== "")
            {
                this.setState({ TextError: (<center><small className="text-danger">{sError}</small></center>) });
                return;
            }*/
            //this.props.ItemInfo.StateID = this.state.StateID;
            //this.props.ItemInfo.Language = this.state.Language;
        }
        this.props.OnResult(this.props.Parent, this, fResult);
    }

    OnTileClicked(pEvent, eTile)
    {

        pEvent.preventDefault();

        this.setState({ActiveTile: eTile});

        console.log("OnTileClicked(" + eTile + ") -> " + this.state.ActiveTile);

    }

    OnTileChanged(sFieldName, pEvent)
    {
        if(sFieldName)
            this.props.ItemInfo[sFieldName] = pEvent.target.value;
        else
            RapidaIDUtils.CopyObject(JSON.parse(pEvent.target.value), this.props.ItemInfo);
    }

    RenderMainInfo()
    {
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <FormInputText autoFocus placeholder="UUID" defaultValue={this.props.ItemInfo.UUID} onChange={event => this.props.ItemInfo.UUID = event.target.value}/>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={4}>
                        <Select className="react-select" classNamePrefix="react-select" name="StateID" placeholder="State"
                            value={this.StateOptions[parseInt(this.props.ItemInfo.StateID) + 1]}
                            onChange={value => this.setState({StateID: this.props.ItemInfo.StateID = value.value.toString()})}
                            options={this.StateOptions}
                        />
                    </Col>
                    <Col xs={4}>
                        <Select className="react-select" classNamePrefix="react-select" name="Language" placeholder="Language"
                            value={this.LanguageOptions[parseInt(this.props.ItemInfo.Language) + 1]}
                            onChange={value => this.setState({Language: this.props.ItemInfo.Language = value.value.toString()})}
                            options={this.LanguageOptions}
                        />
                    </Col>
                    <Col xs={4}>
                        <FormInputText tooltip placeholder="TimeStamp" defaultValue={this.props.ItemInfo.TimeStamp} onChange={event => this.props.ItemInfo.TimeStamp = event.target.value}/>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={4}>
                        <FormInputText tooltip placeholder="StateID" defaultValue={this.props.ItemInfo.StateID} onChange={event => this.props.ItemInfo.StateID = event.target.value}/>
                    </Col>
                    <Col xs={4}>
                        <FormInputText tooltip placeholder="Flags" defaultValue={this.props.ItemInfo.Flags} onChange={event => this.props.ItemInfo.Flags = event.target.value}/>
                    </Col>
                    <Col xs={4}>
                        <FormInputText tooltip placeholder="UserRole" defaultValue={this.props.ItemInfo.UserRole} onChange={event => this.props.ItemInfo.UserRole = event.target.value}/>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={4}>
                        <FormInputText tooltip placeholder="UserInfo" defaultValue={this.props.ItemInfo.UserInfo} onChange={event => this.props.ItemInfo.UserInfo = event.target.value}/>
                    </Col>
                    <Col xs={4}>
                        <FormInputText tooltip placeholder="UserPass" defaultValue={this.props.ItemInfo.UserPass} onChange={event => this.props.ItemInfo.UserPass = event.target.value}/>
                    </Col>
                    <Col xs={4}>
                        <FormInputText tooltip placeholder="UserPIN" defaultValue={this.props.ItemInfo.UserPIN} onChange={event => this.props.ItemInfo.UserPIN = event.target.value}/>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={4}>
                        <FormInputText tooltip placeholder="DataPath" defaultValue={this.props.ItemInfo.DataPath} onChange={event => this.props.ItemInfo.DataPath = event.target.value}/>
                    </Col>
                    <Col xs={4}>
                        <FormInputText tooltip placeholder="AccsPath" defaultValue={this.props.ItemInfo.AccsPath} onChange={event => this.props.ItemInfo.AccsPath = event.target.value}/>
                    </Col>
                    <Col xs={4}>
                        <FormInputText tooltip placeholder="AccsPass" defaultValue={this.props.ItemInfo.AccsPass} onChange={event => this.props.ItemInfo.AccsPass = event.target.value}/>
                    </Col>
                </Row>
                <br/>
            </div>
        );
    }

    RenderRawEdit(sFieldTitle, sFieldName)
    {
        return (
            <div>
                <FormInputText autoFocus wrap="off" placeholder={sFieldTitle} componentClass="textarea" rows="12" style={{fontFamily: 'monospace'}} 
                	defaultValue={RapidaIDUtils.JSONFormat(sFieldName ? this.props.ItemInfo[sFieldName] : this.props.ItemInfo)} 
                	onChange={event => this.OnTileChanged(sFieldName, event)}
                />
                <br/>
            </div>
        );
//                	onChange={event => if(sFieldName) this.props.ItemInfo[sFieldName] = event.target.value; else this.props.ItemInfo = event.target.value}
    }

    RenderTile(sTitle, pImgTile, eTileID, sTileAlt)
    {
        const pToolTip = (<Tooltip style={{zIndex: 10000}} id={sTitle}>{sTitle}</Tooltip>);
        return (
            <OverlayTrigger placement="top" overlay={pToolTip}>
                <a href="#pablo" onClick={e => this.OnTileClicked(e, eTileID)}><img src={pImgTile} alt={sTileAlt} width="48" height="48" title={sTitle}/></a>
            </OverlayTrigger>
        );
    }

    render()
    {
        return (
            <SweetAlert
                style={{ display: "block", width: "580px" }}
                title=""
                openAnim={false}
                showConfirm={false}
                closeOnClickOutside={false}
                onConfirm={() => this.props.OnResult(true)}
                onCancel={() => this.props.OnResult(false)}
            >
                <span style={{ fontSize: '14px', textAlign: 'left' }}>
                    {this.props.title}
                    {this.state.ActiveTile === ETileMain && this.RenderMainInfo()}
                    {this.state.ActiveTile === ETilePolicy && this.RenderRawEdit("Policy Data", "PolicyData")}
                    {this.state.ActiveTile === ETileAcc && this.RenderRawEdit("Accounts Data", "AccData")}
                    {this.state.ActiveTile === ETileSSO && this.RenderRawEdit("SingleSignOn Templates", "SSOData")}
                    {this.state.ActiveTile === ETilePush && this.RenderRawEdit("Push Method Data", "PushData")}
                    {this.state.ActiveTile === ETileOTP && this.RenderRawEdit("OTP Method Data", "OTPData")}
                    {this.state.ActiveTile === ETileRFID && this.RenderRawEdit("RFID Method Data", "CardData")}
                    {this.state.ActiveTile === ETileMAGS && this.RenderRawEdit("Magstripe Method Data", "MAGSData")}
                    {this.state.ActiveTile === ETileFIDO && this.RenderRawEdit("FIDO Method Data", "FIDOData")}
                    {this.state.ActiveTile === ETileFPRT && this.RenderRawEdit("Fingerprint Method Data", "FPRTData")}
                    {this.state.ActiveTile === ETileAll && this.RenderRawEdit("Fingerprint Method Data", "")}
                    {this.state.TextError}
                    {this.RenderTile("Main Information", ImgTileMain, ETileMain, "TileAcc")}
                    {this.RenderTile("Policy Data", ImgTilePolicy, ETilePolicy, "TilePolicy")}
                    {this.RenderTile("Accounts Data", ImgTileAcc, ETileAcc, "TileAcc")}
                    {this.RenderTile("SSO Data", ImgTileSSO, ETileSSO, "TileSSO")}
                    {this.RenderTile("Push Data", ImgTilePush, ETilePush, "TilePush")}
                    {this.RenderTile("OTP Data", ImgTileOTP, ETileOTP, "TileOTP")}
                    {this.RenderTile("RFID Data", ImgTileRFID, ETileRFID, "TileRFID")}
                    {this.RenderTile("MAGS Data", ImgTileMAGS, ETileMAGS, "TileMAGS")}
                    {this.RenderTile("FIDO Data", ImgTileFIDO, ETileFIDO, "TileFIDO")}
                    {this.RenderTile("FPRT Data", ImgTileFPRT, ETileFPRT, "TileFPRT")}
                    {this.RenderTile("All Data", ImgTileMain, ETileAll, "TileAll")}
                    <br/><br/>
                    <center>
                        <Button fill onClick={() => this.OnResult(true)}>{global.StrSave}</Button>
                        <Button fill onClick={() => this.OnResult(false)}>{global.StrCancel}</Button>
                    </center>
                </span>
            </SweetAlert>
        );
    }

}

export default UsersViewEdit;
