//
//  @file AuthRoutes.js
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import LoginPage from "views/Pages/LoginPage.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import ActivatePage from "views/Pages/ActivatePage.jsx";

var AuthRoutes = [
    {
        collapse: true,
        path: "/pages",
        name: "Pages",
        state: "openPages",
        icon: "pe-7s-gift",
        views: [
            {
                path: "/login-page",
                layout: "/auth",
                name: "Login Page",
                mini: "LP",
                component: LoginPage
            },
            {
                path: "/register-page",
                layout: "/auth",
                name: "Register",
                mini: "RP",
                component: RegisterPage
            },
            {
                path: "/activate-page",
                layout: "/auth",
                name: "Activate",
                mini: "AC",
                component: ActivatePage
            }
        ]
    }
];

export default AuthRoutes;
