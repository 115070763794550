//
//  @file AdminSettings.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import { Grid, Row, Col, FormGroup, ControlLabel } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

import PromptProgress from "components/Prompt/PromptProgress.jsx";

import FormInputText from "components/FormInputs/FormInputText.jsx";

const EStateLoad = 0;
const EStateReady = 1;
const EStateError = 2;
const EStateCancel = 3;

class AdminSettings extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            State: EStateLoad,
            Prompt: null
        };

        this.AuthTheme = "";

        this.OnSave = this.OnSave.bind(this);

    }

    componentWillMount()
    {

        this.OnLoad();

    }

    OnLoad()
    {
        this.OnLoadSave(true);
    }

    OnSave()
    {
        this.OnLoadSave(false);
    }

    OnLoadSave(fLoad)
    {

        function OnRequestInitLoad(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrRetrievingDataPleaseWait);
            pPrompt.ServiceRequest = RapidaIDService.UserPolicyGet(pThis, function(pThis, eResult, pResponse) {
                if(eResult !== RapidaIDMainDefs.ERROR_SUCCESS)
                {
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + pResponse.Result);
                    pThis.setState({State: EStateError});
                }
                else
                {
                    pThis.AuthTheme = pResponse.AuthTheme;
                    pThis.setState({State: EStateReady, Prompt: null});
                }
            });
        }

        function OnRequestInitSave(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrSavingData);
            pPrompt.ServiceRequest = RapidaIDService.UserPolicySet(pThis, pThis.AuthTheme, "", function(pThis, eResult) {
                if(eResult !== RapidaIDMainDefs.ERROR_SUCCESS)
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
                else
                    pThis.setState({Prompt: null});
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={fLoad ? OnRequestInitLoad : OnRequestInitSave} OnCancel={() => this.setState({State: EStateCancel, Prompt: null})}/>});

    }

    render()
    {
        return (
          <div className="main-content">
            <Grid fluid>
            {this.state.Prompt}
              <Row>
                <Col md={12}>
                  <Card
                    title={global.StrSettings}
                    content={
                      <form>
                        <FormGroup>
                            <ControlLabel>
                                {global.StrAuthSequence} (ex1: unp;rfid,fido;push,otp or ex2: unp,rfid,fido,push,otp or deafult: unp,push,otp,rfid,mags,fido,fprt)
                            </ControlLabel>
                            <FormInputText
                                name="TokenData"
                                placeholder={global.StrAuthSequence + " (ex1: unp;rfid,fido;push,otp or ex2: unp,rfid,fido,push,otp or deafult: unp,push,otp,rfid,mags,fido,fprt)"}
                                defaultValue={this.AuthTheme}
                                autoFocus
                                autoComplete="off"
                                onChange={event => this.AuthTheme = event.target.value}
                                onKeyPress={event => { if(event.key === "Enter") { this.AuthTheme = event.target.value; this.OnSave(); event.preventDefault(); } }} 
                            />
                        </FormGroup>
                        <Button bsStyle="info" pullRight fill onClick={this.OnSave}>
                            Save
                        </Button>
                        <div className="clearfix" />
                      </form>
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        );
    }
}

export default AdminSettings;
