//
//  @file UserNavbar.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { Navbar } from "react-bootstrap";

import UserNavbarLinks from "./UserNavbarLinks.jsx";

import UserRoutes from "UserRoutes.js";

class UserNavbar extends Component
{

    GetRoutePath(pRoutes)
    {
        let sRoute = "";
        for(var iRoute = 0; iRoute < pRoutes.length; iRoute++)
        {
            if(pRoutes[iRoute].collapse)
            {
                sRoute = this.GetRoutePath(pRoutes[iRoute].views);
                if(sRoute !== "")
                    return pRoutes[iRoute].name + " > " + sRoute;
            }
            else if (window.location.href.indexOf(pRoutes[iRoute].layout + pRoutes[iRoute].path) !== -1)
                return pRoutes[iRoute].name;
        }
        return sRoute;
    }

    OnToggleSidebar(e)
    {
        document.documentElement.classList.toggle("nav-open");
    };

    render()
    {
        return (
            <Navbar fluid className={this.props.navbar ? "navbar-fixed" : ""}>
                <div className="navbar-minimize">
                    <button
                      id="minimizeSidebar"
                      className="btn btn-default btn-fill btn-round btn-icon"
                      onClick={this.props.handleMiniClick}
                    >
                        <i className="fa fa-ellipsis-v visible-on-sidebar-regular" />
                        <i className="fa fa-navicon visible-on-sidebar-mini" />
                    </button>
                </div>
                <Navbar.Header>
                    <Navbar.Brand>
                        {/* Here we create navbar brand, based on route name */}
                        {this.GetRoutePath(UserRoutes)}
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={this.OnToggleSidebar} />
                </Navbar.Header>
                {/* Here we import the links that appear in navbar */}
                {window.innerWidth > 992 ? (
                    <Navbar.Collapse>
                        <UserNavbarLinks />
                    </Navbar.Collapse>
                ) : null}
            </Navbar>
        );
    }

}

export default UserNavbar;
