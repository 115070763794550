//
//  @file EnrollFPRTFinger.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.07.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React from "react";

import ImgFingerActive from "assets/img/FingerActive.png";
import ImgFingerNormal from "assets/img/FingerNormal.png";
import ImgFingerEnrolled from "assets/img/FingerEnrolled.png";

class EnrollFPRTFinger extends React.Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            //IsEnrolled: this.props.EnrolledFingers[this.props.FingerID] !== -1
        }

        this.OnMouseOver = this.OnMouseOver.bind(this);
        this.OnMouseOut = this.OnMouseOut.bind(this);
        this.OnMouseClick = this.OnMouseClick.bind(this);

    }

    OnMouseOver(e)
    {
        e.currentTarget.src = ImgFingerActive;
        e.currentTarget.style.cursor = "pointer";
    }

    OnMouseOut(e)
    {
        e.currentTarget.src = this.IsEnrolled() ? ImgFingerEnrolled : ImgFingerNormal;
        e.currentTarget.style.cursor = "arrow";
    }

    OnMouseClick(e)
    {
        this.props.OnClick(this);
        //this.forceUpdate();
    }

    SetEnrolled(fEnrolled)
    {
        //this.setState({ IsEnrolled: fEnrolled });
        //this.props.EnrolledFingers[this.props.FingerID] !== -1
    }

    IsEnrolled()
    {
        return this.props.EnrolledFingers[this.props.FingerID] !== -1;//this.state.IsEnrolled;
    }

    render()
    {
        const { PosX, PosY } = this.props;
        let pImage = this.IsEnrolled() ? ImgFingerEnrolled : ImgFingerNormal
        this.FingerIndex = this.props.EnrolledFingers[this.props.FingerID];
        return (
            <div>
                <img
                    src={pImage} 
                    style={{ position: "absolute", left: PosX, top: PosY }} 
                    alt="Finger"
                    onMouseOver={ e => { this.OnMouseOver(e); } }
                    onMouseOut={ e => { this.OnMouseOut(e); } }
                    onClick={ e => { this.OnMouseClick(e); } }
                />
            </div>
        );
    }

}

export default EnrollFPRTFinger;
