//
//  @file AuthCardRFID.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import { FormGroup, ControlLabel } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import FormLoginInput from "components/FormInputs/FormLoginInput.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";

import ImgTile from "assets/img/TileRFID.png";

const EStateWaitCard = 0;
const EStateWaitPIN = 1;

class AuthCardRFID extends Component
{
    
    constructor(props)
    {

        super(props);

        this.state = {
            IsRequest: false,
            State: EStateWaitCard
        };

        this.OnSubmitClicked = this.OnSubmitClicked.bind(this);
        this.OnCancelClicked = this.OnCancelClicked.bind(this);
        this.OnRFIDCardChanged = this.OnRFIDCardChanged.bind(this);

        this.CardID = "";

    }

    componentDidMount()
    {
        console.log(this.props.Cards);
        if(this.props.Cards.length)
            this.OnRFIDCardChanged(this.props.Cards[0], true);
    }

    OnSubmitClicked(sPIN)
    {

        this.setState({ IsRequest: true });

        RapidaIDService.LoginCheckCard(this, this.props.AuthKey, this.props.AuthUser, this.CardID, sPIN, function (pThis, pResponse) {
            pThis.setState({ IsRequest: false });
            pThis.props.OnResult(pResponse.Result, pResponse);
        });

    }

    OnCancelClicked()
    {
        this.props.OnCancel();
    }

    OnRFIDCardChanged(sCardID, fPresent)
    {
        console.log("AuthCardRFID::OnRFIDCardChanged(" + sCardID + ", " + fPresent + ")");
        if(fPresent && sCardID !== "")
        {
            this.CardID = sCardID;
            this.setState({ State: EStateWaitPIN });
        }
        else
        {
            this.CardID = "";
            this.setState({ State: EStateWaitCard });
        }
    }

    render()
    {
        return (
            <div>
                <center><img src={ImgTile} alt="TileRFID" width="96" height="96"/></center>
                <br/>
                <center hidden={this.state.State !== EStateWaitCard}>{global.StrApplyYourCard}</center>
                <FormGroup hidden={this.state.State !== EStateWaitPIN}>
                    <ControlLabel>{global.StrPIN}</ControlLabel>
                    <FormLoginInput OnSubmit={this.OnSubmitClicked} placeholder={global.StrPIN} type="password" defaultValue=""/>
                </FormGroup>
                <br/>
                <center>
                    <Button bsStyle="info" fill wd onClick={this.OnCancelClicked.bind(this)}>
                        {global.StrCancel}
                    </Button>
                </center>
            </div>
        );
    }

}

export default AuthCardRFID;
