//
//  @file AuthCardFIDO.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import { FormGroup, ControlLabel } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import FormLoginInput from "components/FormInputs/FormLoginInput.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

import ImgTile from "assets/img/TileFIDO.png";

const EStateFetch = 1;
const EStateTouch = 2;
const EStateCheck = 3;
const EStatePIN = 4;
const EStateError = 5;

class AuthCardFIDO extends Component
{
    
    constructor(props)
    {

        super(props);

        this.state = {
            IsRequest: false,
            StatusText: null,
            State: EStateFetch
        };

        this.OnSubmitClicked = this.OnSubmitClicked.bind(this);
        this.OnCancelClicked = this.OnCancelClicked.bind(this);
        this.OnStartOverClicked = this.OnStartOverClicked.bind(this);
        this.OnResponseFIDOHostAuth = this.OnResponseFIDOHostAuth.bind(this);

        this.IsPINRequired = true;
        this.AuthItem = -1;
        this.HostResult = null;
        this.PrevState = -1;
        this.RefPIN = React.createRef();

        this.ServiceRequest = null;

    }

    componentDidMount()
    {
        this.OnFetchData();
    }

    componentWillUnmount()
    {
        if(this.ServiceRequest)
        {
            this.ServiceRequest = RapidaIDService.Abort(this.ServiceRequest);
            this.ServiceRequest = null;
        }
    }

    SetError(pError)
    {
        this.setState({StatusText: pError});
    }

    OnFetchData()
    {

        this.setState({ IsRequest: true, State: EStateFetch, StatusText: null });

        this.ServiceRequest = RapidaIDService.LoginFetchFIDO(this, this.props.AuthKey, this.props.AuthUser, function (pThis, pResponse) {
            pThis.ServiceRequest = null;
            if(pResponse.Result !== RapidaIDMainDefs.ERROR_SUCCESS)
            {
                let pError = (<center><small className="text-danger">{global.StrLoadDataFailedErrorSemiSpace}{pResponse.Result}.</small></center>);
                pThis.SetError(pError);
            }
            else
            {
                pThis.setState({ State: EStateTouch });
                pThis.props.Channel.RequestFIDOHostAuth(JSON.stringify(pResponse.Items));
            }
        });

    }

    OnCheckData(sPIN)
    {
        let eState = this.state.State;
        this.setState({ State: EStateCheck });
        this.ServiceRequest = RapidaIDService.LoginCheckFIDO(this, this.props.AuthKey, this.props.AuthUser, sPIN, this.AuthItem, this.HostResult, function (pThis, pResponse) {
            pThis.ServiceRequest = null;
            pThis.setState({ State: eState });
            if(pResponse.Result !== RapidaIDMainDefs.ERROR_SUCCESS)
            {
                let pError = (<center><small className="text-danger">Server verification failed, error: {pResponse.Result}.</small></center>);
                pThis.SetError(pError);
                pThis.setState({State: EStateError});
                return;
            }
            pThis.props.OnResult(pResponse.Result, pResponse);
        });
    }

    OnResponseFIDOHostAuth(eResult, iAuthItem, sHostResult)
    {
        console.log("AuthCardFIDO::OnResponseFIDOHostAuth(" + eResult + ", " + iAuthItem + ")");
        if(eResult !== RapidaIDMainDefs.ERROR_SUCCESS)
        {
            let pError = (<center><small className="text-danger">{global.StrHostVerifyFailedErrorSemiSpace}{eResult}.</small></center>);
            this.SetError(pError);
            return;
        }
        this.AuthItem = iAuthItem;
        this.HostResult = sHostResult;
        if(this.IsPINRequired)
            this.setState({ State: EStatePIN });
        else
            this.OnCheckData("");
    }

    OnSubmitClicked(sPIN)
    {

        this.OnCheckData(sPIN);

    }

    OnCancelClicked()
    {
        this.props.OnCancel();
    }

    OnStartOverClicked()
    {
        this.RefPIN.current.ResetValue();
        this.OnFetchData();
    }

    render()
    {
        return (
            <div>
                <center><img src={ImgTile} alt="TileFIDO" width="96" height="96"/></center>
                <br/>
                <FormGroup>
                    <ControlLabel>PIN</ControlLabel>
                    <FormLoginInput ref={this.RefPIN} OnSubmit={this.OnSubmitClicked} placeholder="PIN" type="password" defaultValue="" disabled={this.state.State !== EStatePIN}/>
                </FormGroup>
                {!this.state.StatusText && this.state.State === EStateFetch && <center>{global.StrRetrievingDataPleaseWait}</center>}
                {!this.state.StatusText && this.state.State === EStateTouch && <center>{global.StrPleaseCheckFIDOToken}</center>}
                {!this.state.StatusText && this.state.State === EStateCheck && <center>{global.StrValidatingDataPleaseWait}</center>}
                {this.state.StatusText}
                <br/>
                <center>
                    {this.state.StatusText && <Button bsStyle="info" fill onClick={this.OnStartOverClicked.bind(this)}>{global.StrStartOver}</Button>}
                    &nbsp;<Button bsStyle="info" fill onClick={this.OnCancelClicked.bind(this)}>{global.StrCancel}</Button>
                </center>
            </div>
        );
    }

}

export default AuthCardFIDO;
