//
//  @file PromptProgress.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import SweetAlert from "react-bootstrap-sweetalert";

import Button from "components/CustomButton/CustomButton.jsx";

class PromptProgress extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            StatusText: global.StrPleaseWaitLoading
        };

        this.OnResult = this.OnResult.bind(this);

    }

    componentDidMount()
    {
        if(this.props.OnInit !== undefined)
            this.props.OnInit(this.props.Parent, this)
    }

    componentWillUnmount()
    {
        if(this.props.OnDone !== undefined)
            this.props.OnDone(this.props.Parent, this)
    }

    OnResult(fResult)
    {
        //console.log("PromptProgress::OnResult()");
        if(!fResult)
        {
            this.SetStatusText(global.StrCancelling);
            if(this.ServiceRequest !== undefined && this.ServiceRequest)
            {
                this.ServiceRequest.abort();
                this.ServiceRequest = null;
            }
            this.props.OnCancel(this.props.Parent, this)
        }
    }

    SetStatusText(sText)
    {
        this.setState({StatusText: sText});
    }

    render()
    {
        const { AllowCancel } = this.props;
        return (
            <SweetAlert
                style={{ display: "block" }}
                title={""}
                openAnim={false}
                showConfirm={false}
                showCancel={false}
                closeOnClickOutside={false}
                onConfirm={() => this.OnResult(true)}
                onCancel={() => this.OnResult(false)}
            >
                <span style={{ fontSize: '14px', textAlign: 'left' }}>
                    <i className="fa fa-spin fa-spinner"/>
                    {this.state.StatusText}
                </span>
                <br/>
                <br/>
                {AllowCancel && <Button fill onClick={e => this.OnResult(false)}>{global.StrCancel}</Button>}
            </SweetAlert>
        );
    }

}

export default PromptProgress;
