//
//  @file EnrollOTPStep3b.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React from "react";

import Button from "components/CustomButton/CustomButton.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

const EStateUpdate = 0;
const EStateError = 1;
const EStateSuccess = 2;

class EnrollOTPStep3b extends React.Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            State: EStateUpdate,
            TextStatus: global.StrPleaseWaitUpdatingInfo
        };

        this.ServiceRequest = null;

        this.OnCancel = this.OnCancel.bind(this);
        this.OnStartOver = this.OnStartOver.bind(this);
        this.OnContinue = this.OnContinue.bind(this);

    }

    componentDidMount()
    {
        
        this.OnStartOver();

    }

    componentWillUnmount()
    {
        this.ServiceRequest = RapidaIDService.Abort(this.ServiceRequest);
    }

    isValidated()
    {
        return false;
    }

    OnCancel()
    {
        this.props.OnResult(true);
    }

    OnStartOver()
    {

        this.setState({
            State: EStateUpdate,
            TextStatus: global.StrPleaseWaitUpdatingInfo
        });

        var pData = this.props.TokenInfo;
        this.ServiceRequest = RapidaIDService.UserOTPSet(this, pData.UUID, pData.Name, pData.Data, "", function(pThis, eResult) {
            if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                pThis.setState({State: EStateSuccess, TextStatus: global.StrInformationUpdated});
            else
                pThis.setState({State: EStateError, TextStatus: <div className="text-danger">{global.StrFailedToUpdateInfo}({global.StrErrorSemiSpace}{eResult}).</div>});
        });

    }

    OnContinue()
    {
        this.props.OnResult(true);
    }

    render()
    {
        //<Row noGutters={true}>
        return (
            <div className="wizard-step">
                <br/>
                <br/>
                <br/>
                <br/>
                <center>{this.state.TextStatus}</center>
                <br/>
                <br/>
                <br/>
                <center>
                    {this.state.State === EStateError && <Button fill onClick={this.OnCancel}>{global.StrCancel}</Button>}
                    {/*this.state.State === EStateError && <Button fill onClick={this.OnStartOver}>Start Over</Button>*/}
                    {this.state.State === EStateSuccess && <Button fill onClick={this.OnContinue}>{global.StrFinish}</Button>}
                </center>
            </div>
        );
    }

}

export default EnrollOTPStep3b;
