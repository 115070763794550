//
//  @file AdminNavbarLinks.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import {
  //Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  //FormGroup,
  //FormControl,
  //InputGroup
} from "react-bootstrap";

import RapidaIDSession from "scripts/RapidaIDSession.jsx";

class HeaderLinks extends Component
{
    render()
    {
        return (
            <div>
                <Nav pullRight>
                    {RapidaIDSession.IsUserAdmin() === true && <NavItem eventKey={3} href="#/user/activity"><i className="pe-7s-user"/></NavItem>}
                    <NavDropdown
                        eventKey={4}
                        title={
                            <div>
                                <i className="fa fa-list" />
                                <p className="hidden-md hidden-lg">
                                    {global.StrMore}
                                    <b className="caret" />
                                </p>
                            </div>
                        }
                        noCaret
                        id="basic-nav-dropdown-3"
                        bsClass="dropdown-with-icons dropdown"
                    >
                        <MenuItem eventKey={4.1} href="#/admin/help">
                            <i className="pe-7s-help1" /> {global.StrHelpCenter}
                        </MenuItem>
                        <MenuItem eventKey={4.2} href="#/admin/settings">
                            <i className="pe-7s-tools" /> {global.StrSettings}
                        </MenuItem>
                        <MenuItem divider />
                        <MenuItem eventKey={4.3} href="#/auth/login-page">
                            <div className="text-danger">
                                <i className="pe-7s-close-circle" /> {global.StrLogout}
                            </div>
                        </MenuItem>
                    </NavDropdown>
                </Nav>
            </div>
        );
    }

}

export default HeaderLinks;

