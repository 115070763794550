//
//  @file Activity.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";

import ReactTable from "react-table";

import Card from "components/Card/Card.jsx";
import StatsCard from "components/Card/StatsCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import PromptProgress from "components/Prompt/PromptProgress.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

class AdminActivity extends Component
{
//https://codesandbox.io/s/k9n3y82wov?file=/index.js
    constructor(props)
    {

        super(props);

        this.state = {
            TableData: [],
            StatDisk: 0,
            StatApps: 0,
            StatUsers: 0,
            StatHosts: 0,
            Prompt: null
        };

    }

    componentWillMount()
    {

        this.OnDataFetch(0, 100);

    }

    OnDataPrepare(pData)
    {
        for(let iItem = 0; iItem < pData.length; iItem++)
        {
            pData[iItem].EventType = RapidaIDMainDefs.LogonEventToString(pData[iItem].EventType);
            pData[iItem].MethodID = RapidaIDMainDefs.AuthMethodToString(pData[iItem].MethodID);
            pData[iItem].Actions = (
                <div className="actions-right">
                    <Button onClick={() => {this.OnItemInfo(iItem);}} bsStyle="info" simple icon>
                        <i className="fa fa-edit" />
                    </Button>{" "}
                </div>
            );
        }
        return pData;
    }

    OnDataFetch(iRecordPos, nRecordCount)
    {

        function OnRequestInit(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrRetrievingDataPleaseWait);
            pPrompt.ServiceRequest = RapidaIDService.AdminActivityGet(pThis, iRecordPos, nRecordCount, function(pThis, eResult, pData, nStatDisk, nStatApps, nStatUsers, nStatHosts) {
                if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                    pThis.setState({TableData: pThis.OnDataPrepare(pData), StatDisk: nStatDisk, StatApps: nStatApps, StatUsers: nStatUsers, StatHosts: nStatHosts, Prompt: null});
                else
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={OnRequestInit} OnCancel={() => this.setState({Prompt: null})}/>});

    }

    OnItemInfo(iItemRow)
    {
        let pItem = this.state.TableData[iItemRow];
        let sItem = "";
        sItem += "RecordID: " + pItem.RecordID + "\r\n";
        sItem += "EventType: " + pItem.EventType + "\r\n";
        sItem += "TimeStamp: " + pItem.TimeStamp + "\r\n";
        sItem += "UserInfo: " + pItem.UserInfo + "\r\n";
        sItem += "UserUUID: " + pItem.UserUUID + "\r\n";
        sItem += "TargetHost: " + pItem.TargetHost + "\r\n";
        sItem += "IPAddress: " + pItem.IPAddress + "\r\n";
        sItem += "MethodID: " + pItem.MethodID + "\r\n";
        sItem += "Result: " + pItem.Result + "\r\n";
        sItem += "ExtraInfo: " + pItem.ExtraInfo;
        alert(sItem);
    }

    render()
    {
        return (
            <div className="main-content">
                <Grid fluid>
                {this.state.Prompt}
                    <Row>
                      <Col lg={3} sm={6}>
                        <StatsCard bigIcon={<i className="pe-7s-server text-warning" />} statsText={global.StrDiskSpace} statsValue={this.state.StatDisk} statsIcon="" statsIconText="&nbsp;"/>
                      </Col>
                      <Col lg={3} sm={6}>
                        <StatsCard bigIcon={<i className="pe-7s-browser text-success" />} statsText={global.StrApplications} statsValue={this.state.StatApps} statsIcon="" statsIconText="&nbsp;"/>
                      </Col>
                      <Col lg={3} sm={6}>
                        <StatsCard bigIcon={<i className="pe-7s-users text-danger" />} statsText={global.StrUsersInDB} statsValue={this.state.StatUsers} statsIcon="" statsIconText="&nbsp;"/>
                      </Col>
                      <Col lg={3} sm={6}>
                        <StatsCard bigIcon={<i className="fa pe-7s-monitor text-info" />} statsText={global.StrHostsInUse} statsValue={this.state.StatHosts} statsIcon="" statsIconText="&nbsp;"/>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Card
                            category={global.StrActivityHistory}
                            content={
                                <ReactTable
                                    data={this.state.TableData}
                                    filterable
                                    columns={[
                                        {
                                            Header: global.StrTimeStamp,
                                            accessor: "TimeStamp",
                                            width: 150
                                        },
                                        {
                                            Header: global.StrUserInfo,
                                            accessor: "UserInfo"
                                        },
                                        {
                                            Header: global.StrType,
                                            accessor: "EventType",
                                            width: 125
                                        },
                                        {
                                            Header: global.StrMethod,
                                            accessor: "MethodID",
                                            width: 125
                                        },
                                        {
                                            Header: global.StrTarget,
                                            accessor: "TargetHost"
                                        },
                                        {
                                            Header: global.StrIPAddress,
                                            accessor: "IPAddress",
                                            width: 125
                                        },
                                        {
                                            Header: global.StrActions,
                                            accessor: "Actions",
                                            width: 75,
                                            sortable: false,
                                            filterable: false
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                />
                            }
                        />
                      </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

}

export default AdminActivity;
