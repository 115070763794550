//
//  @file HelpCenter.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";

class HelpCenter extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
        };

    }

    render()
    {
        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            Help Center
                            <br/>
                            <br/>
                        </Col>
                    </Row>
                    <hr/>
                </Grid>
            </div>
        );
    }

}

export default HelpCenter;
