//
//  @file UsersView.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";

import ReactTable from "react-table";

import SweetAlert from "react-bootstrap-sweetalert";

import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CheckboxOwnerData.jsx";

import PromptTextArea from "components/Prompt/PromptTextArea.jsx";
import PromptProgress from "components/Prompt/PromptProgress.jsx";

import UsersViewEdit from "views/Admin/UsersViewEdit.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

class UsersView extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            TableData: [],
            Prompt: null
        }

        this.OnToggleRow = this.OnToggleRow.bind(this);
        this.IsCheckedRow = this.IsCheckedRow.bind(this);
        this.OnItemChange = this.OnItemChange.bind(this);
        this.OnItemDelete = this.OnItemDelete.bind(this);
        this.OnAssignPolicy = this.OnAssignPolicy.bind(this);

    }

    componentWillMount()
    {
        console.log(process.env);
        this.OnDataFetch(0, 100);
    }

    OnDataPrepare(pData)
    {
        for(let iItem = 0; iItem < pData.length; iItem++)
        {
            let pValue = pData[iItem];
            pData[iItem] = { };
            pData[iItem].Value = pValue;
            pData[iItem].RowIndex = iItem;
            pData[iItem].IsChecked = false;
            pData[iItem].CUUID = pValue.UUID;
            pData[iItem].Actions = (
                <div className="actions-right">
                    <Button onClick={() => {this.OnItemChange(iItem);}} bsStyle="info" simple icon>
                        <i className="fa fa-edit" />
                    </Button>{" "}
                    <Button onClick={() => {this.OnItemDelete(iItem);}} bsStyle="danger" simple icon>
                        <i className="fa fa-times" />
                    </Button>{" "}
                </div>
            );
        }
        return pData;
    }

    OnDataFetch(iRecordPos, nRecordCount)
    {

        function OnRequestInit(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrRetrievingDataPleaseWait);
            pPrompt.ServiceRequest = RapidaIDService.AdminUsersGet(pThis, iRecordPos, nRecordCount, function(pThis, eResult, pData) {
                if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                    pThis.setState({TableData: pThis.OnDataPrepare(pData), Prompt: null});
                else
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={OnRequestInit} OnCancel={() => this.setState({Prompt: null})}/>});

    }

    OnDataDelete(pRows, pItems)
    {

        function OnRequestInit(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrPleaseWaitUpdatingInfo);
            pPrompt.ServiceRequest = RapidaIDService.AdminUsersDel(pThis, 0, 100, pItems, function(pThis, eResult, pData) {
                if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                    pThis.setState({TableData: pThis.OnDataPrepare(pData), Prompt: null});
                else
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={OnRequestInit} OnCancel={() => this.setState({Prompt: null})}/>});

    }

    OnDataUpdate(pItem)
    {

        function OnRequestInit(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrPleaseWaitUpdatingInfo);
            pPrompt.ServiceRequest = RapidaIDService.AdminUsersSet(pThis, 0, 100, pItem.CUUID, pItem.Value, function(pThis, eResult, pData) {
                if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                    pThis.setState({TableData: pThis.OnDataPrepare(pData), Prompt: null});
                else
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={OnRequestInit} OnCancel={() => this.setState({Prompt: null})}/>});

    }

    OnDataAssign(pUUID, sPolicyData)
    {

        function OnRequestInit(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrAssigningPolicy);
            pPrompt.ServiceRequest = RapidaIDService.AdminUsersSetPolicy(pThis, 0, 100, pUUID, sPolicyData, function(pThis, eResult, pData) {
                if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                    pThis.setState({TableData: pThis.OnDataPrepare(pData), Prompt: null});
                else
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={OnRequestInit} OnCancel={() => this.setState({Prompt: null})}/>});

    }

    OnToggleRow(pRow)
    {
        if(!pRow)
        {
            let pData = this.state.TableData, iItem = 0;
            let fChecked = !this.IsCheckedRow(null);
            for(iItem = 0; iItem < pData.length; iItem++)
                pData[iItem].IsChecked = fChecked;
        }
        else
            pRow.original.IsChecked = !pRow.original.IsChecked;
        this.forceUpdate();
    }

    IsCheckedRow(pRow)
    {
        if(!pRow)
        {
            let pData = this.state.TableData, iItem = 0;
            for(iItem = 0; iItem < pData.length && pData[iItem].IsChecked; iItem++);
            return iItem === pData.length && pData.length;
        }
        return pRow.original.IsChecked;
    }

    OnItemChange(iRowIndex)
    {
        let pItemInfo = this.state.TableData[iRowIndex];
        function OnResult(pThis, pPrompt, fResult) {
            pThis.setState({Prompt: null});
            if(fResult)
                pThis.OnDataUpdate(pItemInfo);
        }
        console.log(JSON.stringify(pItemInfo));
        this.setState({Prompt: <UsersViewEdit Parent={this} OnResult={OnResult} ItemInfo={pItemInfo.Value}/>});
    }

    OnItemDelete(iRowIndex)
    {
        let pData = this.state.TableData, pCheckedIndex = [], pCheckedUUID = [];
        for(let iItem = 0; iItem < pData.length; iItem++)
        {
            if(pData[iItem].IsChecked)
            {
                pCheckedIndex.push(iItem);
                pCheckedUUID.push(pData[iItem].CUUID);
            }
        }
        if(!pCheckedIndex.length)
        {
            pCheckedIndex.push(iRowIndex);
            console.log(iRowIndex + ", " + pData[iRowIndex]);
            pCheckedUUID.push(pData[iRowIndex].CUUID);
        }
        let sMessage = global.StrDoYouWantToRemove + " " + pCheckedIndex.length + " " + global.StrItems + "?";
        function OnDeleteRows(pThis, fDelete)
        {
            pThis.setState({Prompt: null});
            if(fDelete)
                pThis.OnDataDelete(pCheckedIndex, pCheckedUUID);
        }
        this.setState({
            Prompt: (
                <SweetAlert
                    style={{ display: "block" }}
                    title={global.StrUserActionRequired}
                    onConfirm={() => OnDeleteRows(this, true)}
                    onCancel={() => OnDeleteRows(this, false)}
                    confirmBtnBsStyle="info"
                    confirmBtnText={global.StrYes}
                    cancelBtnText={global.StrNo}
                    showCancel
                >
                    {sMessage}
                </SweetAlert>
            )
        });
    }

    OnAssignPolicy()
    {
        let pData = this.state.TableData, pItemsInfo = [], pItemsUUID = [];
        for(let iRowIndex = 0; iRowIndex < pData.length; iRowIndex++)
        {
            if(pData[iRowIndex].IsChecked)
            {
                pItemsInfo.push(pData[iRowIndex]);
                pItemsUUID.push(pData[iRowIndex].CUUID);
            }
        }
        if(!pItemsInfo.length)
            return;
        let pItemInfo = pItemsInfo[0];
        function OnResult(pThis, pPrompt, fResult) {
            pThis.setState({Prompt: null});
            if(fResult)
                pThis.OnDataAssign(pItemsUUID, pItemInfo.PolicyData);
        }
        this.setState({Prompt: <PromptTextArea Parent={this} OnResult={OnResult} ItemsInfo={pItemsInfo} ItemInfo={pItemInfo} FieldName="PolicyData"/>});
    }

    render()
    {
        return (
            <div className="main-content">
                <Grid fluid>
                {this.state.Prompt}
                    <Row>
                        <Col md={12}>
                            {global.StrUsersCanBeChangedHere}
                            <br/>
                            <br/>
                            <Button fill onClick={this.OnAssignPolicy}>{global.StrAssignPolicy}</Button>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                      <Col md={12}>
                        <Card
                            category={global.StrApplications + ":"}
                            content={
                                <ReactTable
                                    data={this.state.TableData}
                                    filterable
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: (pRowInfo) => {
                                                return (<Checkbox checked={this.IsCheckedRow(pRowInfo)} onChange={() => this.OnToggleRow(pRowInfo)}/>);
                                            },
                                            Header: (sTitle) => {
                                                return (<Checkbox checked={this.IsCheckedRow(null)} onChange={() => this.OnToggleRow(null)}/>);
                                            },
                                            filterable: false,
                                            sortable: false,
                                            width: 40
                                        },
                                        {
                                            Header: global.StrUsername,
                                            accessor: "Value.UserInfo",
                                            width: 200
                                        },
                                        {
                                            Header: global.StrRoles,
                                            accessor: "Value.UserRole",
                                            width: 125
                                        },
                                        {
                                            Header: global.StrPolicyID,
                                            accessor: "Value.PolicyID"
                                        },
                                        {
                                            Header: global.StrActions,
                                            accessor: "Actions",
                                            width: 125,
                                            sortable: false,
                                            filterable: false
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                />
                            }
                        />
                      </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

}

export default UsersView;
