//
//  @file WebServiceBase.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import RapidaIDUtils from "./RapidaIDUtils.jsx"

class WebServiceBase
{

    static ReadStateUnsent = 0;
    static ReadStateOpened = 1;
    static ReadStateHeaders = 2;
    static ReadStateLoading = 3;
    static ReadStateDone = 4;
    
    static HTTPStatusOK = 200;

    static CallPost(sURL, pData, OnResponse)
    {
        var pRequest = new XMLHttpRequest();
        pRequest.open("POST", sURL, true);
        pRequest.setRequestHeader("Content-Type", "application/json");
        pRequest.onreadystatechange = function ()
        {
            OnResponse(pRequest.readyState === pRequest.DONE, pRequest.status === 200, pRequest.responseText, pRequest.readyState, pRequest.status);
        }; 
        var sData = JSON.stringify(pData);
        pRequest.send(sData);
        return pRequest;
    }
    
    static CallPostJSON(sURL, pData, OnResponse)
    {
        console.log("CallPostJSON: " + pData);
        return WebServiceBase.CallPost(sURL, pData, function(fDone, fSuccess, sResponse, nState, nStatus) {
            console.log("InvokeServer2() -> response: " + fDone + ", " + fSuccess + ", " + sResponse + ", " + nState + ", " + nStatus);
            //console.log("CallPostJSON result: " + sResponse);
            if(fDone)
                OnResponse(fSuccess  ? RapidaIDUtils.JSONParse(sResponse) : null);
        });
    }
    
};

export default WebServiceBase;
