//
//  @file UserSettings.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import { Grid, Row, Col, FormGroup, ControlLabel } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import RapidaIDChannel from "scripts/RapidaIDChannel.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";
import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDSession from "scripts/RapidaIDSession.jsx";
import RapidaIDUtils from "scripts/RapidaIDUtils.jsx";

import PromptProgress from "components/Prompt/PromptProgress.jsx";

import FormInputText from "components/FormInputs/FormInputText.jsx";

import Select from "react-select";

const EStateLoad = 0;
const EStateReady = 1;
const EStateError = 2;
const EStateCancel = 3;

class UserSettings extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            State: EStateLoad,
            Prompt: null,
            PasswordError: null,
            RenderRedirect: null,
            LanguageID: global.LangListEN.indexOf(global.LangName)
        };

        this.LanguageOptions = [
            { label: "Language", isDisabled: true },
        ];

        for(let iLang = 0; iLang < global.LangListNV.length; iLang++)
            this.LanguageOptions.push({ value: iLang, label: global.LangListNV[iLang] });

        this.AuthTheme = "";
        this.UserPass = "";

        this.OnSave = this.OnSave.bind(this);
        this.OnSetPassword = this.OnSetPassword.bind(this);
        this.OnCheckPassword = this.OnCheckPassword.bind(this);
        this.OnLangSelected = this.OnLangSelected.bind(this);

    }

    componentWillMount()
    {

        this.OnLoad();

    }

    OnLoad()
    {
        this.OnLoadSave(true);
    }

    OnSave()
    {
        this.OnLoadSave(false);
    }

    OnSetPassword()
    {
        if(!RapidaIDUtils.IsValidPassword(this.UserPass))
            return;
        this.OnSave();
    }

    OnCheckPassword()
    {
        var pError = this.UserPass !== "" && !RapidaIDUtils.IsValidPassword(this.UserPass) ? (<small className="text-danger">{global.StrPasswordShouldBeFormat}</small>) : null;
        if(this.state.PasswordError !== pError)
            this.setState({ PasswordError: pError });
    }

    OnLoadSave(fLoad)
    {

        function OnRequestInitLoad(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrRetrievingDataPleaseWait);
            pPrompt.ServiceRequest = RapidaIDService.UserPolicyGet(pThis, function(pThis, eResult, pResponse) {
                if(eResult !== RapidaIDMainDefs.ERROR_SUCCESS)
                {
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + pResponse.Result);
                    pThis.setState({State: EStateError});
                }
                else
                {
                    pThis.AuthTheme = pResponse.AuthTheme;
                    pThis.setState({State: EStateReady, Prompt: null});
                }
            });
        }

        function OnRequestInitSave(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrPleaseWaitUpdatingInfo);
            pPrompt.ServiceRequest = RapidaIDService.UserPolicySet(pThis, pThis.AuthTheme, pThis.UserPass, global.LangName, function(pThis, eResult) {
                if(eResult !== RapidaIDMainDefs.ERROR_SUCCESS)
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
                else
                {
                    RapidaIDChannel.PerformSync(RapidaIDSession.GetUserInfo());
                    pThis.setState({Prompt: null});
                }
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={fLoad ? OnRequestInitLoad : OnRequestInitSave} OnCancel={() => this.setState({State: EStateCancel, Prompt: null})}/>});

    }

    OnLangSelected(pValue)
    {

        this.setState({ LanguageID: pValue.value });

        global.SetLang(global.LangListEN[pValue.value]);

        //window.location = "http://localhost:3000/?#/user/activity";
        RapidaIDUtils.ForceUpdateComponents();

    }

    render()
    {
        return (
          <div className="main-content">
            <Grid fluid>
            {this.state.Prompt}
              <Row>
                <Col md={12}>
                  <Card
                    title={global.StrSettings}
                    content={
                      <form>
                        <FormGroup>
                            <ControlLabel>
                                {global.StrLanguage}
                            </ControlLabel>
                            <Row>
                                <Col xs={3}>
                                    <Select className="react-select" classNamePrefix="react-select" name="Language" placeholder="Language"
                                        value={this.LanguageOptions[this.state.LanguageID + 1]}
                                        onChange={value => this.OnLangSelected(value)}
                                        options={this.LanguageOptions}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                {global.StrAuthSequence} (ex1: unp;rfid,fido;push,otp or ex2: unp,rfid,fido,push,otp or deafult: unp,push,otp,rfid,mags,fido,fprt)
                            </ControlLabel>
                            <FormInputText
                                name="TokenData"
                                placeholder={global.StrAuthSequence + " (ex1: unp;rfid,fido;push,otp or ex2: unp,rfid,fido,push,otp or deafult: unp,push,otp,rfid,mags,fido,fprt)"}
                                defaultValue={this.AuthTheme}
                                autoFocus
                                onChange={event => this.AuthTheme = event.target.value}
                                onKeyPress={event => { if(event.key === "Enter") { this.AuthTheme = event.target.value; this.OnSave(); event.preventDefault(); } }} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                {global.StrUserPassword}
                            </ControlLabel>
                            <FormInputText
                                type="Password"
                                placeholder={global.StrPassword}
                                defaultValue=""
                                onChange={event => { this.UserPass = event.target.value; this.OnCheckPassword(); } }
                                onKeyPress={event => { if(event.key === "Enter") { this.UserPass = event.target.value; this.OnSetPassword(); event.preventDefault(); } }} 
                            />
                            {this.state.PasswordError}
                        </FormGroup>
                        <Button bsStyle="info" pullRight fill onClick={this.OnSave}>
                            {global.StrSave}
                        </Button>
                        <div className="clearfix" />
                      </form>
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        );
    }
}

export default UserSettings;
