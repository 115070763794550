//
//  @file Auth.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Footer from "components/Footer/Footer.jsx";
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";

import AuthRoutes from "AuthRoutes.js";

import RapidaIDUtils from "scripts/RapidaIDUtils.jsx";

import bgImage from "assets/img/full-screen-image-3.jpg";

class AuthLayout extends Component
{

    constructor(props)
    {

        super(props);
        
        this.OnDocumentLoad = this.OnDocumentLoad.bind(this);

    }

    componentWillMount()
    {
        window.addEventListener('load', this.OnDocumentLoad);
        if (document.documentElement.className.indexOf("nav-open") !== -1)
            document.documentElement.classList.toggle("nav-open");
        RapidaIDUtils.AddComponent(this);
    }

    componentWillUnmount()
    {
        RapidaIDUtils.DelComponent(this);
    }

    OnDocumentLoad()
    {
        console.log("AuthLayout::OnDocumentLoad()");
    }

    getPageClass()
    {
        var pageClass = "";
        switch (this.props.location.pathname)
        {
            case "/auth/login-page":
                pageClass = " login-page";
                break;
            case "/auth/register-page":
                pageClass = " register-page";
                break;
            case "/auth/activate-page":
                pageClass = " login-page";
                break;
            case "/auth/lock-screen-page":
                pageClass = " lock-page";
                break;
            default:
                pageClass = "";
                break;
        }
        return pageClass;
    }

    getRoutes = routes =>
    {
        return routes.map((prop, key) =>
        {
            if (prop.collapse)
                return this.getRoutes(prop.views);
            if (prop.layout === "/auth")
            {
                return (
                    <Route
                      path={prop.layout + prop.path}
                      component={prop.component}
                      key={key}
                    />
                );
            }
            return null;
        });
    };

    render()
    {
      return (
        <div>
          <AuthNavbar />
          <div className="wrapper wrapper-full-page">
            <div
              className={"full-page" + this.getPageClass()}
              data-color="black"
              data-image={bgImage}
            >
              <div className="content">
                <Switch>{this.getRoutes(AuthRoutes)}</Switch>
              </div>
              <Footer transparent />
              <div
                className="full-page-background"
                style={{ backgroundImage: "url(" + bgImage + ")" }}
              />
            </div>
          </div>
        </div>
      );
    }

}

export default AuthLayout;
