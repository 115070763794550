//
//  @file FormInputText.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";

export class FormInputText extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
        };

        this.Ref = React.createRef();

        //this.GetText = this.GetText.bind(this);

    }

    GetText()
    {
        return this.Ref.value;
    }

    SetText(sText)
    {
        this.Ref.value = sText;
    }

    render() {
        const { placeholder, type, tooltip, ...rest } = this.props;
        const pToolTip = (<Tooltip style={{zIndex: 10000}} id={placeholder}>{placeholder}</Tooltip>);
        const pInput = (
            <FormControl 
                inputRef={(input) => this.Ref = input} 
                type={type !== undefined ? type : "text"}
                title={placeholder}
                placeholder={placeholder}
                autoCorrect={'off'}
                autoComplete={'off'}
                spellCheck={false} 
                {...rest}
            />
        );
        if(tooltip)
            return (<OverlayTrigger placement="top" overlay={pToolTip}>{pInput}</OverlayTrigger>);
        return pInput;
    }

}

export default FormInputText;
