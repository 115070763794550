//
//  @file EnrollOTP.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";

import EnrollBase from "views/User/EnrollBase.jsx";

import EnrollOTPStep1 from "views/User/EnrollOTPStep1.jsx";
import EnrollOTPStep2 from "views/User/EnrollOTPStep2.jsx";
import EnrollOTPStep3a from "views/User/EnrollOTPStep3a.jsx";
import EnrollOTPStep3b from "views/User/EnrollOTPStep3b.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDSession from "scripts/RapidaIDSession.jsx";
import RapidaIDUtils from "scripts/RapidaIDUtils.jsx";

import ImgTile from "assets/img/TileOTP.png";

class EnrollOTP extends Component
{

    constructor(props)
    {

        super(props);

        this.TokenInfo = {
            UUID: "",
            Name: "",
            Data: "",
            PIN: "",
            CPIN: ""
        };

        this.TokenData = [];

        this.OnPrepareEdit = this.OnPrepareEdit.bind(this);
        this.OnTokenEnrolled = this.OnTokenEnrolled.bind(this);
        this.OnTokenRemoved = this.OnTokenRemoved.bind(this);

    }

    componentDidMount()
    {

        this.ReloadTokenData();

    }

    ReloadTokenData()
    {

        this.TokenData.splice(0, this.TokenData.length);

        this.TokenData.push({ UUID: "", Name: "Default", Default: true });

        var pData = RapidaIDSession.GetOTPData();
        for(var iItem = 0; iItem < pData.length; iItem++)
            this.TokenData.push({ UUID: pData[iItem].UUID, Name: pData[iItem].Name });

        this.forceUpdate();

        console.log(pData);

    }

    OnPrepareEdit(iItem, OnResult)
    {

        this.TokenInfo.UUID = iItem === -1 ? "NEW" : this.TokenData[iItem].UUID;
        this.TokenInfo.Name = iItem === -1 ? "" : this.TokenData[iItem].Name;
        this.TokenInfo.Default = iItem === -1 ? false : this.TokenData[iItem].Default;
        this.TokenInfo.Data = RapidaIDUtils.OTPCreateSecret();
        this.TokenInfo.PIN = "";
        this.TokenInfo.CPIN = "";

        var pSteps = [
            { name: global.StrName, component: <EnrollOTPStep1 OnResult={OnResult} TokenInfo={this.TokenInfo}/> },
            { name: global.StrSecret, component: <EnrollOTPStep2 OnResult={OnResult} TokenInfo={this.TokenInfo}/> }
        ];

        if(!iItem)
            pSteps.push({ name: global.StrPIN, component: <EnrollOTPStep3a OnResult={OnResult} TokenInfo={this.TokenInfo}/> });
        else
            pSteps.push({ name: global.StrFinish, component: <EnrollOTPStep3b OnResult={OnResult} TokenInfo={this.TokenInfo}/> });

        return pSteps;
    }

    OnTokenEnrolled(iItem)
    {

        console.log("EnrollOTP::OnTokenEnrolled()");

        this.ReloadTokenData();
        /*if(iItem === -1)
            this.TokenData.push({ UUID: this.TokenInfo.UUID, Name: this.TokenInfo.Name });
        else
        {
            this.TokenData[iItem].UUID = this.TokenInfo.UUID;
            this.TokenData[iItem].Name = this.TokenInfo.Name;
        }*/

    }

    OnTokenRemoved(iItem)
    {
        console.log("EnrollOTP::OnTokenRemoved(" + iItem + ")");
        RapidaIDService.UserOTPDel(this, this.TokenData[iItem].UUID, function(pThis, eResult) {
            pThis.ReloadTokenData();
            //if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                //pThis.TokenData.splice(iItem, 1);
        });
    }

    render()
    {
        return (
            <EnrollBase 
                ImgTile={ImgTile} 
                EnrollTitle={global.StrEnrollNewToken} 
                BriefDesc={global.StrHereEnrollUpdateDeleteToken}
                TokenData={this.TokenData}
                TokenInfo={this.TokenInfo}
                OnPrepareEdit={this.OnPrepareEdit}
                OnTokenEnrolled={this.OnTokenEnrolled}
                OnTokenRemoved={this.OnTokenRemoved}
            />
        );
    }

}

export default EnrollOTP;
