//
//  @file EnrollCardStep2.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React from "react";

import Button from "components/CustomButton/CustomButton.jsx";

import RapidaIDChannel from "scripts/RapidaIDChannel.jsx";

const EStateWaitCard = 0;
const EStateError = 1;
//const EStateFinished = 2;

class EnrollCardStep2 extends React.Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            TextStatus: global.StrConnectingDeviceWait,
            State: EStateWaitCard
        };

        this.OnCancel = this.OnCancel.bind(this);
        this.OnStartOver = this.OnStartOver.bind(this);
        this.OnChannelReady = this.OnChannelReady.bind(this);
        this.OnRFIDCardChanged = this.OnRFIDCardChanged.bind(this);

    }

    componentDidMount()
    {
        this.Channel = new RapidaIDChannel(this.OnChannelReady, this.OnRFIDCardChanged, null, null);
        this.Channel.Create();
    }

    componentWillUnmount()
    {
        this.Channel.Destroy();
        this.Channel = null;
    }

    isValidated()
    {
        var sError = null;
        if(this.props.TokenInfo.Name === "")
            sError = global.StrEmptyTokenNameNotAllowed;
        if(sError)
            this.ShowStatus(true, {sError});
        return !sError;
    }

    OnCancel()
    {
        this.props.OnResult(false);
    }

    OnStartOver()
    {
        this.ShowStatus(false, global.StrApplyYourCard);
    }

    OnChannelReady(fReady)
    {
        console.log("EnrollCardStep2::OnChannelReady(" + fReady + ")");
        if(fReady)
            this.ShowStatus(false, global.StrApplyYourCard);
        else
            this.ShowStatus(true, global.StrUnableConnectHardware);
    }

    OnRFIDCardChanged(sCardID, fPresent)
    {
        console.log("EnrollFIDOStep2::OnRFIDCardChanged(" + sCardID + ", " + fPresent + ")");
        if(fPresent && sCardID !== "")
        {
            this.props.TokenInfo.Data = sCardID;
            this.props.jumpToStep(2);
        }
    }

    ShowStatus(fError, sText)
    {
        if(fError)
            this.setState({ TextStatus: (<div className="text-danger">{sText}.</div>), State: EStateError });
        else
            this.setState({ TextStatus: (<div>{sText}.</div>), State: EStateWaitCard });
    }

    render()
    {
        return (
            <div className="wizard-step">
                <br/>
                <br/>
                <br/>
                <center>{global.StrPleaseCheckStatusMessages}</center>
                <br/>
                <br/>
                <center>{this.state.TextStatus}</center>
                <br/>
                <br/>
                <br/>
                <center>
                    {(this.state.State === EStateError || this.state.State === EStateWaitCard) && <Button fill onClick={this.OnCancel}>{global.StrCancel}</Button>}&nbsp;
                    {this.state.State === EStateError && <Button fill onClick={this.OnStartOver}>{global.StrStartOver}</Button>}
                </center>
            </div>
        );
    }

}

export default EnrollCardStep2;
