//
//  @file AdminRoutes.js
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import Activity from "views/Admin/Activity.jsx";
import Applications from "views/Admin/Applications.jsx";
import HostsView from "views/Admin/HostsView.jsx";
import HostsPolicy from "views/Admin/HostsPolicy.jsx";
import UsersView from "views/Admin/UsersView.jsx";
import UsersPolicy from "views/Admin/UsersPolicy.jsx";
import MainDisk from "views/Admin/FilesMainDisk.jsx";
import Strings from "views/Admin/FilesStrings.jsx";
import HelpCenter from "views/Admin/HelpCenter.jsx";
import AdminSettings from "views/Admin/AdminSettings.jsx";

var AdminRoutes = [
    {
        path: "/activity",
        layout: "/admin",
        name: global.StrActivity,
        icon: "pe-7s-note2",
        component: Activity,
        OnLangUpdate: function() { this.name = global.StrActivity; }
    },
    {
        path: "/applications",
        layout: "/admin",
        name: global.StrApplications,
        icon: "pe-7s-browser",
        component: Applications,
        OnLangUpdate: function() { this.name = global.StrApplications; }
    },
    {
        collapse: true,
        path: "/hosts",
        name: global.StrHosts,
        state: "openHosts",
        icon: "pe-7s-monitor",
        OnLangUpdate: function() { this.name = global.StrHosts; },
        views: [
            {
                path: "/hostsview",
                layout: "/admin",
                name: global.StrView,
                mini: "\u00A0",
                component: HostsView,
                OnLangUpdate: function() { this.name = global.StrView; }
            },
            {
                path: "/hostspolicy",
                layout: "/admin",
                name: global.StrPolicy,
                mini: "\u00A0",
                component: HostsPolicy,
                OnLangUpdate: function() { this.name = global.StrPolicy; }
            }
        ]
    },
    {
        collapse: true,
        path: "/users",
        name: global.StrUsers,
        state: "openUsers",
        icon: "pe-7s-users",
        OnLangUpdate: function() { this.name = global.StrUsers; },
        views: [
            {
                path: "/usersview",
                layout: "/admin",
                name: global.StrView,
                mini: "\u00A0",
                component: UsersView,
                OnLangUpdate: function() { this.name = global.StrView; }
            },
            {
                path: "/userspolicy",
                layout: "/admin",
                name: global.StrPolicy,
                mini: "\u00A0",
                component: UsersPolicy,
                OnLangUpdate: function() { this.name = global.StrPolicy; }
            }
        ]
    },
    {
        collapse: true,
        path: "/files",
        name: global.StrFiles,
        state: "openFiles",
        icon: "pe-7s-diskette",
        OnLangUpdate: function() { this.name = global.StrFiles; },
        views: [
            {
                path: "/maindisk",
                layout: "/admin",
                name: global.StrMainDisk,
                mini: "\u00A0",
                component: MainDisk,
                OnLangUpdate: function() { this.name = global.StrMainDisk; }
            },
            {
                path: "/strings",
                layout: "/admin",
                name: global.StrTranslations,
                mini: "\u00A0",
                component: Strings,
                OnLangUpdate: function() { this.name = global.StrTranslations; }
            }
        ]
    },
    {
        path: "/help",
        layout: "/admin",
        name: global.StrHelpCenter,
        icon: "pe-7s-help2",
        component: HelpCenter,
        OnLangUpdate: function() { this.name = global.StrHelpCenter; }
    },
    {
        path: "/settings",
        layout: "/admin",
        redirect: true,
        name: global.StrAdminSettings,
        icon: null,
        component: AdminSettings,
        OnLangUpdate: function() { this.name = global.StrAdminSettings; }
    }
];

global.AddLangHandler(function() {
    global.SetLangRoute(AdminRoutes);
});

export default AdminRoutes;

