//
//  @file Applications.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";

import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";

import PromptProgress from "components/Prompt/PromptProgress.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";

import ImgTile from "assets/img/TileFIDO.png";

class UserApplications extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            TableData: [],
            Prompt: null
        }

        this.GetAppsCards = this.GetAppsCards.bind(this);
        this.OnLaunchClicked = this.OnLaunchClicked.bind(this);

    }

    componentWillMount()
    {

        this.OnDataFetch();

    }

    OnDataPrepare(pData)
    {
        return pData;
    }

    OnDataFetch()
    {

        function OnRequestInit(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrRetrievingDataPleaseWait);
            pPrompt.ServiceRequest = RapidaIDService.UserAppsGet(pThis, function(pThis, eResult, pData) {
                if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                    pThis.setState({TableData: pThis.OnDataPrepare(pData), Prompt: null});
                else
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={OnRequestInit} OnCancel={() => this.setState({Prompt: null})}/>});

    }

    OnLaunchClicked(iItem)
    {
        console.log(iItem);
    }

    GetAppsCards()
    {
        if(!this.state.TableData)
            return null;
        var pItems = [];
        for(var iItem = 0; iItem < this.state.TableData.length; iItem++)
        {
            let iItemIndex = iItem;
            var pItem = (
                <Col lg={2} md={3} sm={4} xs={6} key={iItemIndex}>
                    <div className="font-icon-detail">
                        <img src={ImgTile} width="96" height="96" alt="AppImage"/>
                        <br/>
                        Facebook
                        <br/>
                        John
                        <br/>
                        <Button fill onClick={e => { this.OnLaunchClicked(iItemIndex); }}>Launch</Button>
                    </div>
                </Col>
            );
            pItems.push(pItem);
        }
        return (<Row>{pItems}</Row>);
    }

    render()
    {
        return (
            <div className="main-content">
                <Grid fluid>
                {this.state.Prompt}
                    <Row>
                        <Col md={12}>
                            <Card ctAllIcons content={this.GetAppsCards()}/>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

}

export default UserApplications;
