//
//  @file EnrollPushStep2.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React from "react";

import Button from "components/CustomButton/CustomButton.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";

const EStateWaitCode = 0;
const EStateWaitScan = 1;
const EStateError = 2;

class EnrollPushStep2 extends React.Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            State: EStateWaitCode,
            QRCode: null
        }

        this.OnCancel = this.OnCancel.bind(this);
        this.OnContinue = this.OnContinue.bind(this);
        this.OnStartOver = this.OnStartOver.bind(this);

        this.ServiceRequest = null;

        this.OnStartOver();

    }

    componentWillUnmount()
    {
        this.ServiceRequest = RapidaIDService.Abort(this.ServiceRequest);
    }

    isValidated()
    {
        return true;
    }

    OnCancel()
    {
        this.props.OnResult(false);
    }

    OnContinue()
    {
        this.props.jumpToStep(2);
    }

    OnStartOver()
    {
        this.ServiceRequest = RapidaIDService.UserPushAdd(this, this.props.TokenInfo.Name, function(pThis, eResult, pQRCodeImg) {
            pThis.ServiceRequest = null;
            if(pQRCodeImg)
                pThis.setState({ State: EStateWaitScan, QRCode: (<img src={pQRCodeImg} alt="QRCode" width={320} height={320}/>) });
            else
                pThis.setState({ State: EStateError });
        });
    }

    render()
    {
        return (
            <div className="wizard-step">
                <br/>
                <br/>
                <br/>
                {this.state.State === EStateWaitCode && <center>{global.StrRequestingServerWait}</center>}
                {this.state.State === EStateWaitScan && <center>{global.StrScanQRCodeWithMobile}</center>}
                {this.state.State === EStateError && <center><div className="text-danger">{global.StrRequestFailedTryAgain}</div></center>}
                <br/>
                <center>{this.state.QRCode}</center>
                <br/>
                <center>
                    <Button fill onClick={this.OnCancel}>Cancel</Button>&nbsp;
                    {this.state.State === EStateWaitScan && <Button fill onClick={this.OnContinue}>{global.StrContinue}</Button>}
                    {this.state.State === EStateError && <Button fill onClick={this.OnStartOver}>{global.StrStartOver}</Button>}
                </center>
            </div>
        );
    }

}

export default EnrollPushStep2;
