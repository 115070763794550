//
//  @file Applications.jsx
//  @author Sergii Oryshchenko <sergii.orishchenko@gmail.com>
//  @see https://github.com/os-v/
//
//  Created on 03.06.20.
//  Copyright 2020 Sergii Oryshchenko. All rights reserved.
//  Any usage of this material is strictly forbidden unless prior written permission is obtained from Sergii Oryshchenko <sergii.orishchenko@gmail.com>.
//

import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";

import ReactTable from "react-table";

import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CheckboxOwnerData.jsx";

import SweetAlert from "react-bootstrap-sweetalert";

import PromptTextArea from "components/Prompt/PromptTextArea.jsx";
import PromptProgress from "components/Prompt/PromptProgress.jsx";

import RapidaIDService from "scripts/RapidaIDService.jsx";
import RapidaIDMainDefs from "scripts/RapidaIDMainDefs.jsx";
import RapidaIDUtils from "scripts/RapidaIDUtils.jsx";

class AdminApplications extends Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            TableData: [],
            Prompt: null
        }

        this.OnToggleRow = this.OnToggleRow.bind(this);
        this.IsCheckedRow = this.IsCheckedRow.bind(this);
        this.OnItemChange = this.OnItemChange.bind(this);
        this.OnItemDelete = this.OnItemDelete.bind(this);
        this.OnAddTemplate = this.OnAddTemplate.bind(this);
        this.OnBuildCache = this.OnBuildCache.bind(this);

    }

    componentWillMount()
    {

        this.OnDataFetch();

    }

    OnDataPrepare(pData)
    {
        for(let iItem = 0; iItem < pData.length; iItem++)
        {
            let pTemplate = RapidaIDUtils.JSONParse(pData[iItem].Value);
            pData[iItem].RowIndex = iItem;
            pData[iItem].IsChecked = false;
            pData[iItem].CUUID = pData[iItem].UUID;
            pData[iItem].Type = pTemplate ? pTemplate.Rule : "error";
            pData[iItem].Name = pTemplate ? pTemplate.Name : "error";
            pData[iItem].AppPath = pTemplate && pTemplate.Items !== undefined && pTemplate.Items.length > 0 ? pTemplate.Items[0].AppURL : "error";
            pData[iItem].TargetOS = pTemplate ? pTemplate.OSVersion : "error";
            pData[iItem].Actions = (
                <div className="actions-right">
                    <Button onClick={() => {this.OnItemChange(iItem);}} bsStyle="info" simple icon>
                        <i className="fa fa-edit" />
                    </Button>{" "}
                    <Button onClick={() => {this.OnItemDelete(iItem);}} bsStyle="danger" simple icon>
                        <i className="fa fa-times" />
                    </Button>{" "}
                </div>
            );
        }
        return pData;
    }

    OnDataFetch()
    {

        function OnRequestInit(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrRetrievingDataPleaseWait);
            pPrompt.ServiceRequest = RapidaIDService.AdminAppsGet(pThis, function(pThis, eResult, pData) {
                if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                    pThis.setState({TableData: pThis.OnDataPrepare(pData), Prompt: null});
                else
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={OnRequestInit} OnCancel={() => this.setState({Prompt: null})}/>});

    }

    OnDataDelete(pRows, pItems)
    {

        function OnRequestInit(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrPleaseWaitUpdatingInfo);
            pPrompt.ServiceRequest = RapidaIDService.AdminAppsDel(pThis, pItems, function(pThis, eResult, pData) {
                if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                    pThis.setState({TableData: pThis.OnDataPrepare(pData), Prompt: null});
                else
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={OnRequestInit} OnCancel={() => this.setState({Prompt: null})}/>});

    }

    OnDataUpdate(pItem)
    {

        function OnRequestInit(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrPleaseWaitUpdatingInfo);
            pPrompt.ServiceRequest = RapidaIDService.AdminAppsSet(pThis, [ pItem.CUUID ], [ RapidaIDUtils.JSONRepacked(pItem.Value) ], function(pThis, eResult, pData) {
                if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                    pThis.setState({TableData: pThis.OnDataPrepare(pData), Prompt: null});
                else
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={OnRequestInit} OnCancel={() => this.setState({Prompt: null})}/>});

    }

    OnDataAppend(sUUID, sTemplate)
    {

        let pTemplate = RapidaIDUtils.JSONParse(sTemplate), pUUIDs = [ "" ], pTemplates = [ RapidaIDUtils.JSONPack(pTemplate) ];
        if(pTemplate.Name === undefined)
        {
            pTemplates = [];
            pUUIDs = RapidaIDUtils.InitStringArray([], "", pTemplate.Items.length);
            for(let iItem = 0; iItem < pTemplate.Items.length; iItem++)
                pTemplates.push(RapidaIDUtils.JSONPack(pTemplate.Items[iItem]));
        }

        function OnRequestInit(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrAddingTemplate);
            pPrompt.ServiceRequest = RapidaIDService.AdminAppsSet(pThis, pUUIDs, pTemplates, function(pThis, eResult, pData) {
                if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                    pThis.setState({TableData: pThis.OnDataPrepare(pData), Prompt: null});
                else
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={OnRequestInit} OnCancel={() => this.setState({Prompt: null})}/>});

    }

    OnDataBuild()
    {

        function OnRequestInit(pThis, pPrompt)
        {
            pPrompt.SetStatusText(global.StrBuildingCache);
            pPrompt.ServiceRequest = RapidaIDService.AdminAppsBuild(pThis, function(pThis, eResult) {
                if(eResult === RapidaIDMainDefs.ERROR_SUCCESS)
                    pThis.setState({Prompt: null});
                else
                    pPrompt.SetStatusText(global.StrErrorSemiSpace + eResult);
            });
        }

        this.setState({Prompt: <PromptProgress AllowCancel Parent={this} OnInit={OnRequestInit} OnCancel={() => this.setState({Prompt: null})}/>});

    }

    OnToggleRow(pRow)
    {
        if(!pRow)
        {
            let pData = this.state.TableData, iItem = 0;
            let fChecked = !this.IsCheckedRow(null);
            for(iItem = 0; iItem < pData.length; iItem++)
                pData[iItem].IsChecked = fChecked;
        }
        else
            pRow.original.IsChecked = !pRow.original.IsChecked;
        this.forceUpdate();
    }

    IsCheckedRow(pRow)
    {
        if(!pRow)
        {
            let pData = this.state.TableData, iItem = 0;
            for(iItem = 0; iItem < pData.length && pData[iItem].IsChecked; iItem++);
            return iItem === pData.length && pData.length;
        }
        return pRow.original.IsChecked;
    }

    OnItemChange(iRowIndex)
    {
        function OnResult(pThis, pPrompt, fResult) {
            pThis.setState({Prompt: null});
            if(fResult)
                pThis.OnDataUpdate(pPrompt.props.ItemInfo);
        }
        let pItemInfo = this.state.TableData[iRowIndex];
        pItemInfo.Value = RapidaIDUtils.JSONReformat(pItemInfo.Value);
        this.setState({Prompt: <PromptTextArea Parent={this} OnResult={OnResult} ItemInfo={pItemInfo} FieldName="Value"/>});
    }

    OnItemDelete(iRowIndex)
    {
        let pData = this.state.TableData, pCheckedIndex = [], pCheckedUUID = [];
        for(let iItem = 0; iItem < pData.length; iItem++)
        {
            if(pData[iItem].IsChecked)
            {
                pCheckedIndex.push(iItem);
                pCheckedUUID.push(pData[iItem].UUID);
            }
        }
        if(!pCheckedIndex.length)
        {
            pCheckedIndex.push(iRowIndex);
            console.log(iRowIndex + ", " + pData[iRowIndex]);
            pCheckedUUID.push(pData[iRowIndex].UUID);
        }
        let sMessage = global.StrDoYouWantToRemove + " " + pCheckedIndex.length + " " + global.StrItems + "?";
        function OnDeleteRows(pThis, fDelete)
        {
            pThis.setState({Prompt: null});
            if(fDelete)
                pThis.OnDataDelete(pCheckedIndex, pCheckedUUID);
        }
        this.setState({
            Prompt: (
                <SweetAlert
                    style={{ display: "block" }}
                    title={global.StrUserActionRequired}
                    onConfirm={() => OnDeleteRows(this, true)}
                    onCancel={() => OnDeleteRows(this, false)}
                    confirmBtnBsStyle="info"
                    confirmBtnText={global.StrYes}
                    cancelBtnText={global.StrNo}
                    showCancel
                >
                    {sMessage}
                </SweetAlert>
            )
        });
    }

    OnAddTemplate()
    {
        let pDefaultItem = { Name: global.StrNewTemplate };
        let pItemInfo = { Value: RapidaIDUtils.JSONFormat(pDefaultItem) };
        function OnResult(pThis, pPrompt, fResult) {
            pThis.setState({Prompt: null});
            if(fResult)
                pThis.OnDataAppend("", pItemInfo.Value);
        }
        this.setState({Prompt: <PromptTextArea Parent={this} OnResult={OnResult} ItemInfo={pItemInfo} FieldName="Value"/>});
    }

    OnBuildCache()
    {
        this.OnDataBuild();
    }

    render()
    {
        return (
            <div className="main-content">
                <Grid fluid>
                {this.state.Prompt}
                    <Row>
                        <Col md={12}>
                            {global.StrSSOTemplateChangeRemoveThere}
                            <br/>
                            <br/>
                            <Button fill onClick={this.OnAddTemplate}>{global.StrAddNew}</Button>&nbsp;
                            <Button fill onClick={this.OnBuildCache}>{global.StrBuildCache}</Button>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                      <Col md={12}>
                        <Card
                            category={global.StrApplications + ":"}
                            content={
                                <ReactTable
                                    data={this.state.TableData}
                                    filterable
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: (pRowInfo) => {
                                                return (<Checkbox checked={this.IsCheckedRow(pRowInfo)} onChange={() => this.OnToggleRow(pRowInfo)}/>);
                                            },
                                            Header: (sTitle) => {
                                                return (<Checkbox checked={this.IsCheckedRow(null)} onChange={() => this.OnToggleRow(null)}/>);
                                            },
                                            filterable: false,
                                            sortable: false,
                                            width: 40
                                        },
                                        {
                                            Header: global.StrName,
                                            accessor: "Name"
                                        },
                                        {
                                            Header: global.StrApplication,
                                            accessor: "AppPath"
                                        },
                                        {
                                            Header: global.StrType,
                                            accessor: "Type",
                                            width: 75
                                        },
                                        {
                                            Header: global.StrTargetOS,
                                            accessor: "TargetOS",
                                            width: 125
                                        },
                                        {
                                            Header: global.StrActions,
                                            accessor: "Actions",
                                            width: 100,
                                            sortable: false,
                                            filterable: false
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                />
                            }
                        />
                      </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

}

export default AdminApplications;
